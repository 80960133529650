import './assessment-answer.scss';


interface AssessmentAnswerProps {
  text?: string,
  chosen: boolean,
  key: number,
  content_type?: string,
  setChosen?: () => void,
  question_type?: string,
  handleMultipleAns?: () => void
}
export interface MultipleAns {
  ans: { [key: number]: number };
}


const AssessmentAnswer = (props: AssessmentAnswerProps) => {
  const {
    text,
    chosen,
    key,
    content_type,
    setChosen,
    question_type,
    handleMultipleAns
  } = props;


  
  if (text && question_type === "multiple_ans_choice" && handleMultipleAns) {
    return <div
    onClick={handleMultipleAns}
    className={`assessment-answer ${chosen ? 'assessment-answer-chosen' : ''}`}
    dangerouslySetInnerHTML={{__html: text}}
    key={key}>
      
    </div>
  }


  if (text && content_type !== "image_options" && content_type !== "multiple_ans_choice") {
    return <div onClick={setChosen}
                className={`assessment-answer ${chosen ? 'assessment-answer-chosen' : ''}`}
                dangerouslySetInnerHTML={{__html: text}}
                key={key}
    />
  }



  if (content_type === "image_options") {
    return <div
      key={key}
      onClick={setChosen}
      className={`assessment-answer assessment-answer-image ${chosen ? 'assessment-answer-chosen' : ''}`}
    >
      <img src={text} alt={'answer-option'}/>
    </div>
  }

  return <div/>

}

export default AssessmentAnswer;