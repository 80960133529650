import './create-password.scss';
import {useNavigate} from "react-router-dom";
import InputKMQ from "../../../components/UI/Input/InputKMQ";
import {useEffect, useState} from "react";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import PasswordRequirements from "../../../components/login/PasswordRequirements/PasswordRequirements";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import {jwtToJson} from "../../../lib/jwt/jwtToJson";
import {toast} from "react-toastify";
import {useResetPassword} from "../../../api/login/useResetPassword";
import TermsOfUse from "../../../components/Router/TextWithLink/TermsOfUse";
import PrivacyPolicy from "../../../components/Router/TextWithLink/PrivacyPolicy";

const CreatePassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordIsChecked, setPasswordIsChecked] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
  const [policyIsAccepted, setPolicyIsAccepted] = useState<boolean>(false);
  const {mutate, isSuccess} = useResetPassword();

  const handleCreatePassword = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
    } else {
      mutate({
        password: password,
        confirmation_password: confirmPassword
      });
    }
  }

  useEffect(() => {
    try {
      let token = localStorage.getItem('reset-password-token')
      jwtToJson(token ?? '');
    } catch (e) {
      navigate('/login');
    }
  }, [navigate])

  useEffect(() => {
    if (confirmPasswordError && password && password === confirmPassword) {
      setConfirmPasswordError(false);
    }
  }, [confirmPasswordError, password, confirmPassword])

  useEffect(() => {
    if (isSuccess) {
      navigate('/login');
      toast.success('Your password is created. You can log in using your new password.');
      localStorage.removeItem('reset-password-token');
    }
  }, [isSuccess, navigate])

  return <div className={'create-password'}>
    <div className={'create-password-title'}>
      Create password
    </div>
    <InputKMQ
      label={'Password'}
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      type={'password'}
      placeholder={'Enter password'}
      passwordShowIcon
    />
    <InputKMQ
      label={'Confirm password'}
      value={confirmPassword}
      onChange={(e) => setConfirmPassword(e.target.value)}
      type={'password'}
      placeholder={'Confirm password'}
      error={confirmPasswordError ? 'Passwords do not match' : ''}
      passwordShowIcon
    />
    <PasswordRequirements
      password={password}
      setPasswordCheck={setPasswordIsChecked}
    />
    <Checkbox
      active={policyIsAccepted}
      handleClick={() => setPolicyIsAccepted(!policyIsAccepted)}
      textHtml={
        <span>
          I have read, and agree to, <TermsOfUse/> and <PrivacyPolicy/>
        </span>
      }
    />
    <div className={'create-password-button'}>
      <ButtonKMQ
        onClick={handleCreatePassword}
        disabled={!passwordIsChecked || !policyIsAccepted || confirmPasswordError || confirmPassword.length < 8}
      >
        Create password and continue
      </ButtonKMQ>
    </div>
  </div>
}

export default CreatePassword;