import "./rejects-details.scss";
import React, {ChangeEvent, useContext, useState} from "react";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import {RejectsOptionsList} from "../../../constants/rejectsOptions/rejectsOptions";
import LineSeparator from "../../../components/UI/LineSeparator/LineSeparator";
import TextArea from "../../../components/UI/TextArea/TextArea";
import {useNavigate, useParams} from "react-router-dom";
import {useQueryEvidences} from "../../../api/admin/useQueryEvidences";
import {useMutateCourseFeedback} from "../../../api/admin/useMutateCourseFeedback";
import {useQueryCourses} from "../../../api/admin/useQueryCourses";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import {useQueryPrograms} from "../../../api/admin/useQueryPrograms";
import {useQueryUserInfo} from "../../../api/admin/useQueryUserInfo";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";

interface SubRejectOption {
  text: string;
}

interface RejectOptionList {
  text: string;
  desc?: string;
  subRejectsOptions?: SubRejectOption[];
}

interface OptionEntry {
  option: string;
  subOptions: SubRejectOption[];
}

const RejectDetails = () => {
  const navigate = useNavigate();
  const {portfolio_id, program_id, course_code} = useParams();
  const [portfolio] = useContext(PortfolioContext);

  const [rejectOptions, setRejectOptions] = useState<OptionEntry[]>([]);
  const [otherTextBox, setOtherTextBox] = useState<string>("");

  const {data: evidences} = useQueryEvidences({
    portfolio_id: portfolio_id,
    course_code: course_code
  });

  const {data: currCourse} = useQueryCourses({
    portfolio_id: portfolio_id,
    course_code: course_code
  });

  const {data: currProgram} = useQueryPrograms({
    portfolio_id: portfolio_id,
    program_id: program_id
  });

  const {
    mutate: postFeedback,
  } = useMutateCourseFeedback();

  const handleReject = () => {
    const feedback_list = rejectOptions.map((item, index) => {
      if (item.option === 'Other') {
        return {
          feedback: item.option,
          secondary_feedback: [otherTextBox],
          associated_learning_outcomes: []
        }
      } else if (index === 2) {
        return {
          feedback: item.option,
          secondary_feedback: item.subOptions.map(el => el.text),
          associated_learning_outcomes: []
        }
      } else {
        return {
          feedback: item.option,
          secondary_feedback: item.subOptions.map(el => el.text),
          associated_learning_outcomes:
            evidences.learning_outcomes.filter((outcome: { description: string }) => !!item.subOptions.find(option => option.text === outcome.description))
              .map((outcome: { id: number }) => outcome.id)
        }
      }
    });

    postFeedback({
      course_recommendation_id: currCourse.data.courses.find((item: any) => item.code === course_code).course_recommendation_id,
      approved: false,
      feedback_list: feedback_list
    });
    navigate(`/cpl-admin/${portfolio_id}/${program_id}/${course_code}`);
    setRejectOptions([]);
  }

  const handleOptionsChange = (rejectItem: string) => {
    setRejectOptions((prev) => {
      const isRejectItemExists = prev.some((entry) => entry.option === rejectItem);
      return isRejectItemExists
        ? prev.filter((entry) => entry.option !== rejectItem)
        : [...prev, {option: rejectItem, subOptions: []}];
    });
  };

  const isSubOptionElementExist = (
    optionText: string,
    subOptionText: string
  ) => {
    return rejectOptions
      .find((ele) => ele.option === optionText)
      ?.subOptions.some((item) => item.text === subOptionText);
  };
  const handleSubOptionChange = (optionText: string, subOptionText: string) => {
    setRejectOptions((prev) =>
      prev.map((item) => {
        return {
          ...item,
          subOptions: isSubOptionElementExist(optionText, subOptionText)
            ? item.subOptions.filter((subOpt) => subOpt.text !== subOptionText)
            : optionText !== 'Other'
              ? [...item.subOptions, {text: subOptionText}]
              : [{text: subOptionText}]
        };
      })
    );
  };

  return (
    <>
      <Breadcrumbs content={[
        {
          label: 'Applicants',
          link: `/cpl-admin`
        },
        {
          label: `${portfolio.portfolio_user_name}’s profile`,
          link: `/cpl-admin/${portfolio_id}`
        },
        {
          label: `${currProgram?.name}`,
          link: `/cpl-admin/${portfolio_id}/${program_id}`
        },
        {
          label: `${evidences?.title}`,
          link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}`
        },
        {
          label: 'Reason for Rejecting Credit',
          link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}/reject-reason`
        }
      ]}/>
      <div className="rejectdetails">
        <div className="rejectdetails-main">
          <h1 className="rejectdetails-title">
            Choose one or multiple reasons for rejection
          </h1>
          {RejectsOptionsList.map((item: RejectOptionList, index: number) => (
            <div className="rejectdetails-main-checkbox" key={index}>
              <Checkbox
                active={rejectOptions.some(
                  (entry) => entry.option === item.text
                )}
                handleClick={() => handleOptionsChange(item.text)}
                text={item.text}
              />

              {
                (rejectOptions.some((entry) => entry.option === item.text) && item.text === "Other")
                  ? <TextArea
                    value={otherTextBox ?? ""}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                      setOtherTextBox(e.target.value);
                    }
                    }
                    placeholder={"Please tell us more"}
                  />
                  : null
              }

              {
                (item.subRejectsOptions && rejectOptions.some((entry) => entry.option === item.text) && index === 2 && item.text !== "Other")
                  ? <ul className="rejectdetails-main-checkbox-sub">
                    {item.desc
                      ? <p>{item.desc}</p>
                      : null
                    }
                    {item.subRejectsOptions.map(
                      (subItem: SubRejectOption, index: number) => (
                        <li key={index}>
                          <Checkbox
                            active={isSubOptionElementExist(
                              item.text,
                              subItem.text
                            )}
                            handleClick={() =>
                              handleSubOptionChange(item.text, subItem.text)
                            }
                            text={index + 1 + ". " + subItem.text}
                          />
                        </li>
                      )
                    )}
                  </ul>
                  : null
              }

              {
                (rejectOptions.some((entry) => entry.option === item.text) && index !== 2 && item.text !== "Other")
                  ? <ul className="rejectdetails-main-checkbox-sub">
                    {item.desc
                      ? <p>{item.desc}</p>
                      : null
                    }
                    {evidences.learning_outcomes.map(
                      (subItem: { description: string }, index: number) => (
                        <li key={index}>
                          <Checkbox
                            active={!!rejectOptions.find(selectedOption => selectedOption.option === item.text)?.subOptions.find(el => el.text === subItem.description)}
                            handleClick={() =>
                              handleSubOptionChange(item.text, subItem.description)
                            }
                            text={index + 1 + ". " + subItem.description}
                          />
                        </li>
                      )
                    )}
                  </ul>
                  : null
              }
              {(index !== RejectsOptionsList.length - 1)
                ? <LineSeparator/>
                : null
              }
            </div>
          ))}
          <div className="rejectdetails-main-submit">
            <ButtonKMQ
              onClick={handleReject}
              disabled={!portfolio.assigned_to_me}
            >
              Submit feedback and reject credit
            </ButtonKMQ>
          </div>
        </div>
      </div>
    </>
  );
};
export default RejectDetails;
