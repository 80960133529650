export const DCResults = {
  title: 'Digital Competencies Score',
  grades: {
    totalGrade: 25,
  }
}

export const ReadingResults = {
  title: 'Reading Score',
  grades: {
    totalGrade: 100,
  }
}

export const NumeracyResults = {
  title: 'Numeracy Score ',
  grades: {
    totalGrade: 75,
  }
}

export const OCEANResults = {
  title: 'OCEAN Score',
  grades: {
    allGrades: [
      {
        percentage: 75,
        name: 'Openness'
      },
      {
        percentage: 100,
        name: 'Extraversion'
      },
      {
        percentage: 25,
        name: 'Emotional Stability'
      },
      {
        percentage: 25,
        name: 'Conscientiousness'
      },
      {
        percentage: 50,
        name: 'Agreeableness'
      },
    ]
  }
}

export const AQResults = {
  title: 'AQ Score',
  grades: {
    totalGrade: 75,
    allGrades: [
      {
        percentage: 50,
        name: 'Problem Solving'
      },
      {
        percentage: 75,
        name: 'Cultural Adaptability'
      },
      {
        percentage: 100,
        name: 'Work Stress'
      },
      {
        percentage: 75,
        name: 'Crisis Handling'
      },
      {
        percentage: 75,
        name: 'Interpersonal Adaptability'
      },
      {
        percentage: 25,
        name: 'Physical Adaptability'
      },
    ]
  }
}

export const ACERResults = {
  title: 'ACER Score',
  grades: {
    allGrades: [
      {
        percentage: 25,
        name: 'Acknowledging and Knowing Their Own Emotions'
      },
      {
        percentage: 50,
        name: 'Empathy/Interpersonal and Social Awareness'
      },
      {
        percentage: 50,
        name: 'Controlling and Managing Their Own Emotions'
      },
      {
        percentage: 75,
        name: 'Relationship Building/Maintaining Relationships'
      },
    ]
  }
}

export const EmotionalResults = {
  title: 'Emotional Intelligence Score',
  grades: {
    allGrades: [
      {
        percentage: 25,
        name: 'Emotional Perception '
      },
      {
        percentage: 50,
        name: 'Emotional Management '
      },
      {
        percentage: 50,
        name: 'Teamwork'
      },
      {
        percentage: 75,
        name: 'Emotional Understanding'
      },
      {
        percentage : 80,
        name:'Creativity'
      }
    ]
  }
}

export const assessmentData = [
  {
      "assessment_id": 1,
      "assessment_name": "ACER",

  },
  {
      "assessment_id": 2,
      "assessment_name": "ADAPTABILITY ASSESSMENT",

  },
  {
      "assessment_id": 3,
      "assessment_name": "DIGITAL COMPETENCIES",

  },
  {
      "assessment_id": 4,
      "assessment_name": "EMOTIONAL INTELLIGENCE",

  },
  {
      "assessment_id": 5,
      "assessment_name": "OCEAN",

  },
  {
      "assessment_id": 6,
      "assessment_name": "NUMERACY",

  },
  {
      "assessment_id": 7,
      "assessment_name": "PROBLEM SOLVING",

  },
  {
      "assessment_id": 8,
      "assessment_name": "READING",
  }
]