import {Text, View, StyleSheet, Image, Font} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    marginBottom: 24,
  },
  title: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  learning_outcomes: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 16,
  },
  title_course: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    marginBottom: 8
  },
  header_approved: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 12,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    top: '-1px'
  },
  col1: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '4%',
    padding: '4px 2px',
    borderLeft: '1px solid #909090',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col2: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '10%',
    padding: '4px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col3: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '19%',
    padding: '4px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col4: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '23.2%',
    padding: '4px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col5: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '18.5%',
    padding: '4px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col6: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '25.3%',
    padding: '4px 4px 0 4px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
})

const AppendixTableHeader = () => {
  return <View style={styles.row} fixed>
    <Text style={{...styles.col1, borderTop: '1px solid #909090', fontWeight: 'light',}}>
      #
    </Text>
    <Text style={{...styles.col2, borderTop: '1px solid #909090', fontWeight: 'light',}}>
      Score %
    </Text>
    <Text style={{...styles.col3, borderTop: '1px solid #909090', fontWeight: 'light',}}>
      Resume category
    </Text>
    <Text style={{...styles.col4, borderTop: '1px solid #909090', fontWeight: 'light',}}>
      Referred section
    </Text>
    <Text style={{...styles.col5, borderTop: '1px solid #909090', fontWeight: 'light',}}>
      Evidence Source
    </Text>
    <Text style={{...styles.col6, borderTop: '1px solid #909090', fontWeight: 'light',}}>
      Evidence Text
    </Text>
  </View>
}

const AppendixTable = ({course, index}: { course: any, index: string }) => {
  return <View style={styles.table}>
    <Text style={styles.title}>
      Appendix {index}
    </Text>
    <Text style={styles.title_course}>
      {course.code} {course.title}
    </Text>

    {
      course.learning_outcomes.map((learningOutcome: any, index: number) =>

        <View style={{marginBottom: 12}}>
          <Text style={styles.learning_outcomes}>
            {index + 1}. {learningOutcome.description}
          </Text>

          <AppendixTableHeader/>

          {
            learningOutcome.evidence_sentence_pairs.map((item: any, evidenceIndex: number) =>
              <View style={styles.row} wrap={false}>
                <Text style={styles.col1}>
                  {evidenceIndex + 1}
                </Text>
                <Text style={styles.col2}>
                  {`${Math.round(item.matching_score * 100)}%`}
                </Text>
                <Text style={styles.col3}>
                  {item.resume_category[0].toUpperCase() + item.resume_category.slice(1)}
                </Text>
                <Text style={styles.col4}>
                  {
                    item.portfolio_section.map((section: string, sectionIndex: number) =>
                      section + "\n"
                    )
                  }
                </Text>
                <Text style={styles.col5}>
                  {item.resume_detail}
                </Text>
                <Text style={styles.col6}>
                  {item.resume_sentence}
                </Text>
              </View>)
          }

        </View>
      )
    }
  </View>
}

export default AppendixTable;