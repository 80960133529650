import { LINKEDIN_HOW_TO_DOWNLOAD_PDF } from '../../../constants/resume/default';
import './linkedin.scss';
import {useEffect} from "react";
import KMQHeader from "../../../components/Headers/KMQHeader";

const Linkedin = () => {
    const steps = [
        {
            number: 1,
            text: 'Open your LinkedIn homepage using your preferred web browser.'
        },
        {
            number: 2,
            text: 'Click the your profile icon at the top of the page to open the dropdown menu.'
        },
        {
            number: 3,
            text: "Select 'View Profile.' You can also navigate to another LinkedIn member's profile from this page if needed."
        },
        {
            number: 4,
            text: 'Click on the More button beneath the profile and cover photo section.'
        },
        {
            number: 5,
            text: "Select 'Save to PDF,' and LinkedIn will generate the PDF for you."
        },
        {
            number: 6,
            text: 'Save the PDF to your desired location.'
        }
    ];

    useEffect(() => {
        window.scroll(0, 0);
    }, [])

    return (
      <div>
        <KMQHeader/>
        <div className="save-linkedin">
            <h1 className="save-linkedin-title">Save a LinkedIn profile as a PDF</h1>
            <p className="save-linkedin-description">
                On the <a href={LINKEDIN_HOW_TO_DOWNLOAD_PDF} target="_blank" rel="noopener noreferrer" className="save-linkedin-link">
                    LinkedIn website
                </a>, you have the option to export a profile as a PDF. 
                Follow these steps to generate a PDF of your own profile or another LinkedIn member's profile.
            </p>
            <div className="save-linkedin-steps">
                {steps.map((step) => (
                    <div key={step.number} className="save-linkedin-step">
                        <div className="save-linkedin-step-number">{step.number}</div>
                        <div className="save-linkedin-step-text">{step.text}</div>
                    </div>
                ))}
            </div>
        </div>
      </div>
    );
};

export default Linkedin;