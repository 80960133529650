import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";

const bulkCreateUsersURL = "user-management/bulk-create-users";

export const useCreateUsers = () => {
    const queryClient = useQueryClient();
    const axios = useAxios();

    async function postCreateUsers(data: any) {
        return axios.post(bulkCreateUsersURL, data);
    }

    return useMutation({
        mutationKey: ["users", "bulkCreate"],
        mutationFn: postCreateUsers,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["employees"] });
        },
    });
};
