import './status-chiclet.scss';
import {CheckCircle} from "@phosphor-icons/react";

interface StatusChicletProps {
  text: string,
  icon?: boolean
}

const StatusChiclet = (props: StatusChicletProps) => {
  const {
    text,
    icon
  } = props;

  return <span className={`status-chiclet-container`}>
    <span className={`status-chiclet`}>
      {text} {icon ? <CheckCircle size={20} color={'#212121'}/> : null}
    </span>
  </span>
}

export default StatusChiclet;