import {useQuery} from "@tanstack/react-query";
import {assessmentProgressGETURL} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useQueryProgress = () => {
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(assessmentProgressGETURL)
      .then(response => response?.data ?? response);
  }

  return useQuery({queryKey: ['assessment', 'progress'], queryFn: handleRequest})

}
