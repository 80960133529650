import './payment-chiclet.scss';
import PaidIcon from '../../../../assets/icons/UI/icons/paid-icon.svg';

const PaymentChiclet = ({text}: { text?: string }) => {

  return <span className={`payment-chiclet`}>
    <img
      src={PaidIcon}
      alt={'paid'}
      className={'payment-chiclet-icon'}
    />
    <span className={'payment-chiclet-text'}>
      {text}
    </span>
  </span>
}

export default PaymentChiclet;