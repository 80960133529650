import './assessment-header.scss';
import LogoKMQ from "../../../../assets/logo/KnowMeQ_logo.svg";
import {useState} from "react";
import InstructionModal from "../AssessmentInstructionModal/InstructionModal";
import { useNavigate } from 'react-router-dom';

interface HeaderProps {
  title?: string,
  showInstructions?: boolean,
  instructions?: {
    title?: string,
    details?: any[],
    description?: string[]
  },
  text?: string
}


const AssessmentHeader = (props: HeaderProps) => {
  const {
    title,
    showInstructions,
    instructions,
    text
  } = props;

  const [showModal, setShowModal] = useState<boolean>(false);
  const navigate = useNavigate();
  return <div className={'assessment-header'}>
    <img
      className={'assessment-header-logo'}
      src={LogoKMQ}
      alt={'kmq-logo'}
      onClick={() => navigate('/assessments/dashboard')}
    />
    <span className={'assessment-header-text'}>
      <div className={'assessment-header-text-title'}>
        {title}
      </div>
      {
        showInstructions
          ? <div className={'assessment-header-text-instructions'}
                 onClick={() => setShowModal(true)}>
            {text ?? ''}
          </div>
          : null
      }
    </span>

    {instructions
      ? <InstructionModal
        open={showModal}
        onClose={() => setShowModal(false)}
        title={instructions.title}
        description={instructions.description}
        icons={instructions.details}
        closeText={'Close and get back to the assessment'}
      />
      : null
    }

  </div>
}

export default AssessmentHeader;