import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { ReactNode, useState } from "react";
import { List, X } from '@phosphor-icons/react';
import "./layout.scss";
import Header from "../Headers/UserHeader/Header";
import MenuKMQ from "../UI/Menu/MenuKMQ";
import Footer from "../Footer";

interface MenuOption {
  name: string;
  icon: ReactNode;
}

const MenuLayout = ({ menuOptions }: { menuOptions: MenuOption[] }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen((prev) => !prev);

  return (
    <div className="layout">
      <div className="layout-header">
        <Header toggleMenu={toggleMenu} />
      </div>

      <div className={`layout-content ${isMenuOpen ? 'menu-open' : ''}`}>
        <div className={`layout-content-menu ${isMenuOpen ? 'show' : ''}`}>
          {isMenuOpen && (
            <div className="menu-close" onClick={toggleMenu}>
              <X size={24} />
            </div>
          )}
          <MenuKMQ
            options={menuOptions.map((item: any, _: number) => ({
              ...item,
              active: item.link === location.pathname,
              onClick: () => {
                navigate(item.link);
                setIsMenuOpen(false);
              },
            }))}
          />
        </div>

        <div className="layout-content-page">
          <Outlet />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default MenuLayout;
