import {useMutation, useQueryClient} from "@tanstack/react-query";
import {finishPortfolioReviewUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useFinishPortfolioReview = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function postReview(data: {portfolio_id?: string}) {
    return axios.post(finishPortfolioReviewUrl, data)
  }

  return useMutation({
    mutationKey: ['review-applicant'],
    mutationFn: postReview,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user'] })
      queryClient.invalidateQueries({ queryKey: ['applicants'] })
    }
  });
}