import "./assessment-intro.scss";
import { AssessmentIcon } from "../AssessmentCards/components/CardIcons/AssessmentCardIcons";
import HourIcon from "../../../../assets/icons/UI/select/hour.svg";
import DocumentationIcon from "../../../../assets/icons/UI/select/documentation.svg";
interface AssessmentMeta {
  assessment_name?: string;
  description?: string[];
  self_assessment_description?: string[];
  duration?: string;
  number_of_questions?: string;
  version?: string;
}
interface AssessmentIntroProps {
  detail?: AssessmentMeta;
  isAssessmentIntro?: boolean;
}

const AssessmentIntro = (props: AssessmentIntroProps) => {
  const { detail, isAssessmentIntro } = props;

  return (
    <div className={"assessment-intro"}>
      <div className={"assessment-intro-title"}>{detail?.assessment_name}</div>
      <div className={"assessment-intro-details"}>
        <span className={"assessment-intro-details-icon"}>
          <img src={HourIcon} alt={"hour icon"} />
        </span>
        <span className={"assessment-intro-details-text"}>
          {detail?.duration}
        </span>
        <span className={"assessment-intro-details-icon"}>
          <img src={DocumentationIcon} alt={"Documentation icon"} />
        </span>
        <span className={"assessment-intro-details-text"}>
          {detail?.number_of_questions}
        </span>
      </div>

      <ul className={"assessment-intro-description"}>
        {isAssessmentIntro
          ? detail?.description &&
            detail?.description.map((item: string, index: number) => (
              <li key={index}>{item}</li>
            ))
          : detail?.self_assessment_description &&
            detail?.self_assessment_description.map(
              (item: string, index: number) =>(
                <li key={index}>{item}</li>
              )
            )}
      </ul>
    </div>
  );
};

export default AssessmentIntro;
