import './evidence-component.scss';
import EvidenceTitle from "./components/EvidenceTitle/EvidenceTitle";
import EvidenceTable, {IEvidence} from "./components/EvidenceTable/EvidenceTable";
import {useMemo} from "react";

interface EvidenceProps {
  index: number,
  title: string,
  isOpened: boolean,
  toggleOpened: () => void,
  evidences: IEvidence[],
  canSendFeedback?: boolean
}

const Evidence = (props: EvidenceProps) => {
  const {
    index,
    isOpened,
    evidences,
    canSendFeedback
  } = props;

  const stickyHeight = useMemo<number>(() => {
    let parent = document.getElementById(`evidence-component-${index}`);
    if (parent) {
      let header: HTMLTableHeaderCellElement = parent.getElementsByTagName('th')[0];
      if (header?.offsetHeight) {
        return header?.offsetHeight - 1
      }
    }

    return 76;
  }, [window.innerWidth, isOpened])


  return <div className={'evidence-component'} id={`evidence-component-${index}`}>
    <div className={`evidence-component-table`}>
      <table>
        <tr className={'evidence-component-table-title'}>
          <th className={'evidence-component-table-title'}>
            <EvidenceTitle
              {...props}
            />
          </th>
        </tr>

        {
          isOpened
            ? <EvidenceTable
              evidences={evidences}
              stickyHeight={stickyHeight}
              canSendFeedback={canSendFeedback}
            />
            : null
        }
      </table>
    </div>
  </div>
}

export default Evidence;