import React, { useState } from 'react';
import CSVReader from 'react-csv-reader';
import { WarningCircle } from "@phosphor-icons/react";
import ButtonKMQ from "../../../../../components/UI/Button/ButtonKMQ";
import './csv-uploader.scss';
import UploadEmployeeTable from "../EmployeeTable/UploadEmployeeTable";
import { useCreateUsers } from "../../../../../api/assessment-admin/useCreateUsers";
import { useNavigate } from "react-router-dom";
import Back from "../../../../../components/UI/Back/Back";

interface CsvUploaderProps {
    maxRows: number;
    onUsersCreated: () => void;
    onCancel?: () => void;
    hasEmployees?: boolean;
}

const CsvUploader = ({ maxRows = 250, onUsersCreated, onCancel, hasEmployees }: CsvUploaderProps) => {
    const navigate = useNavigate();
    const [data, setData] = useState<any[]>([]);
    const [error, setError] = useState(false);
    const createUsersMutation = useCreateUsers();

    const handleFileLoad = (data: any[]) => {
        const hasErrors = data.some(row => !row['First Name'] || !row['Last Name'] || !row['Email']);
        setError(hasErrors);
        if (data.length > maxRows) {
            setError(true);
            setData([]);
        } else {
            setData(data);
        }
    };

    const handleSubmit = () => {
        if (!error && data.length > 0) {
            const convertedData = data.map(row => ({
                first_name: row['First Name'],
                last_name: row['Last Name'],
                email: row['Email'],
                job_title: row['Job Title'],
                location: row['Location'],
                team: row['Team']
            }));

            createUsersMutation.mutate({ users: convertedData }, {
                onSuccess: () => {
                    onUsersCreated();
                    navigate("/assessments/employees");
                }
            });
        }
    };

    const handleReupload = () => {
        setData([]);
    };

    return (
        <div className="csv-uploader">
            {onCancel && hasEmployees && <Back onClick={onCancel} text={'Back to Employees'}/>}
            {data.length === 0 ? (
                <div className="file-upload-section">
                    <h2>Welcome to KnowMeQ!</h2>
                    <p>
                        Let's begin by uploading a .csv file with employee information.
                        Click below to begin the import process.
                    </p>
                    <p>
                        Please only use the following file formats: .csv
                        Max files size: 5MBs | Max number: 200 employees
                    </p>
                    <CSVReader
                        cssClass="csv-reader-input"
                        label="Select file"
                        onFileLoaded={handleFileLoad}
                        parserOptions={{ header: true }}
                    />
                </div>
            ) : (
                <>
                    {error && <p className="error-message"><WarningCircle size={20} color="red"/>At least one column is empty, please verify that all required fields are provided and reupload the file.</p>}
                    <UploadEmployeeTable data={data} />
                    <div className="button-group">
                        <ButtonKMQ onClick={handleReupload}>Reupload</ButtonKMQ>
                        <ButtonKMQ onClick={handleSubmit} disabled={error || data.length === 0}>Submit</ButtonKMQ>
                    </div>
                </>
            )}
        </div>
    );
};

export default CsvUploader;
