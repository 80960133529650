import React from 'react';
import './upload-employee-table.scss';

interface UploadEmployeeTableProps {
    data: any[];
}

const UploadEmployeeTable: React.FC<UploadEmployeeTableProps> = ({ data }) => {
    const requiredFields = ['First Name', 'Last Name', 'Email'];
    const validateRow = (row: any) => {
        return requiredFields.every((field) => row[field]);
    };

    return (
        <div className="upload-employee-table">
            {data.length > 0 && (
                <table>
                    <thead>
                    <tr>
                        {Object.keys(data[0]).map((key) => (
                            <th key={key}>{key}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((row, index) => (
                        <tr key={index} className={!validateRow(row) ? 'error-row' : ''}>
                            {Object.values(row).map((value: any, i) => (
                                <td key={i}>{value}</td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default UploadEmployeeTable;
