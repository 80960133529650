import {UsersFour, User} from '@phosphor-icons/react';

export const CplAdminMenuOptions = [
  {
    name: 'Applicants',
    icon: <UsersFour size={18} color="#212121"/>,
    link: '/cpl-admin'
  },
]

export const CplSuperAdminMenuOptions = [
  {
    name: 'Applicants',
    icon: <UsersFour size={18} color="#212121"/>,
    link: '/cpl-admin'
  },
  {
    name: 'Create Admin',
    icon: <User size={18} color="#212121"/>,
    link: '/cpl-admin/super-admin/create-admin'
  },
]