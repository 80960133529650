import {useQuery} from "@tanstack/react-query";
import {adminsListUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";


export const useQueryAdmins = () => {
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(adminsListUrl)
      .then(response => response?.data.cpl_admins ?? response);
  }

  return useQuery({
    queryKey: ['admins-list'],
    queryFn: handleRequest,
    enabled: false
  })
}