import './progress-bar.scss';
import {createRef, useEffect} from "react";

interface ProgressBarProps {
  progress: number
}

const ProgressBar = (props: ProgressBarProps) => {
  const {
    progress
  } = props;

  const progressRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (progressRef.current?.style && progressRef.current?.style.width !== `${progress}%`) {
      progressRef.current.style.width = `${progress}%`;
    }
  }, [progress, progressRef])

  return <div className={'assessments-progress-bar'}>
    <div className={'assessments-progress-bar-container'}>
      <div ref={progressRef} className={'assessments-progress-bar-container-fill'}/>
    </div>
    <div className={'assessments-progress-bar-text'}>
      {Math.floor(progress)}%
    </div>
  </div>
}

export default ProgressBar;