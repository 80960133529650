import './soft-user-header.scss';
import LogoKMQ from "../../../assets/logo/KnowMeQ_logo.svg";
import {useLocation, useNavigate} from "react-router-dom";
import ButtonKMQ from "../../UI/Button/ButtonKMQ";
import {useGetSubdomainInfo} from "../../../api/general/useGetSubdomainInfo";

const SoftUserHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {data: subdomainInfo} = useGetSubdomainInfo();

  return <div className={'soft-user-header'}>
    <img
      className={'header-logo'}
      src={subdomainInfo?.logo_url ? subdomainInfo.logo_url : LogoKMQ}
      alt={'kmq-logo'}
      onClick={
        location.pathname.includes('login')
          ? () => navigate('/login')
          : () => navigate('/cpl-applicant')
      }
    />
    <span className={'soft-user-header-button'}>
      <ButtonKMQ onClick={() => navigate('/login')}>
        Log In
      </ButtonKMQ>
    </span>
  </div>
}

export default SoftUserHeader;