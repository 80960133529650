import {NavigationTab} from "../../NavigationTabs";
import './navigation-tab-element.scss';

interface NavigationTabElementProps {
  element: NavigationTab,
  showDivider: boolean
}

const NavigationTabElement = (props: NavigationTabElementProps) => {
  const {
    element,
    showDivider
  } = props;

  return <span onClick={element.onClick}
               className={`navigation-tabs-element`}>
    {element.name}
    {showDivider ? <span className={'navigation-tabs-element-divider'}/> : null}
    {element.active ? <div className={'navigation-tabs-element-active'}/> : null}
  </span>

}

export default NavigationTabElement;