import {Question, SignOut} from "@phosphor-icons/react";

export const MenuLogoutElement = {
  name: 'Logout',
  icon: <SignOut size={18} color="#212121"/>,
  active: false,
}

export const MenuFAQElement = {
  name: 'Need help?',
  icon: <Question size={18} color="#212121"/>,
  active: false, // this "active" field will be overridden by pathname.includes logic
}