import './skill-chiclet.scss';

interface IChiclet {
  text: string,
  active?: boolean,
  onClick?: (aspiration: string) => void
}

const SkillChiclet = (props: IChiclet) => {
  const {
    text,
    active,
    onClick
  } = props;

  return <span className={`skill-chiclet 
      ${active ? 'skill-chiclet-active' : ''} 
      ${onClick ? 'skill-chiclet-clickable' : ''}`}
    onClick={onClick ? () => onClick(text) : () => {}}
  >
    {text}
  </span>
}

export default SkillChiclet;