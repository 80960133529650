import {useContext} from "react";
import {UserContext} from "../../../hooks/Context/UserContext";
import {PermissionType} from "../../../constants/types/PermissionType";
import MenuLayout from "../../Menu/MenuLayout";
import {CplAdminMenuOptions, CplSuperAdminMenuOptions} from "../../../constants/menuOptions/CplAdminMenuOptions";

const CplAdminLayout = () => {
  const [user] = useContext(UserContext);

  if (user.permission_groups?.includes(PermissionType.cpl_super_admin)) {
    return <MenuLayout menuOptions={CplSuperAdminMenuOptions}/>
  }

  if (user.permission_groups?.includes(PermissionType.cpl_admin)) {
    return <MenuLayout menuOptions={CplAdminMenuOptions}/>
  }

  return null;
}

export default CplAdminLayout;