import {useQuery} from '@tanstack/react-query';
import {useAxios} from '../../hooks/axios/useAxios';
import {marketplaceInstitutionsUrl} from "../../constants/api/soft-user/endpoints";

interface QueryParams {
  portfolio_id?: string,
  location: [number, number] | undefined,
  searchParam: string,
  userIsSoft?: boolean
}

export const useQueryInstitutions = (params: QueryParams) => {
  const {
    portfolio_id,
    location,
    searchParam,
    userIsSoft
  } = params;
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(marketplaceInstitutionsUrl(userIsSoft),
      {
        params: {
          portfolio_id,
          search_term: searchParam,
          location_lat: location ? location[0] : undefined,
          location_lon: location ? location[1] : undefined
        }
      })
      .then(response => response?.data?.education_institutions ?? response);
  };

  return useQuery({
    queryKey: ['institutions'],
    queryFn: handleRequest
  })
};
