import { Clock, Note } from "@phosphor-icons/react";

export const generateAssessmentIcons = (item: any) => {
  return [
    {
      description: item?.duration || 'N/A',
      icon: <Clock size={28} color="#212121" />
    },
    {
      description: item?.number_of_questions || 'N/A',
      icon: <Note size={28} color="#212121" />
    }
  ];
};
