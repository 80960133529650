import './portfolio-chiclet.scss';
import {PortfolioStatusTypes} from "../../../../hooks/Context/PortfolioContext";

const PortfolioChiclet = ({status}: { status?: PortfolioStatusTypes }) => {

  if (!status) {
    return null;
  }

  return <span className={`portfolio-chiclet portfolio-chiclet-${status.replaceAll(' ', '_').toLowerCase()}`}>
    {status}
  </span>
}

export default PortfolioChiclet;