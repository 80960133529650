import {ReactNode, useEffect, useState} from "react";

import './menu.scss';
import MenuElementKMQ from "./components/MenuElement/MenuElementKMQ";
import {MenuFAQElement} from "../../../constants/menuOptions/MenuFooterElement";
import {useLocation, useNavigate} from "react-router-dom";

export type MenuOption = {
  name: string,
  icon: ReactNode,
  onClick: () => void,
  active: boolean
}

interface MenuProps {
  options: MenuOption[]
}

const MenuKMQ = (props: MenuProps) => {
  const {
    options
  } = props;

  const navigate = useNavigate();
  const location = useLocation();
  const [footerBottomPx, setFooterBottomPx] = useState<string>('');

  useEffect(() => {
    const onScroll = () => {
      if (document.getElementsByClassName('footer')[0].getBoundingClientRect().y < Math.max(document.documentElement.clientHeight, window.innerHeight)) {
        setFooterBottomPx(String(Math.max(document.documentElement.clientHeight, window.innerHeight) - document.getElementsByClassName('footer')[0].getBoundingClientRect().y + 8) + 'px');
      } else {
        setFooterBottomPx('');
      }
    };

    setFooterBottomPx('');
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [location]);

  return <div className={'menu'}>
    {options.length > 0
      ? options.map((item: MenuOption, index: number) =>
        <MenuElementKMQ element={item} index={index}/>)
      : null
    }

    <div className={'menu-footer'} style={{bottom: footerBottomPx}}>
      {
        location.pathname.includes('cpl')
          ? <MenuElementKMQ
            element={{
              ...MenuFAQElement,
              active: location.pathname.includes('cpl-applicant')
                ? location.pathname === '/cpl-applicant/faq'
                : location.pathname === '/cpl-admin/faq',
              onClick: location.pathname.includes('cpl-applicant')
                ? () => navigate('/cpl-applicant/faq')
                : () => navigate('/cpl-admin/faq')
            }}
            index={-1}
          />
          : null
      }
    </div>
  </div>
}

export default MenuKMQ;