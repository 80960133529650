import React from "react";
import "./page-not-found.scss";
import { useNavigate } from "react-router-dom";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import KMQHeader from "../../../components/Headers/KMQHeader";

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();
  const isLoggedIn = !!localStorage.getItem('refresh');

  const handleBackToLogin = () => {
    navigate("/login");
  };

  const handleBackToProfile = () => {
    navigate("/cpl-applicant")
  };

  return (
    <>
      <KMQHeader />
      <div className="page-not-found">
        <div className="page-not-found-subtitle">Page not found</div>
        <p className="page-not-found-message">
          Sorry, but the page you're looking for couldn't be found. It may have
          been removed, had its name changed, or is temporarily unavailable.
        </p>
        <div className="page-not-found-action">
          {isLoggedIn ? (
            <ButtonKMQ onClick={handleBackToProfile}>Back to Dashboard</ButtonKMQ>
          ) : (
            <ButtonKMQ onClick={handleBackToLogin}>Back to Log in</ButtonKMQ>
          )}
        </div>
      </div>
    </>
  );
};

export default PageNotFound;