import {useNavigate, useParams} from "react-router-dom";
import {useEffect} from "react";
import { jwtToJson } from "../../../../lib/jwt/jwtToJson";
import Loading from "../../../../components/UI/Loading/Loading";

const ForgotPasswordToken = () => {
  const navigate = useNavigate();
  const {token} = useParams();

  useEffect(() => {
    if (token) {
      try {
        jwtToJson(token);
        localStorage.setItem('reset-password-token', token);
        navigate('/login/reset-password');
      } catch (e) {
        //navigate('/login');
      }
    }
  }, [navigate, token])

  return <Loading loading/>
}

export default ForgotPasswordToken;