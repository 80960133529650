import './icon-chiclet.scss';

interface IconChicletProps {
  text: string,
  icon: any
}

const IconChiclet = (props: IconChicletProps) => {
  const {
    text,
    icon
  } = props;
  return <span className={'icon-chiclet'}>
    <img
      src={icon}
      alt={'icon'}
      className={'icon-chiclet-image'}
    />
    <span className={'icon-chiclet-text'}>
      {text}
    </span>
  </span>
}

export default IconChiclet;