import {useNavigate} from "react-router-dom";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";

const UserProgramsEmpty = ({text}: {text: string}) => {
  const navigate = useNavigate();

  return <div className={'user-programs-empty'}>
    <div  className={'user-programs-empty-container'}>
      <div
        className={'user-programs-empty-text'}
        dangerouslySetInnerHTML={{__html: text}}
      />

      <div className={'user-programs-empty-button'}>
        <ButtonKMQ onClick={() => navigate('/cpl-applicant')}>
          Go to My Account
        </ButtonKMQ>
      </div>
    </div>
  </div>
}

export default UserProgramsEmpty;