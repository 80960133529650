import React, { useContext } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import './dashboard.scss';
import SummaryCard from '../../../components/UI/Cards/Summary/SummaryCard';
import ResultCard from '../components/AssessmentResultCards/ResultCard';
import { Assessment } from '../assessment/Assessment';
import { useQueryProgress } from '../../../api/assessment/useQueryProgress';
import { useQueryEmployeeDashboard } from '../../../api/assessment-admin/useQueryEmployeeDashboard';
import { assessmentData } from '../../../constants/assessments/results';
import { UserContext } from "../../../hooks/Context/UserContext";
import { PermissionType } from "../../../constants/types/PermissionType";
import { useEmployeeProfileInfo } from "../../../api/assessment-admin/useEmployeeProfileInfo";
import Back from "../../../components/UI/Back/Back";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const { id: userId } = useParams<{ id: string }>();
  const [user] = useContext(UserContext);
  const isAdminUser = user.permission_groups?.includes(PermissionType.assessments_admin);

  const { data: progressDataEmployee, isLoading: isLoadingEmployee } = useQueryEmployeeDashboard(userId || '');
  const { data: progressData, isLoading } = useQueryProgress();

  const isRequestingEmployeeData = Boolean(userId);

  const effectiveProgressData = isRequestingEmployeeData ? progressDataEmployee : progressData;
  const effectiveIsLoading = isRequestingEmployeeData ? isLoadingEmployee : isLoading;

  const { data: employeeProfile } = useEmployeeProfileInfo(isAdminUser ? userId || '' : '');

  const summaryData = [
    {
      number: effectiveProgressData?.assessments?.length || 0,
      name: 'Total assessments',
    },
    {
      number: effectiveProgressData?.assessments?.filter((item: Assessment) => item.status === 'completed').length || 0,
      name: 'Assessments completed',
    },
  ];

  return (
      <div className="worker-dashboard">
        {isAdminUser && isRequestingEmployeeData && employeeProfile ? (
            <>
              <Back
                  text={'Back to Employees'}
                  onClick={() => navigate('/assessments/employees')}
              />
              <div className="employee-profile">
                <div className="employee-avatar">
              <span className="avatar-circle">
                {employeeProfile.first_name[0]}{employeeProfile.last_name[0] ?? employeeProfile.first_name[0]}
              </span>
                </div>
                <div className="employee-info">
                  <div className="employee-name">
                    {employeeProfile.first_name} {employeeProfile.last_name}
                  </div>
                  <div className="employee-team">
                    {employeeProfile.team}
                  </div>
                </div>
              </div>
              <div className="performance-review">
                Performance review
                <hr/>
              </div>
            </>
        ) : (
            <div className="worker-dashboard-title">Dashboard</div>
        )}
        {effectiveIsLoading ? (
            <p>Loading...</p>
        ) : (
            <>
              <SummaryCard summaryData={summaryData} />
              <div className="worker-dashboard-results">
                {assessmentData.map((item) => (
                    <ResultCard
                        key={item.assessment_id}
                        title={item.assessment_name}
                        assessment_id={item.assessment_id}
                    />
                ))}
              </div>
            </>
        )}
      </div>
  );
};

export default Dashboard;
