import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {favouriteUrl} from "../../constants/api/soft-user/endpoints";

export const useMutateCourseFeedback = ({userIsSoft}: {userIsSoft?: boolean}) => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function postFavourite(data: any) {
    return axios.post(favouriteUrl(userIsSoft), data)
  }

  return useMutation({
    mutationKey: ['programs', 'favourite'],
    mutationFn: postFavourite,
    onMutate: () => {
      document.body.className = 'body-loading';
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['programs']})
        .then(() => document.body.removeAttribute('class'));
    }
  });
}