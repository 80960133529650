import {ProgramAdminCardProps} from "./index";
import {PlarCardType} from "../PlarCard";
import {ChicletTypes} from "../../../../../constants/ui/types/ChicletTypes";
import ProgressChiclet from "../../../Chiclets/ProgressChiclet/ProgressChiclet";
import StatusChiclet from "../../../Chiclets/StatusChiclet/StatusChiclet";
import PaymentChiclet from "../../../Chiclets/PaymentChiclet/PaymentChiclet";

const ProgramAdminCardStatus = (props: ProgramAdminCardProps) => {
  const {
    type = PlarCardType.Not_started,
    chicletText,
    statusText,
    num_cpl_eligible_recommendations,
    num_paid_recommendations
  } = props;

  const cardTypeToChicletType = {
    [PlarCardType.Not_started]: ChicletTypes.Not_started,
    [PlarCardType.Accepted]: ChicletTypes.Completed,
    [PlarCardType.Completed]: ChicletTypes.Completed,
    [PlarCardType.Rejected]: ChicletTypes.Overdue,
    [PlarCardType.In_progress]: ChicletTypes.In_progress,
  }

  return <div className={`plar-card-status plar-card-status-${type.replaceAll(' ', '_')}`}>
    <div>
      {
        num_cpl_eligible_recommendations > 0
          ? <StatusChiclet
            text={'Contains CPL Eligible Courses'}
          />
          : null
      }
      {
        num_paid_recommendations > 0
          ? <PaymentChiclet
            text={`${num_paid_recommendations} ${num_paid_recommendations > 1 ? 'courses' : 'course'} paid`}/>
          : null
      }
      {
        chicletText
          ? <ProgressChiclet type={cardTypeToChicletType[type]} text={chicletText}/>
          : null
      }
    </div>
    {(statusText && statusText.length > 0)
      ? statusText.map((item: string, index: number) =>
        <div className={'plar-card-status-text'} key={index}>
          {item}
        </div>)
      : null
    }
  </div>
}

export default ProgramAdminCardStatus;