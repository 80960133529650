import './summary-card.scss';

interface SummaryData {
  name: string,
  number: number
}

interface SummaryCardProps {
  summaryData: SummaryData[]
}

const SummaryCard = (props: SummaryCardProps) => {
  const {
    summaryData
  } = props;

  return <div className={'summary-card'}>
    <div className={'summary-card-title'}>
      Summary
      <div className={'result-card-title-bottom'}/>
    </div>

    {summaryData?.length > 0
      ? <table className={'summary-card-list'}>
        <tr className={'summary-card-list-number'}>
          {summaryData.map((item: SummaryData, index: number) =>
            <td key={index}>
              {item.number}
            </td>
          )}
        </tr>
        <tr className={'summary-card-list-name'}>
          {summaryData.map((item: SummaryData, index: number) =>
            <td key={index}>
              {item.name}
            </td>
          )}
        </tr>
      </table>
      : null
    }
  </div>
}

export default SummaryCard;