import './login-verification.scss';
import AccessCode from "../../../components/UI/AccessCode/AccessCode";
import {useContext, useEffect, useState} from "react";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {useMfaLogin} from "../../../api/login/useMfaLogin";
import {UserContext} from "../../../hooks/Context/UserContext";
import {useNavigate} from "react-router-dom";
import {useNewMfaCode} from "../../../api/login/useNewMfaCode";
import {toast} from "react-toastify";
import {useToken} from "../../../hooks/jwt/useToken";

const TIME_BEFORE_NEW_CODE = 30 * 1000;

const LoginVerification = () => {
  const [user, setUser] = useContext(UserContext);
  const navigate = useNavigate();

  const [selectedForPayment, setSelectedForPayment] = useState<boolean>(false);
  const [code, setCode] = useState<(number | undefined)[]>([]);
  const [canResendCode, setCanResendCode] = useState<boolean>(false);
  const [showResendCodeError, setShowResendCodeError] = useState<boolean>(false);
  const {setTokens} = useToken();

  const {
    mutate: postCode,
    isSuccess: postCodeSuccess,
    isError,
    reset,
    data
  } = useMfaLogin();
  const {
    mutate: sendNewCode,
    isSuccess: newCodeSuccess
  } = useNewMfaCode();

  const handleSubmit = () => {
    postCode({
      email: user.email ?? '',
      code: code.join('')
    })
  }

  const allowNewCode = () => {
    setCanResendCode(true);
    setShowResendCodeError(false);
  }

  const handleNewCode = () => {
    if (canResendCode) {
      setCanResendCode(false);
      setShowResendCodeError(false);
      setCode([]);
      reset();
      sendNewCode({email: user.email ?? ''});
    } else {
      setShowResendCodeError(true);
    }
  }

  useEffect(() => {
    if (!user.email) {
      let savedEmail = localStorage.getItem('user-email');
      if (savedEmail) {
        setUser({
          ...user,
          email: savedEmail
        })
      } else {
        navigate('/login');
      }
    }
  }, [user])

  useEffect(() => {
    const urlParams = new URLSearchParams(decodeURI(window.location.search));
    const paymentInfo = urlParams.get('payment');
    if (paymentInfo) {
      setSelectedForPayment(!!paymentInfo);
    }

    setTimeout(() => {
      allowNewCode();
    }, TIME_BEFORE_NEW_CODE)
  }, [])

  useEffect(() => {
    if (newCodeSuccess) {
      toast.success('A new code has been sent to your email');
      setTimeout(() => {
        allowNewCode();
      }, TIME_BEFORE_NEW_CODE)
    }
  }, [newCodeSuccess])

  useEffect(() => {
    if (postCodeSuccess && data.data?.access) {
      localStorage.removeItem('user-email');
      setUser({
        ...user,
      });
      setTokens({
        accessToken: data?.data?.access,
        refreshToken: data?.data?.refresh
      })
      localStorage.setItem('refresh', data?.data?.refresh)
      if (localStorage.getItem('portfolio_id')) {
          localStorage.removeItem('portfolio_id');
          localStorage.removeItem('marketplace_user');
          localStorage.removeItem('basic_info');
          setUser({
            ...user,
            isSoft: false
          });
          if (selectedForPayment) {
            navigate('/cpl-applicant/payment/session-id')
          } else {
            navigate('/cpl-applicant')
          }
      } else {
        navigate('/');
      }
    }
  }, [postCodeSuccess, data])

  return <div className={'login-verification'}>
    <div className={'login-verification-title'}>
      Enter code
    </div>
    <div className={'login-verification-text'}>
      Check your email for a one-time code. Enter the code below to proceed. If you haven't received it, please check
      your spam folder or request a new code.
    </div>
    <div className={'login-verification-code'}>
      <AccessCode
        length={6}
        code={code}
        onChange={setCode}
        label={'Access code'}
        error={isError ? 'Wrong access code' : ''}
        resendError={showResendCodeError ? 'Please wait at least 30 seconds to request a new code.' : ''}
        handleNewCode={handleNewCode}
        onSubmit={handleSubmit}
      />
    </div>
    <div className={'login-verification-footer'}>
      <ButtonKMQ onClick={handleSubmit} disabled={code.length !== 6}>
        Log in
      </ButtonKMQ>
    </div>
  </div>
}

export default LoginVerification;