import {useMutation, useQueryClient} from "@tanstack/react-query";
import {assignPortfolioUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useAssignPortfolio = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function postAssign(data: {portfolio_id: string, assign: boolean}) {
    return axios.post(assignPortfolioUrl, data)
  }

  return useMutation({
    mutationKey: ['assign-applicant'],
    mutationFn: postAssign,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user'] })
  });
}