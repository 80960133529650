import { useQuery } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";

const employeeListURL = "assessment-engine/user-list";

interface GetEmployeeListParams {
    current_page?: number;
    items_per_page?: number;
    search_term?: string;
    ordering?: string;
}

export const useQueryEmployees = (params: GetEmployeeListParams) => {
    const { current_page, items_per_page, search_term, ordering } = params;
    const axios = useAxios();

    const handleRequest = async () => {
        const response = await axios.get(employeeListURL, {
            params: {
                current_page,
                items_per_page,
                search_term,
                ordering,
            },
        });
        return response?.data ?? response;
    };

    return useQuery({queryKey: ['employees'], queryFn: handleRequest})
};
