import {AssessmentQuestionProps} from "../AssessmentQuestion";
import AssessmentQuestionContent from "./AssessmentQuestionContent";
import AssessmentAnswer from "../../AssessmentAnswer/AssessmentAnswer";
import AssessmentContent, {RadioAnswer} from "./AssessmentContent";
import {sanitizeHTML} from "../../../../../lib/string/sanitizeHtml";
import {useState} from "react";


const AssessmentQuestionBody = (props: AssessmentQuestionProps) => {
  const {question, currIndex, totalQuestions, chosen, setChosen, answers} = props;
  const [multipleAns, setMultipleAns] = useState<RadioAnswer>({ans: {}});

  const handleMultipleAns = (sub_question_id: number, ans: number) => {
    setMultipleAns(prevState => {
      const newAns = {...prevState.ans, [String(sub_question_id)]: String(ans)};
      setChosen(Object.values(newAns));
      return {...prevState, ans: newAns};
    });
  };

  return (
    <div className={"assessment-question"}>
      <div className={"assessment-question-number"}>
        {`${currIndex} of ${totalQuestions} ${
          question?.question_category ? "| " + question?.question_category : ""
        }`}
      </div>
      <div className="assessment-question-question"
      dangerouslySetInnerHTML={{ __html: sanitizeHTML(question?.question_text || '') }}
      />
      {question?.center
        ? question.center.map((item, index) => (
          <div key={index}>
            {item.content_type === "options" ||
            item.content_type === "image_options" ? (
              item.content.map((ele, key) => (
                <AssessmentAnswer
                  text={ele.content ? ele.content : ele.text}
                  chosen={chosen === String(ele.id)}
                  setChosen={() => setChosen(String(ele.id))}
                  key={key}
                  content_type={item.content_type}
                />
              ))
            ) : (
              <AssessmentQuestionContent
                type={item.content_type}
                content={item.content}
              />
            )}
          </div>
        ))
        : null}

      {question?.right
        ? question.right.map((item, index) => (
          <div key={index}>
            {typeof item.content === "string" ? (
              <div className={"assessment-question-question"}
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.content) }}
              />
            ) : question && item.content_type !== 'options'
              ? <AssessmentContent rightContent={question.right} setChosen={setChosen} currIndex={currIndex}
                                   answers={answers}/>
              : item.content.map((ele, key) => (
                <AssessmentAnswer
                  text={ele.content ? ele.content : ele.text}
                  chosen={question.question_type === 'multiple_ans_choice'
                    ? String(multipleAns.ans[item.sub_question_id]) === String(ele.id)
                    : chosen === String(ele.id)
                  }
                  setChosen={() => setChosen(String(ele.id))}
                  key={key}
                  content_type={item.content_type}
                  question_type={question.question_type}
                  handleMultipleAns={() => handleMultipleAns(item.sub_question_id, ele.id)}
                />
              ))
            }
          </div>
        ))
        : null}
    </div>
  );
};

export default AssessmentQuestionBody;
