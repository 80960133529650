import {useMutation, useQueryClient} from "@tanstack/react-query";
import {evidenceFeedbackUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useMutateEvidenceFeedback = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function postFeedback(data: any) {
    return axios.post(evidenceFeedbackUrl, data)
  }

  return useMutation({
    mutationKey: ['evidences', 'feedback'],
    mutationFn: postFeedback,
    onMutate: () => {
      document.body.className = 'body-loading';
    },
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ['evidences']})
        .then(() => document.body.removeAttribute('class'));
    }
  });
}