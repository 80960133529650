import {useMutation, useQueryClient} from "@tanstack/react-query";
import {courseFeedbackUrl} from "../../constants/api/endpoints";
import {toast} from "react-toastify";
import {useAxios} from "../../hooks/axios/useAxios";

export const useMutateCourseFeedback = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function postFeedback(data: any) {
    return axios.post(courseFeedbackUrl, data)
  }

  return useMutation({
    mutationKey: ['courses', 'feedback'],
    mutationFn: postFeedback,
    onSuccess: () => {
      // TODO remove courses call after backend changes
      queryClient.invalidateQueries({queryKey: ['courses']})
      queryClient.invalidateQueries({queryKey: ['evidences']})
      toast.success('Feedback submitted. Thank you for helping us improve our product!');
    }
  });
}