import './toast.scss';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {XCircle} from "@phosphor-icons/react";
import {CloseButtonProps} from "react-toastify/dist/components/CloseButton";

const ToastContainerUI = () => {

  const customCloseButton = ({closeToast}: CloseButtonProps) =>
    <span className={'toast-close'} onClick={closeToast}>
      <XCircle size={24} color="#212121"/>
    </span>;

  return <span className={'toast-container'}>
    <ToastContainer
      position="top-right"
      theme="colored"
      hideProgressBar
      icon={false}
      closeButton={customCloseButton}
    />
  </span>
}

export default ToastContainerUI;