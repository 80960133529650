import './back.scss';
import {ArrowCircleLeft} from "@phosphor-icons/react";

interface BackProps {
  text: string,
  onClick: () => void
}

const Back = (props: BackProps) => {
  const {
    text,
    onClick
  } = props;

  return <div className={'back'} onClick={onClick}>
    <span className={'back-icon'}>
      <ArrowCircleLeft size={28} color="#212121" />
    </span>
    <span className={'back-text'}>
      {text}
    </span>
  </div>
}

export default Back;