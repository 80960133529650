import {useMutation} from "@tanstack/react-query";
import {createSoftUserURL} from "../../constants/api/soft-user/endpoints";
import {jsonToJwt} from "../../lib/jwt/jsonToJwt";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";
import {useAxios} from "../../hooks/axios/useAxios";

interface SoftUser {
  email?: string,
  first_name?: string,
  last_name?: string,
  password?: string,
  confirmation_password?: string,
  date_of_birth?: string,
}

export const useSoftToHardApplicant = () => {
  const axios = useAxios();

  const createUser = (body: SoftUser) => {
    return axios.put(
      backendBaseUrl + createSoftUserURL,
      body
    ).then(resp => resp.data)
  }

  return useMutation({
    mutationKey: ['soft-user'],
    mutationFn: createUser,
    retry: false
  })
}