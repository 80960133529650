import './my-profile.scss';
import {useQueryUser} from "../../../api/admin/useQueryAdmin";
import UserProfile from "../../../components/UI/Profile/UserProfile";
import TextField from "../../../components/UI/TextField/TextField";
import Loading from "../../../components/UI/Loading/Loading";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {useNavigate} from "react-router-dom";

const MyProfile = () => {
  const navigate = useNavigate();
  const {data, isLoading} = useQueryUser();

  const handleChangePassword = () => {
    navigate(`/login/reset-password`);
  }

  if (isLoading) {
    return <Loading loading/>
  }

  return <div className={'my-profile'}>
    <UserProfile
      name={{
        first_name: data?.first_name ?? '',
        last_name: data?.last_name ?? ''
      }}
      showText
      size={'large'}
    />

    <div className={'my-profile-title'}>
      Profile info
    </div>
    <TextField
      label={'Name'}
      value={`${data?.first_name} ${data?.last_name}`}
    />
    <TextField
      label={'Contact information'}
      value={data?.email}
    />

    <div className={'my-profile-footer'}>
      <ButtonKMQ onClick={handleChangePassword}>
        Change password
      </ButtonKMQ>
    </div>
  </div>
}

export default MyProfile;