import './error.scss';
import ErrorIcon from "../../../assets/icons/error/error.svg";

interface ErrorProps {
  error?: string
}

const ErrorKMQ = (props: ErrorProps) => {
  const {
    error
  } = props;

  if (!error) {
    return null;
  }

  return <div className={'error-container'}>
    <img src={ErrorIcon} alt={'error'}/>
    <span className={'error-container-text'}>
      {error}
    </span>
  </div>
}

export default ErrorKMQ;