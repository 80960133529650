import './expandable-text.scss';
import {useState} from "react";
import SelectOpen from '../../../assets/icons/UI/select/arrow-down.svg';
import SelectClose from '../../../assets/icons/UI/select/arrow-up.svg';

interface ExpandableTextProps {
  title: string,
  expandableHtml: string
}

const ExpandableText = (props: ExpandableTextProps) => {
  const {
    title,
    expandableHtml
  } = props;

  const [isOpen, setIsOpen] = useState<boolean>(false);

  return <div className={'expandable-text'}>
    <div className={'expandable-text-title'} onClick={() => setIsOpen(!isOpen)}>
      {title}
      <img
        src={isOpen ? SelectClose : SelectOpen}
        alt={isOpen ? 'close' : 'open'}
        className={'expandable-text-title-icon'}
      />
    </div>
    {
      isOpen ?
        <div
          className={'expandable-text-content'}
          dangerouslySetInnerHTML={{__html: expandableHtml}}
        />
        : null
    }
  </div>
}

export default ExpandableText;