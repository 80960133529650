import { Resume } from "../resume-parser/redux/types";

export const resumeToProfile = (resume: any) => {
  return {
    portfolio_user_name: resume.basic_info?.full_name ?? "",
    portfolio_user_email: resume.basic_info?.email ?? "",
    basic_info: {
      full_name: resume.basic_info?.full_name ?? "",
      email: resume.basic_info?.email ?? "",
    },
    jobs: resume.work_experience.map((item: any) => ({
      title: item.job_title ?? "",
      company: item.company ?? "",
      current_job: !!item.current_job,
      description: item.job_summary ?? "",
      normalization_choice: item.normalized ?? " ",
      location: item.location ?? "",
      start_date: item.start_date,
      end_date: item.end_date,
    })),
    educations: resume.education.map((item: any) => ({
      title: item.program ?? "",
      program: item.program ?? "", // ensure these fields are correctly sourced
      degree_type: item.degree_type ?? "",
      normalization_choice: item.normalized ?? " ",
      start_date: item.start_date,
      end_date: item.end_date,
      institution_name: item.institution_name ?? "",
      institution_type: item.institution_type ?? "",
      location: item.location ?? "",
      graduated: item.currently_enrolled ? !item.currently_enrolled : true,
    }))

  };
};
