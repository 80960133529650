import "./marketplace.header.scss";
import MarketplaceBg from "../../../assets/bg/marketplace_bg.svg";
import BookIcon from "../../../assets/icons/marketplace/book-marketplace.svg";
import WaveIcon from "../../../assets/icons/marketplace/wave-marketplace.svg";
import MedalIcon from "../../../assets/icons/marketplace/medal-marketplace.svg";

const MarketplaceHeader = () => {
  return <div className={'marketplace-header'}>
    <div className={'marketplace-header-title'}>
      <div className={'marketplace-header-title-text'}>
        <div className={'marketplace-header-title-text-name'}>
          Marketplace
        </div>
        <div className={'marketplace-header-title-text-description'}>
          Connect with institutions and fast-track your upskilling journey with our Marketplace.
        </div>
      </div>
      <img
        src={MarketplaceBg}
        alt={'background'}
        className={'marketplace-header-title-background'}
      />
    </div>
    <div className={'marketplace-header-description'}>
      <span className={'marketplace-header-description-item'}>
        <img
          className={'marketplace-header-description-item-icon'}
          src={WaveIcon}
          alt={'flexibility'}
        />
        <span className={'marketplace-header-description-item-text'}>
          <div className={'marketplace-header-description-item-text-title'}>
            Flexibility
          </div>
          <div className={'marketplace-header-description-item-text-description'}>
            CPL enables flexible academic pathways
          </div>
        </span>
      </span>

      <span className={'marketplace-header-description-item'}>
        <img
          className={'marketplace-header-description-item-icon'}
          src={MedalIcon}
          alt={'credentialing'}
        />
        <span className={'marketplace-header-description-item-text'}>
          <div className={'marketplace-header-description-item-text-title'}>
            Credentialing
          </div>
          <div className={'marketplace-header-description-item-text-description'}>
            Your gateway to recognized learning
          </div>
        </span>
      </span>

      <span className={'marketplace-header-description-item'}>
        <img
          className={'marketplace-header-description-item-icon'}
          src={BookIcon}
          alt={'accreditation'}
        />
        <span className={'marketplace-header-description-item-text'}>
          <div className={'marketplace-header-description-item-text-title'}>
            Accreditation
          </div>
          <div className={'marketplace-header-description-item-text-description'}>
            Turn experience into academic experience
          </div>
        </span>
      </span>
    </div>
  </div>
}

export default MarketplaceHeader;