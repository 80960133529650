import {useEffect, useState} from "react";

export const useUserLocation = () => {
  const [location, setLocation] = useState<[number, number] | undefined>(undefined);

  useEffect(() => {
    const locationSuccess = (position: GeolocationPosition) => {
      setLocation([position.coords.latitude, position.coords.longitude]);
    }
    const locationError = () => {
      console.log("Unable to retrieve your location");
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(locationSuccess, locationError);
    } else {
      locationError();
    }
  }, [])

  return location;
}