import "./assessmentResults.scss";
import SelectIconUp from "../../../../../assets/icons/UI/select/arrow-up.svg";
import SelectIconDown from "../../../../../assets/icons/UI/select/arrow-down.svg";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ResultGrading from "../../../components/AssessmentResultCards/components/ResultGrading/ResultGrading";
import { SampleAQData } from "../../../../../constants/results/sampleResults";
import Back from "../../../../../components/UI/Back/Back";
import { UserContext } from "../../../../../hooks/Context/UserContext";
import { PermissionType } from "../../../../../constants/types/PermissionType";
import { useEmployeeProfileInfo } from "../../../../../api/assessment-admin/useEmployeeProfileInfo";
import { useQueryResultsDashboard } from "../../../../../api/assessment/useQueryResultsDashboard";
import { sanitizeHTML } from "../../../../../lib/string/sanitizeHtml";

export interface ResultsListFormat {
  title?: string;
  percentage: number;
  percentage_range?: string;
  text_List?: string[];
  text?: string;
}

export interface ResultsFormat {
  assessment_id?: number;
  assessment_name?: string;
  type?: string;
  overall_text?: string[];
  total_score?: number;
  results?: ResultsListFormat[];
}

export interface AQcontent {
  content_type: string;
  content: string;
}

export interface ResultContentItem {
  content_type: string;
  content: string;
  score?: number;
  subcontent?: string;
  left?: AQcontent[];
  right?: AQcontent[];
}

const AssessmentResults = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { assessment_id, id: userId } = useParams<{ assessment_id: any; id?: string }>();
  const { data } = useQueryResultsDashboard(assessment_id, userId);
  const [user] = useContext(UserContext);
  const isAdminUser = user.permission_groups?.includes(PermissionType.assessments_admin);
  const isRequestingEmployeeData = Boolean(userId);
  const { data: employeeProfile } = useEmployeeProfileInfo(isAdminUser ? userId || '' : '');

  // initialize all dropdowns as closed
  const [showDropdown, setShowDropdown] = useState<boolean[]>(
    Array(SampleAQData.results.length).fill(false)
  );

  const toggleVisibility = (index: number) => {
    setShowDropdown((prev) => {
      const newVisibleItems = [...prev];
      newVisibleItems[index] = !newVisibleItems[index];
      return newVisibleItems;
    });
  };

  const renderContent = (item: ResultContentItem, index: number) => {
    if (item.content_type === "h1") {
      return <div className={"assessment-results-title"}>{item.content}</div>;
    } else if (item.content_type === "score") {
      return (
        <div className={"assessment-results-accordion-grade"}>
          <ResultGrading percentage={Number(item.content)} size={index === 1 ? "large" : "small"} />
        </div>
      );
    } else if (item.left && item.right) {
      return (
        <div className="assessment-results-combination">
          <div className="assessment-results-combination-left">
            {item.left.map((leftItem: AQcontent, index: number) =>
              renderContent(leftItem, index)
            )}
          </div>
          <div className="assessment-results-combination-right">
            {item.right.map((rightItem: AQcontent, index: number) =>
              renderContent(rightItem, index)
            )}
          </div>
        </div>
      );
    } else if (
      item.content_type === "h2" ||
      item.content_type === "h3" ||
      item.content_type === "text"
    ) {
      return (
        <div className={`assessment-results-${item.content_type}`}>
          {item.content}
        </div>
      );
    } else if (item.content_type === "h3-dropdown" && item.score !== undefined) {
      return (
        <div className={`${index > 0 ? "assessment-results-desp" : ""}`}>
          <div
            className={"assessment-results-accordion"}
            onClick={() => toggleVisibility(index)}
            key={index}
          >
            <div>{item.content}</div>
            <img
              src={showDropdown[index] ? SelectIconDown : SelectIconUp}
              alt={"dropdown"}
            />
          </div>
          <div
            className={`${
              !showDropdown[index] ? "assessment-results-accordion-hidden" : ""
            }`}
          >
            <div className="assessment-results-accordion-grade">
              <ResultGrading percentage={item.score} size="small" />
            </div>
            <div className={`assessment-results-text`} key={index}
            dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.subcontent || '') }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {isAdminUser && isRequestingEmployeeData && employeeProfile ? (
        <>
          <Back
            text={`Back to ${employeeProfile.first_name}  ${employeeProfile.last_name}`}
            onClick={() => navigate(`/assessments/employees/${employeeProfile.id}`)}
          />
        </>
      ) : (
        <Back
          text={"Back to Dashboard"}
          onClick={() => navigate("/assessments/dashboard")}
        />
      )}
      <div className={"assessment-results"}>
        <div className={"assessment-results-total"}>
          {data &&
            data.results.map((item: ResultContentItem, index: number) =>
              renderContent(item, index)
            )}
        </div>
      </div>
    </>
  );
};

export default AssessmentResults;
