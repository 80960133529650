
import './result-card.scss';
import ResultGrading from "./components/ResultGrading/ResultGrading";
import { useQueryResultsDashboard } from '../../../../api/assessment/useQueryResultsDashboard';
import { AQcontent, ResultContentItem } from '../../assessment/[assessment_id]/results/AssessmentResults';
import ResultCardFooter from './components/ResultCardFooter/ResultCardFooter';
import { useNavigate, useParams } from 'react-router-dom';

interface ResultCardProps {
  title: string;
  assessment_id: number;
}

const ResultCard = (props: ResultCardProps) => {
  const {
    title,
    assessment_id,
  } = props;

  const { id: userId } = useParams<{ id: string }>();
  const isAdmin = Boolean(userId);
  const navigate = useNavigate();
  const { data } = useQueryResultsDashboard(assessment_id, isAdmin ? userId : undefined);

  const renderContent = (item: ResultContentItem, index: number) => {
    if (item.content_type === "score") {
      return (
          <div className={`assessment-results-grade ${index === 1 ? "assessment-results-grade-large" : null}`}>
            <ResultGrading
                percentage={Number(item.content)}
                size={index === 1 ? "large" : "small"}
            />
          </div>
      );
    } else if (item.content_type === "score") {
      return (
          <div className="assessment-results-grade">
            <ResultGrading percentage={Number(item.content)} size="small" />
          </div>
      );
    } else if (item.left && item.right) {
      return (
          <div className={`assessment-results-combination-${title.replaceAll(' ','').toLowerCase()}`}>
            {item.left.map((item: AQcontent, index: number) =>
                renderContent(item, index)
            )}
            {item.right.map((item: AQcontent, index: number) =>
                renderContent(item, index)
            )}
          </div>
      );
    }
    else if(item.content_type === "h3" && assessment_id !== 8 && assessment_id !== 7 && assessment_id !== 6) {
      return (
          <div className={`assessment-results-h3`}>
            {item.content}
          </div>
      );
    }

    else if (item.content_type === "h3-dropdown" && item.score) {
      return (
          <div className={`assessment-results-dropdown-${title.replaceAll(' ','').toLowerCase()}`}>
            <div className="assessment-results-dropdown-grade">
              <ResultGrading percentage={item.score} size="small" />
            </div>
            <div className='assessment-results-dropdown-h3'>
              {item.content}
            </div>

          </div>
      );
    }
  };

  const handleViewResults = () => {
    if (isAdmin) {
      navigate(`/assessments/employees/${userId}/${assessment_id}/results`);
    } else {
      navigate(`/assessments/dashboard/${assessment_id}/results`);
    }
  };

  return (
      <div key={assessment_id} className={`result-card ${data && data.results && data.results.length > 4 || assessment_id === 4  ? 'result-card-large' : ''}`}>
        <div className={'result-card-title'}>
          {title}
          <div className={'result-card-title-bottom'}/>
        </div>
        {
          data
              ? <>
                <div className={'result-card-grading'}>
                  {
                      data && data.results.map((item: ResultContentItem,index: number) => renderContent(item, index))
                  }
                </div>
              </>
              : <div className={'result-card-grading'}><ResultGrading percentage={0} size="large" /></div>
        }
        <ResultCardFooter
            onViewResults={handleViewResults}
            onViewDetails={() => navigate(`/assessments/assessment/${assessment_id}/details`)}
            disabled={!data}
        />
      </div>
  );
};

export default ResultCard;
