import {useQuery} from "@tanstack/react-query";
import {reportUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

interface GetReportParams {
  portfolio_id?: string | null,
}

export const useQueryReport = (params: GetReportParams) => {
  const {
    portfolio_id,
  } = params;

  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(reportUrl, {
      params: {
        portfolio_id,
      }
    })
      .then(response => response?.data ?? response);
  }

  return useQuery({queryKey: ['report'], queryFn: handleRequest})

}
