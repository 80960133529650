import './assessment-content.scss';
import { useState } from 'react';
import { ContentItem } from '../../SelfAssessmentPage/SelfAssessmentPage';
import { AnswerFormat, Right } from '../../../assessment/[assessment_id]/AssessmentID';

interface AssessmentContentProps {
  leftContent?: ContentItem[];
  rightContent?: Right[];
  questionId?: number;
  answers?: AnswerFormat[],
  currIndex?:number,
  setChosen?: (answer: string | string[]) => void
}

export interface RadioAnswer {
  ans: { [key: number]: string };
}

const AssessmentContent = (props: AssessmentContentProps) => {
  const { leftContent, rightContent, setChosen, answers, currIndex } = props;
  const combinedContent = [...(leftContent || []), ...(rightContent || [])];
  const [radioAns, setRadioAns] = useState<RadioAnswer>({ans: {}});
  
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, rowIndex: number) => {
    const selectedId = event.target.id;
    setRadioAns(prevState => {
      const newAns = { ...prevState.ans, [rowIndex]: selectedId };
      if (setChosen) {
        setChosen(Object.values(newAns));
      }
      return { ...prevState, ans: newAns };
    });
  };

  const renderContent = (type: string, content: any) => {
    if (type === 'image') {
      return (
        <div className={'assessment-container-content-image'}>
          <img src={content} alt={'reading-main'} />
        </div>
      );
    }

    if (type === 'title') {
      return <div className={'assessment-container-content-title'}>{content}</div>;
    }

    if (type === 'list') {
      return (
        <ol className={'assessment-container-content-list'}>
          {content.map((item: string, index: number) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      );
    }

    if (type === 'footer') {
      return <div className={'assessment-container-content-footer'}>{content}</div>;
    }


    if (type === 'table') {
      return (
       <div className='assessment-table-div'>
         <table className={'assessment-container-content-right-table'}>
          {content.map((raw: string[], rowIndex: number) => (
            <tr key={rowIndex}>
              {raw.map((item: string, index: number) => (
                <td key={index}>
                  {item.includes('radio') ? (
                    <div className='radio_button_div'>
                      <input
                        type='radio'
                        id={`${index}`}
                        className='radioInput'
                        onChange={(e) => handleRadioChange(e,rowIndex)}
                        checked={radioAns.ans[rowIndex] === String(index)}
                      />
                    </div>
                  ) : (
                    item
                  )}
                </td>
              ))}
            </tr>
          ))}
        </table>
       </div>
      );
    }

    return <div className={'assessment-container-content-text'}>{content}</div>;
  };

  return (
    <div>
      {combinedContent.map((item, index) => (
        <div key={index}>{renderContent(item.content_type, item.content)}</div>
      ))}
    </div>
  );
};

export default AssessmentContent;