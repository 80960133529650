import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";
import {forgotPasswordUrl} from "../../constants/api/endpoints";

export const useForgotPassword = () => {

  async function postData(data: { email: string }) {
    return axios.post(backendBaseUrl + forgotPasswordUrl,
      {
        email: data.email,
      },
    )
  }

  return useMutation({
    mutationKey: ['forgot-password'],
    mutationFn: postData,
    retry: false
  });
}