export const RejectsOptionsList = [
  {
    text:"Learning outcomes were wrong",
    desc: "Please select one or multiple learning outcomes that were wrong:",
    subRejectsOptions: [
      {
        text: "Demonstrate an understanding of fundamental design principles such as balance, proportion, and harmony."
      },
      {
        text: "Apply theoretical design concepts to analyze and critique visual compositions across various media."
      },
      {
        text: "Explore the historical and cultural contexts that shape design theory and practice."
      },
      {
        text: "Develop critical thinking skills to evaluate the effectiveness of design solutions in addressing user needs and solving problems."
      },
      {
        text: "Utilize design principles to create innovative and cohesive visual solutions that communicate effectively to target audiences."
      }
    ]
  },
  {
    text:"I disagree that a learning outcome is satisfied",
    desc: "Please select one or multiple learning outcomes that were not satisfied:",
    subRejectsOptions: [
      {
        text: "Demonstrate an understanding of fundamental design principles such as balance, proportion, and harmony."
      },
      {
        text: "Apply theoretical design concepts to analyze and critique visual compositions across various media."
      },
      {
        text: "Explore the historical and cultural contexts that shape design theory and practice."
      },
      {
        text: "Develop critical thinking skills to evaluate the effectiveness of design solutions in addressing user needs and solving problems."
      },
      {
        text: "Utilize design principles to create innovative and cohesive visual solutions that communicate effectively to target audiences."
      }
    ]
  },
  {
    text:"Problem with a portfolio",
    subRejectsOptions: [
      {
        text:"An incoherent or unintelligible portfolio sentence",
      },
      {
        text:"Portfolio not credible",
      }
    ]
  },
  {
    text:"Other"
  },
]
