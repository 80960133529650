import {useQuery} from "@tanstack/react-query";
import {adminInfoUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";


export const useQueryUser = () => {
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(adminInfoUrl)
      .then(response => response?.data ?? response);
  }

  return useQuery({
    queryKey: ['admin-info'],
    queryFn: handleRequest,
    retry: false
  })

}
