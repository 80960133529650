import './evidences.scss';
import {useNavigate, useLocation, useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import {useQueryPrograms} from "../../../api/admin/useQueryPrograms";
import {useQueryEvidences} from "../../../api/admin/useQueryEvidences";
import SkillChiclet from "../../../components/UI/Chiclets/SkillChiclet/SkillChiclet";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../constants/ui/types/ButtonTypes";
import Evidence from "../../../components/Evidence/Evidence";
import Loading from "../../../components/UI/Loading/Loading";
import {useMutateCourseFeedback} from "../../../api/admin/useMutateCourseFeedback";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import ProgressChiclet from "../../../components/UI/Chiclets/ProgressChiclet/ProgressChiclet";
import {ChicletTypes} from "../../../constants/ui/types/ChicletTypes";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {PermissionType} from "../../../constants/types/PermissionType";
import {UserContext} from "../../../hooks/Context/UserContext";

const EvidencesPage = () => {
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const location = useLocation();
  const {portfolio_id, program_id, course_code} = useParams();
  const [portfolio] = useContext(PortfolioContext);
  const [openedTables, setOpenedTables] = useState<number[]>([]);
  const [approveCourseModal, setApproveCourseModal] = useState<boolean>(false);
  const [rejectCourseModal, setRejectCourseModal] = useState<boolean>(false);

  const {data: currProgram} = useQueryPrograms({
    portfolio_id: portfolio_id,
    program_id: program_id
  });

  const {data: evidences} = useQueryEvidences({
    portfolio_id: portfolio_id,
    course_code: course_code
  });

  const {
    mutate: postFeedback,
  } = useMutateCourseFeedback();

  const toggleOpenedTable = (index: number) => {
    if (openedTables.includes(index)) {
      setOpenedTables(openedTables.filter(item => item !== index))
    } else {
      setOpenedTables([...openedTables, index])
    }
  }

  const handleApproveCourse = () => {
    postFeedback({
      course_recommendation_id: evidences.course_recommendation_id,
      approved: true,
      feedback_list: []
    });
    setApproveCourseModal(false);
  }

  const handleRejectCourse = () => {
    navigate(location.pathname + '/reject-reason');
    setRejectCourseModal(false);
  }

  if (!currProgram || !evidences) {
    return <Loading loading/>
  }

  return <div>
    <div className={'evidences'}>
      <Breadcrumbs content={[
        {
          label: 'Applicants',
          link: `/cpl-admin`
        },
        {
          label: `${portfolio.portfolio_user_name}’s profile`,
          link: `/cpl-admin/${portfolio_id}`
        },
        {
          label: `${currProgram?.name}`,
          link: `/cpl-admin/${portfolio_id}/${program_id}`
        },
        {
          label: `${evidences?.title}`,
          link: `/cpl-admin/${portfolio_id}/${program_id}/${course_code}`
        }
      ]}/>
      <div className={'evidences-chiclets'}>
        <SkillChiclet
          text={`${Math.floor(evidences.matching_score * 100)}% match`}
        />
        {
          evidences.approved
            ? <ProgressChiclet text={'Approved'} type={ChicletTypes.Completed}/>
            : null
        }
        {
          evidences.approved === false
            ? <ProgressChiclet text={'Rejected'} type={ChicletTypes.Overdue}/>
            : null
        }
      </div>
      <div className={'evidences-code'}>
        {(course_code ?? '').toUpperCase()}
      </div>
      <div className={'evidences-title'}>
        {evidences.title}
      </div>
      <div className={'evidences-description'}>
        {evidences.description}
      </div>

      <div className={'evidences-buttons'}>
        <ButtonKMQ
          onClick={() => setApproveCourseModal(true)}
          disabled={!portfolio.assigned_to_me || evidences.approved !== null}
        >
          Approve credit
        </ButtonKMQ>
        <ButtonKMQ
          onClick={() => setRejectCourseModal(true)} type={ButtonTypes.Secondary}
          disabled={!portfolio.assigned_to_me || evidences.approved !== null}
        >
          Reject credit
        </ButtonKMQ>
        <ButtonKMQ
          onClick={() => navigate(`/cpl-admin/${portfolio_id}/request-document-upload?course_code=${course_code}`)}
          type={ButtonTypes.Tertiary}
          disabled={!portfolio?.assigned_to_me && !user.permission_groups?.includes(PermissionType.cpl_super_admin)}
        >
          Request documents
        </ButtonKMQ>
      </div>

      {
        evidences && evidences.learning_outcomes.map((item: any, index: number) =>
          <Evidence
            index={index + 1}
            title={item.description}
            isOpened={openedTables.includes(index)}
            toggleOpened={() => toggleOpenedTable(index)}
            evidences={item.evidence_sentence_pairs}
            canSendFeedback={portfolio.assigned_to_me}
          />
        )
      }

      <AlertModal
        open={approveCourseModal}
        onClose={() => setApproveCourseModal(false)}
        title={'Approve course recommendation?'}
        text={`Do you wish to approve the course '${evidences.title}' for ${portfolio?.portfolio_user_name}?`}
        onProceed={handleApproveCourse}
        proceedText={'Approve credit'}
        closeText={'Cancel'}
      />

      <AlertModal
        open={rejectCourseModal}
        onClose={() => setRejectCourseModal(false)}
        title={'Reject course recommendation?'}
        text={`Do you wish to reject the course '${evidences.title}' for ${portfolio?.portfolio_user_name}?`}
        onProceed={handleRejectCourse}
        proceedText={'Reject credit'}
        closeText={'Cancel'}
      />
    </div>
  </div>
}

export default EvidencesPage;