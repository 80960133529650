import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Archive, ArrowLineDown, CaretDown } from "@phosphor-icons/react";
import "./applicant-documents.scss";
import { useDocumentDownload } from "../../../../../api/admin/useDocumentDownload";
import { useQueryDocumentRequests } from "../../../../../api/admin/useQueryDocumentRequests";
import { DocumentRequest } from "../../../../../constants/types/DocumentRequest";

const ApplicantDocuments = () => {
  const { portfolio_id } = useParams<{ portfolio_id: string }>();
  const [expandedRequest, setExpandedRequest] = useState<string | null>(null);
  const [downloadingId, setDownloadingId] = useState<string | null>(null);
  const [isDownloadingAll, setIsDownloadingAll] = useState(false);
  const { downloadDocument } = useDocumentDownload();

  const {
    data: allDocumentRequests,
    isLoading,
    error,
  } = useQueryDocumentRequests({
    portfolio_id: portfolio_id || "",
  });

  const resolvedDocuments = allDocumentRequests?.filter(
    (request) => request.status === "Request resolved"
  ) || [];

  const handleDownloadAll = async () => {
    if (!portfolio_id || isDownloadingAll) return;
    
    try {
      setIsDownloadingAll(true);
      await downloadDocument({ portfolio_id });
    } catch (error) {
      console.error("Error downloading all documents:", error);
      // @TODO: show an error toast/notification here
    } finally {
      setIsDownloadingAll(false);
    }
  };

  const handleDownloadSingle = async (documentId: string) => {
    if (downloadingId) return;
    
    try {
      setDownloadingId(documentId);
      await downloadDocument({ document_request_id: documentId });
    } catch (error) {
      console.error("Error downloading document:", error);
      // @TODO: show an error toast/notification here
    } finally {
      setDownloadingId(null);
    }
  };

  return (
    <div className="applicant-info-documents-section">
      <section className="info-section">
        <div className="info-header">
          <Archive size={20} weight="regular" />
          <h2>Documents and Files</h2>
        </div>
        {!isLoading && !error && resolvedDocuments.length > 0 && (
          <div
            className="applicant-info-documents-section-download-all"
            onClick={handleDownloadAll}
          >
            <ArrowLineDown size={22} />
            <span>{isDownloadingAll ? 'Downloading...' : 'Download all'}</span>
          </div>
        )}
      </section>
      <div className="documents-list">
        <div className="documents-header">
          <span>Request Title</span>
          <span>Request Date</span>
          <span>Download</span>
        </div>
        
        {isLoading ? (
          <div className="previous-request-text">Loading documents...</div>
        ) : (error || !resolvedDocuments.length) ? (
          <div className="previous-request-text">No available document requests</div>
        ) : (
          resolvedDocuments.map((request: DocumentRequest) => (
            <div key={request.id} className="document-item-container">
              <div className="document-item">
                <span>{request.title}</span>
                <span>{new Date(request.created_at).toISOString().slice(0, 10)}</span>
                <div className="document-item-actions">
                  <span
                    className="document-item-download"
                    onClick={() => handleDownloadSingle(request.id)}
                  >
                    {downloadingId === request.id ? 'Downloading...' : 'Download'}
                  </span>
                  <CaretDown
                    size={20}
                    className="toggle-icon"
                    onClick={() =>
                      setExpandedRequest(
                        expandedRequest === request.id ? null : request.id
                      )
                    }
                  />
                </div>
              </div>
              {expandedRequest === request.id && (
                <div className="request-details">
                  <div className="detail-row">
                    <span className="detail-label">Paid course</span>
                    <span className="detail-value">
                      {`${request.paid_course.course.code} ${request.paid_course.course.title}`}
                    </span>
                  </div>
                  <div className="detail-row">
                    <span className="detail-label">Notes to applicant</span>
                    <span className="detail-value">{request.notes}</span>
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default ApplicantDocuments;