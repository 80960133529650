import './applicants.scss';
import {useQueryApplicants} from "../../../api/admin/useQueryApplicants";
import Search from "../../../components/UI/Search/Search";
import {useContext, useEffect, useState} from "react";
import ComplexSearch from "../../../components/UI/ComplexSearch/ComplexSearch";
import {useQueryPrograms} from "../../../api/admin/useQueryPrograms";
import UserProfile from "../../../components/UI/Profile/UserProfile";
import ProgressChiclet from "../../../components/UI/Chiclets/ProgressChiclet/ProgressChiclet";
import {ChicletTypes} from "../../../constants/ui/types/ChicletTypes";
import Loading from "../../../components/UI/Loading/Loading";
import {useQueryClient} from "@tanstack/react-query";
import {ISortingOption} from "../../../components/UI/SearchAndSort/SearchAndSort";
import {applicantsSortingOptions} from "../../../constants/ui/sortingOptions";
import {IProgram} from "../../../constants/types/IProgram";
import {useNavigate} from "react-router-dom";
import Pagination from "../../assessments/components/EmployeeTablePagination/Pagination";
import DocumentRequestButton from '../AdminDocumentRequest/DocumentRequestButton/DocumentRequestButton';
import {useQueryAdmins} from "../../../api/admin/useQueryAdmins";
import {IUser, UserContext} from "../../../hooks/Context/UserContext";
import {PermissionType} from "../../../constants/types/PermissionType";
import FilterIcon from '../../../assets/icons/UI/icons/filter.svg';
import {DropdownOption} from "../../../components/UI/SearchAndSort/DropdownWithIcon/Sorting";

const ApplicantsList = () => {
  const [user] = useContext(UserContext);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [currPage, setCurrPage] = useState<number>(1);
  const [searchParam, setSearchParam] = useState<string>('');
  const [sortingValue, setSortingValue] = useState<ISortingOption>(applicantsSortingOptions[3]);
  const [filterValue, setFilterValue] = useState<ISortingOption[]>([]);
  const [hideReviewComplete, setHideReviewComplete] = useState<boolean>(false);
  const [assignedTo, setAssignedTo] = useState<IUser>({});

  const {
    data: applicants,
    isLoading: applicantsIsLoading
  } = useQueryApplicants({
    assigned_to: assignedTo ? assignedTo.id : undefined,
    hide_completed: hideReviewComplete,
    ordering: sortingValue.value,
    search_term: searchParam,
    current_page: currPage,
    program_ids: (filterValue && filterValue.length > 0)
      ? [filterValue.map((item) => item.value).join(',')]
      : []
  });

  const {
    data: programs,
    isLoading: programsIsLoading,
  } = useQueryPrograms({
    name_only: true
  });

  const {
    data: adminsList,
    refetch: refetchAdminList
  } = useQueryAdmins();

  const getApplicantStatus = (status: string) => {
    switch (status) {
      case 'Not started':
        return ChicletTypes.Not_started;
      case 'Review in progress':
        return ChicletTypes.In_progress;
      case 'Review completed':
        return ChicletTypes.Completed;
      default:
        return ChicletTypes.Not_started
    }
  };

  useEffect(() => {
    if (searchParam.length > 2 || searchParam.length === 0) {
      setCurrPage(1);
      queryClient.invalidateQueries({queryKey: ['applicants']});
    }
  }, [queryClient, searchParam])

  useEffect(() => {
    setCurrPage(1);
    queryClient.invalidateQueries({queryKey: ['applicants']});
  }, [queryClient, sortingValue, filterValue, assignedTo, hideReviewComplete])

  useEffect(() => {
    queryClient.invalidateQueries({queryKey: ['applicants']});
    window.scroll(0, 0);
  }, [currPage])

  useEffect(() => {
    if (user.permission_groups?.includes(PermissionType.cpl_super_admin)) {
      refetchAdminList();
    }
  }, [user, refetchAdminList])

  if (programsIsLoading || applicantsIsLoading) {
    return <Loading loading/>
  }

  return <div className={'applicants'}>
    <div className={'applicants-title'}>
      Applicants
    </div>
    <div className={'applicants-search'}>
      <Search
        value={searchParam}
        onChange={(e) => setSearchParam(e.target.value)}
        placeholder={'Search Applicants'}
      />
    </div>
    <ComplexSearch
      filter={{
        name: 'Programs',
        value: filterValue,
        options: programs?.length > 0
          ? programs.map((item: IProgram) => {
            return {
              label: item.name,
              value: item.id
            }
          })
          : [],
        onChange: setFilterValue
      }}
      sorting={{
        options: applicantsSortingOptions,
        value: sortingValue,
        onChange: (newSorting) => setSortingValue(newSorting)
      }}
      toggle={!user.permission_groups?.includes(PermissionType.cpl_super_admin)
        ? [
          {
            active: hideReviewComplete,
            onToggle: () => setHideReviewComplete(!hideReviewComplete),
            label: 'Hide review completed'
          },
          {
            active: assignedTo.id === user.id,
            onToggle: () => setAssignedTo(assignedTo.id === user.id ? {} : user),
            label: 'Assigned to me'
          }
        ] : [
          {
            active: hideReviewComplete,
            onToggle: () => setHideReviewComplete(!hideReviewComplete),
            label: 'Hide review completed'
          }
        ]}
      dropdown={user.permission_groups?.includes(PermissionType.cpl_super_admin)
        ? {
          options: adminsList
            ? [
              {
                label: 'Assigned to me',
                value: {first_name: 'Assigned to me', last_name: '', id: user.id}
              },
              ...adminsList.map((item: IUser) => {
                return {
                  label: item?.first_name + ' ' + item?.last_name,
                  value: item
                }
              }),
              {
                label: 'No assignee',
                value: {first_name: 'No assignee', last_name: '', id: 'none'}
              }
            ]
            : [],
          value: {
            label: assignedTo.first_name
              ? (assignedTo.first_name + ' ' + assignedTo.last_name)
              : 'Assignee',
            value: assignedTo
          },
          onChange: (newActive: DropdownOption) => setAssignedTo(newActive.value),
          icon: FilterIcon
        }
        : undefined
      }
    />
    <table className={'applicants-table'}>
      <tr>
        <th>Applicant</th>
        <th>Application date</th>
        <th>Review status</th>
        <th>Assigned to</th>
        <th>Request documents</th>
      </tr>
      {applicants?.data && applicants.data.map((item: any, index: number) => (
        <tr key={index} onClick={() => navigate('/cpl-admin/' + item.id)}>
          <td>
            <UserProfile
              name={{
                first_name: item.first_name,
                last_name: item.last_name
              }}
              size={"small"}
              showText
            />
          </td>
          <td>
            <div className={'applicants-table-created-at'}>
              {item.created_at}
              <br/>
              ({item.created_ago_str.toLowerCase()})
            </div>
          </td>
          <td>
            <ProgressChiclet
              type={getApplicantStatus(item.status)}
              text={item.status}
            />
          </td>
          <td>{item.assigned_to}</td>
          <td>
            <DocumentRequestButton
              applicantId={item.id}
              disabled={!item.can_request_documents}
              documentStats={item.document_request_stats}
            />
          </td>
        </tr>
      ))}
    </table>

    {
      Number(applicants.total_pages) > 1
        ? <Pagination
          total={Number(applicants.total_pages)}
          currentPage={currPage}
          onPageChange={setCurrPage}
        />
        : null
    }

  </div>
}

export default ApplicantsList;