import Modal from 'react-modal';
import {XCircle} from "@phosphor-icons/react";
import ButtonKMQ from "../../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../../constants/ui/types/ButtonTypes";
import './instruction-modal.scss';
import {AssessmentIcon} from "../AssessmentCards/components/CardIcons/AssessmentCardIcons";

interface InstructionModalProps {
  open: boolean,
  onClose: () => void,
  title?: string,
  description?: string[],
  icons?: any,
  closeText?: string
}

const InstructionModal = (props: InstructionModalProps) => {
  const {
    open,
    onClose,
    title,
    description,
    icons,
    closeText = 'Close and get back to the assessment'
  } = props;

  return <Modal
    isOpen={open}
    onRequestClose={onClose}
    portalClassName={'instruction-modal'}
    contentLabel="Instruction Modal"
  >
    <div className={'instruction-modal-body'}>
      <span className={'instruction-modal-body-close'} onClick={onClose}>
        <XCircle size={28} color="#212121"/>
      </span>

      {title
        ? <div className={'instruction-modal-body-title'}>
          {title}
        </div>
        : null
      }

      <div className={'instruction-modal-body-icons'}>
        {
          icons && icons.map((item: AssessmentIcon, index: number) =>
              <span className={'assessment-card-icons-container'} key={index}>
          <span className={'assessment-card-icons-container-icon'}>
            {item.icon}
          </span>
          <span className={'assessment-card-icons-container-text'}>
            {item.description}
          </span>
        </span>
          )
        }
      </div>

      <ul className={'instruction-modal-body-description'}>
      {
        description && description.map((item: string, index: number) =>
          <li key={index}>
            {item}
          </li>
        )
      }
      </ul>

      <div className={'instruction-modal-body-footer'}>
        <ButtonKMQ onClick={onClose} type={ButtonTypes.Primary}>
          {closeText}
        </ButtonKMQ>
      </div>
    </div>
  </Modal>
}

export default InstructionModal;