import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userInfoUrl } from "../../constants/api/endpoints";
import { useAxios } from "../../hooks/axios/useAxios";
import { toast } from "react-toastify";

interface UpdatePortfolioParams {
  portfolio_id: string;
  notes: string;
}

export const useMutateUserInfo = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  return useMutation({
    mutationFn: async ({ portfolio_id, notes }: UpdatePortfolioParams) => {
      const response = await axios.patch(userInfoUrl, {
        portfolio_id,
        notes
      });
      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["portfolio"] });
    },
    onError: (error) => {
      // @ts-ignore
			toast.error(error);
    }
  });
};