import './assessment-footer.scss';
import ProgressBar from "../../../../components/UI/ProgressBar/ProgressBar";
import ButtonKMQ from "../../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../../constants/ui/types/ButtonTypes";

interface FooterProps {
  progress: number,
  continueText: string,
  onContinue: () => void,
  onClose?: () => void,
  continueDisabled?: boolean,
  showCloseButton?: boolean,
  submitButton?: boolean
}

const AssessmentFooter = (props: FooterProps) => {
  const {
    progress,
    continueText,
    onContinue,
    onClose,
    continueDisabled,
    showCloseButton,
    submitButton
  } = props;

  return <div className={'assessment-footer'}>
    <ProgressBar progress={progress}/>
    <div className={'assessment-footer-buttons'}>
      {
        (showCloseButton && onClose)
          ? <div className={'assessment-footer-buttons-close'}>
            <ButtonKMQ onClick={onClose} type={ButtonTypes.Secondary}>
              Save and exit
            </ButtonKMQ>
          </div>
          : null
      }
      <div className={'assessment-footer-buttons-continue'}>
        <ButtonKMQ onClick={onContinue} disabled={continueDisabled}
                   type={submitButton ? ButtonTypes.Tertiary : ButtonTypes.Primary}>
          {continueText}
        </ButtonKMQ>
      </div>
    </div>
  </div>
}

export default AssessmentFooter;