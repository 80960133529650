import {AssessmentCardTypes} from "../../../../../../constants/ui/types/AssessmentCardTypes";
import {UserRoles} from "../../../../../../constants/user/roles";
import {AssessmentCardProps} from "../../AssessmentCard";
import ButtonKMQ from "../../../../../../components/UI/Button/ButtonKMQ";

import './card-buttons.scss';
import {ButtonTypes} from "../../../../../../constants/ui/types/ButtonTypes";

const AssessmentCardButtons = (props: AssessmentCardProps) => {
  const {
    type = AssessmentCardTypes.Regular,
    userRole,
    onViewDetails,
    onClick,
    onTeamAssign,
  } = props;

  const footerButtons = {
    [AssessmentCardTypes.Regular]:
      (_: UserRoles) => [{text: 'Assign', action: onClick}],
    [AssessmentCardTypes.Completed]:
      (_: UserRoles) => [{text: 'View results', action: onClick}],
    [AssessmentCardTypes.In_progress]:
      (role: UserRoles) => role === UserRoles.WORKER
        ? [{text: 'Continue assessment', action: onClick}]
        : [],
    [AssessmentCardTypes.Recommended]:
      (_: UserRoles) => [{text: 'Assign', action: onClick}],
    [AssessmentCardTypes.Overdue]:
      (role: UserRoles) => role === UserRoles.WORKER
        ? [{text: 'Start assessment', action: onClick}]
        : [],
    [AssessmentCardTypes.Not_started]:
      (role: UserRoles) => role === UserRoles.WORKER
        ? [{text: 'Start assessment', action: onClick}]
        : [],
    [AssessmentCardTypes.HR_info]:
      (_: UserRoles) => [
        {text: 'Assign to teams', action: onTeamAssign ? onTeamAssign : onClick},
        {text: 'Assign to employees', action: onClick}
      ],
  }

  return <div className={'assessment-card-footer'}>
    {
      footerButtons[type](userRole).map(
        (item: { text: string, action: () => void }, index: number) =>
          <ButtonKMQ onClick={item.action} key={index}>
            {item.text}
          </ButtonKMQ>
      )
    }

    <ButtonKMQ onClick={onViewDetails} type={ButtonTypes.Secondary}>
      View details
    </ButtonKMQ>
  </div>
}

export default AssessmentCardButtons;