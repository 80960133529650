import {useMutation} from "@tanstack/react-query";
import {adminsListUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useCreateAdmin = () => {
  const axios = useAxios();

  async function postAssign(data: {first_name?: string, last_name?: string, email?: string}) {
    return axios.post(adminsListUrl, data)
  }

  return useMutation({
    mutationKey: ['assign-admin'],
    mutationFn: postAssign,
    retry: 2
  });
}