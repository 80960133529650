import {useQuery} from "@tanstack/react-query";
import { assessmentQuestionDataGETURL } from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";


export const useQueryQuestionData = ({assessment_id}: {assessment_id?: string}) => {
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(assessmentQuestionDataGETURL + `${assessment_id}/`)
        .then(response => response?.data ?? response);
  }

  return useQuery({queryKey: ['assessment','questionData'], queryFn: handleRequest})

}