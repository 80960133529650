import './evidence-component-table.scss';
import ApproveEvidence from '../../../../assets/icons/evidences/thumbs up.svg';
import ApproveEvidenceActive from '../../../../assets/icons/evidences/thumbs_up_active.svg';
import RejectEvidence from '../../../../assets/icons/evidences/thumbs down.svg';
import RejectEvidenceActive from '../../../../assets/icons/evidences/thumbs_down_active.svg';
import {useMutateEvidenceFeedback} from "../../../../api/admin/useMutateEvidenceFeedback";

export interface IEvidence {
  matching_score: number,
  portfolio_section: string[]
  approved: boolean,
  resume_category: string
  resume_detail: string
  resume_sentence: string,
  evidence_id: string
}

interface EvidenceTableProps {
  evidences: IEvidence[],
  stickyHeight?: number,
  canSendFeedback?: boolean
}

const EvidenceTable = (props: EvidenceTableProps) => {
  const {
    evidences,
    stickyHeight,
    canSendFeedback
  } = props;

  const {
    mutate: postEvidenceFeedback,
  } = useMutateEvidenceFeedback();

  const handleEvidenceFeedback = (evidence_id: string, approved: boolean | null) => {
    if (canSendFeedback) {
      postEvidenceFeedback({evidence_id, approved});
    }
  }

  return <div className={'evidence-component-table-content'}>
    <tr>
      <th style={{top: stickyHeight}}>#</th>
      <th style={{top: stickyHeight}}>Score %</th>
      <th style={{top: stickyHeight}}>Resume category</th>
      <th style={{top: stickyHeight}}>Referred section</th>
      <th style={{top: stickyHeight}}>Evidence Source</th>
      <th style={{top: stickyHeight}}>Evidence Text</th>
    </tr>

    {
      evidences && evidences.map((item: IEvidence, index: number) =>
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{Math.round(item.matching_score * 100)}%</td>
          <td>{item.resume_category[0].toUpperCase() + item.resume_category.slice(1)}</td>
          <td>
            {
              item.portfolio_section.map((section: string, sectionIndex: number) =>
                <div key={sectionIndex}>
                  {section}
                </div>)
            }
          </td>
          <td>
            {item.resume_detail}
          </td>
          <td>
            {item.resume_sentence}
            <span className={`evidence-component-table-actions ${canSendFeedback ? '' : 'evidence-component-table-actions-disabled'}`}>
                <img
                  src={item.approved ? ApproveEvidenceActive : ApproveEvidence}
                  alt={'approve-evidence'}
                  className={'evidence-component-table-actions-approve'}
                  onClick={() => handleEvidenceFeedback(item.evidence_id, item.approved ? null : true)}
                />
                <img
                  // don't change to !item.approved because 'approved' field may be null
                  src={item.approved === false ? RejectEvidenceActive : RejectEvidence}
                  alt={'reject-evidence'}
                  className={'evidence-component-table-actions-reject'}
                  onClick={() => handleEvidenceFeedback(item.evidence_id, item.approved === false ? null : false)}
                />
              </span>
          </td>
        </tr>
      )
    }
  </div>
}

export default EvidenceTable;