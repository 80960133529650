import "./education.scss";
import InputKMQ from "../../../../UI/Input/InputKMQ";
import { ChangeEvent, useEffect, useState } from "react";
import SelectKMQ from "../../../../UI/Select/SelectKMQ";
import Checkbox from "../../../../UI/Checkbox/Checkbox";
import ResumeDates from "../ResumeDates/ResumeDates";
import { WarningCircle, Trash } from "@phosphor-icons/react";
import { ResumeEducation } from "../../../../../lib/resume-parser/redux/types";
import { countries } from "countries-list";
import ErrorKMQ from "../../../../UI/Error/ErrorKMQ";
import { boolean, object, string } from "yup";

const locationOptions = [
  { label: "Canada", value: "Canada" },
  { label: "United States", value: "United States" },
  ...Object.values(countries)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((item) => {
      return {
        label: item.name,
        value: item.name,
      };
    }),
];

const typeOptions = [
  { label: "Community College", value: "Community College" },
  { label: "University", value: "University" },
  { label: "Secondary School", value: "Secondary School" },
];

const certificationCollegeOptions = [
  { label: "Certificate", value: "Certificate" },
  { label: "Diploma", value: "Diploma" },
  { label: "Associate’s Degree", value: "Associate’s Degree" },
];

const certificationUniversityOptions = [
  { label: "Bachelor’s (General)", value: "Bachelor’s (General)" },
  { label: "Bachelor’s Honors", value: "Bachelor’s Honors" },
  { label: "Masters", value: "Masters" },
  { label: "Doctorate", value: "Doctorate" },
  { label: "Post-Doctorate", value: "Post-Doctorate" },
];

const certificationSchoolOptions = [
  { label: "Diploma", value: "Diploma" },
];

interface EducationProps {
  education: ResumeEducation;
  changeEducation: (education: ResumeEducation) => void;
  normalizationOptions: { title: string }[];
  updateNormalization: () => void;
  isError?: boolean;
}

const Education = (props: EducationProps) => {
  const {
    education = {},
    changeEducation,
    normalizationOptions,
    updateNormalization,
    isError
  } = props;

  const {
    normalized,
    start_date,
    end_date,
    institution_name,
    institution_type,
    currently_enrolled,
    location,
    degree_type,
    program,
  } = education;

  const [init, setInit] = useState<boolean>(false);

  const handleNormalization = () => {
    let educationSchema = object({
      start_date: string().optional().nullable(),
      end_date: string().optional().nullable(),
      institution_name: string().required(),
      institution_type: string().required(),
      location: string().optional(),
      currently_enrolled: boolean().nullable(),
      degree_type: string().optional(),
      program: string().optional(),
    });

    educationSchema
      .validate({
        start_date,
        end_date,
        institution_name,
        institution_type,
        location,
        currently_enrolled,
        program,
        degree_type,
      })
      .then(() => updateNormalization())
      .catch(() => {
        // we should wait for more data
      });
  };

  useEffect(() => {
    if (program && institution_name) {
      if (!init) {
        setInit(true);
      } else {
        const timeOutId = setTimeout(() => handleNormalization(), 2000);
        return () => clearTimeout(timeOutId);
      }
    }
  }, [
    program,
    start_date,
    end_date,
    institution_name,
    institution_type,
    location,
    currently_enrolled,
    degree_type,
  ]);

  return (
    <div className={"education"}>
      <div className="education-header">
        <h3>Education</h3>
      </div>

      <InputKMQ
        value={institution_name ?? ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          changeEducation({ institution_name: e.target.value })
        }
        type={"text"}
        placeholder={"Enter the institution name"}
        label={"Name of Institution"}
      />

      <SelectKMQ
        options={locationOptions}
        value={location ? { label: location, value: location } : null}
        onChange={(newLocation) =>
          changeEducation({ location: newLocation.value })
        }
        label={"Location"}
        placeholder={"Select your location"}
      />

      <SelectKMQ
        options={typeOptions}
        value={
          institution_type
            ? { label: institution_type, value: institution_type }
            : null
        }
        onChange={(newType) =>
          changeEducation({
            institution_type: newType.value,
            degree_type: "",
          })
        }
        label={"Type of institution"}
        placeholder={"Select your institution type"}
      />

      {institution_type ? (
        <SelectKMQ
          options={
            institution_type === "Community College"
              ? certificationCollegeOptions
              : institution_type === "University"
              ? certificationUniversityOptions
              : certificationSchoolOptions
          }
          value={
            degree_type
              ? { label: degree_type, value: degree_type }
              : null
          }
          onChange={(newCertification) =>
            changeEducation({ degree_type: newCertification.value })
          }
          label={"Type of degree"}
          placeholder={"Select your type of degree"}
        />
      ) : null}

      <InputKMQ
        value={program ?? ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          changeEducation({ program: e.target.value })
        }
        type={"text"}
        placeholder={"Enter the degree program"}
        label={"Degree program"}
      />

      {normalizationOptions.length > 0 ? (
        <div>
          <SelectKMQ
            options={normalizationOptions.map((item) => {
              return {
                label: item.title,
                value: item.title,
              };
            })}
            value={normalized ? { label: normalized, value: normalized } : null}
            onChange={(newCertification) =>
              changeEducation({ normalized: newCertification.value })
            }
            label={"Selected degree program"}
            placeholder={"Choose your degree program"}
            error={isError && !normalized}
          />
          {isError && !normalized ? (
            <div className="education-error">
              <ErrorKMQ error={"Some fields are missing or incomplete, please fill in all required information."} />
            </div>
          ) : null}
          <div className={"education-notification"}>
            <WarningCircle size={21} color={"#212121"} />
            Please select the degree program that closely matches the degree program title.
          </div>
        </div>
      ) : null}

      <Checkbox
        active={!currently_enrolled}
        handleClick={() =>
          changeEducation({ currently_enrolled: !currently_enrolled })
        }
        text={"I have graduated"}
      />

      <ResumeDates
        changeResume={changeEducation}
        start_date={start_date}
        end_date={end_date}
        isError={isError}
        hideFutureDates={!currently_enrolled}
      />
    </div>
  );
};

export default Education;
