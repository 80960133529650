import {useQueryPrograms} from "../admin/useQueryPrograms";
import {testing_institution_id} from "../../constants/api/institution_id";
import {Dispatch, useContext, useEffect, useState} from "react";
import {UserContext} from "../../hooks/Context/UserContext";

interface ProgramsParams {
  portfolio_id?: string,
  searchValue?: string,
  sortingValue?: string,
  filterValue?: string,
  pageState: [number, Dispatch<number>]
}

export const usePrograms = (params: ProgramsParams) => {
  const [user] = useContext(UserContext);

  const {
    searchValue,
    sortingValue,
    filterValue,
    portfolio_id,
    pageState: [currPage, setPage]
  } = params

  const {data, error, isLoading} = useQueryPrograms({
    portfolio_id: portfolio_id,
    institution_id: testing_institution_id,
    searchValue,
    filter: filterValue,
    sortingValue: sortingValue,
    current_page: currPage,
    userIsSoft: user.isSoft
  });

  const [programsData, setProgramsData] = useState<any>({});

  useEffect(() => {
    if (data) {
      setProgramsData(data);
    }
  }, [data])

  useEffect(() => {
    setPage(1);
  }, [searchValue, sortingValue, filterValue, setPage])

  useEffect(() => {
    window.scroll(0, 0);
  }, [currPage])

  return {
    data: programsData,
    error: error,
    isLoading: !programsData,
    isUpdating: isLoading
  };
}