import {useContext, useEffect, useState} from "react";
import {useRefreshToken} from "../../api/jwt/useRefreshToken";
import {jwtToJson} from "../../lib/jwt/jwtToJson";
import {useLocation} from "react-router-dom";
import {UserContext} from "../Context/UserContext";
import {TokenContext} from "../Context/TokenContext";
import {toast} from "react-toastify";

export const useToken = () => {
  const [user, setUser] = useContext(UserContext);
  const [token, setToken] = useContext(TokenContext);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const {mutate, isSuccess, data, isPending, error} = useRefreshToken({userIsSoft: user.isSoft});
  const location = useLocation();

  const handleRefresh = () => {
    if (token.refreshToken) {
      if (jwtToJson(token.refreshToken).exp * 1000 > Date.now()) {
        mutate({
          refreshToken: token.refreshToken
        });
      } else {
        localStorage.removeItem('refresh');
        localStorage.removeItem('portfolio_id');
        localStorage.removeItem('basic_info');
        if (token.accessToken) {
          toast.error('Your session is expired, please login again')
        }
        setToken({accessToken: '', refreshToken: ''});
        if (user.isSoft) {
          window.scroll(0, 0);
          window.location.reload();
        }
        setUser({});
        setIsLoading(false);
      }

    }
  }

  const saveToken = () => {
    localStorage.setItem('refresh', token.refreshToken);
  }

  useEffect(() => {
    setIsLoading(true);
    if (token.accessToken) {
      if (jwtToJson(token.accessToken).exp * 1000 < Date.now()) {
        if (token.refreshToken && jwtToJson(token.refreshToken).exp * 1000 > Date.now()) {
          mutate({
            refreshToken: token.refreshToken,
          });
        } else {
          setToken({accessToken: '', refreshToken: ''})
          localStorage.removeItem('refresh');
          localStorage.removeItem('portfolio_id');
          localStorage.removeItem('basic_info');
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } else {
      let refresh_code = localStorage.getItem('refresh');
      if (refresh_code) {
        if (jwtToJson(refresh_code).exp * 1000 > Date.now()) {
          if (!isPending && user.user_id) {
            mutate({
              refreshToken: refresh_code,
            });
          } else {
            let parsedToken = jwtToJson(refresh_code);
            setUser({
              ...user,
              user_id: parsedToken.user_id,
              isSoft: parsedToken.soft_user
            });
          }
        } else {
          localStorage.removeItem('refresh');
          localStorage.removeItem('portfolio_id');
          localStorage.removeItem('basic_info');
        }
      } else {
        setIsLoading(false);
      }
    }
  }, [location, user])

  useEffect(() => {
    if (data?.data && isSuccess) {
      let parsedToken = jwtToJson(data?.data.refresh);
      setUser({
        ...user,
        user_id: parsedToken.user_id,
        isSoft: parsedToken.soft_user
      });

      setToken({
        accessToken: data?.data.access,
        refreshToken: data?.data.refresh
      })
      // only save updated refresh token if the previous token was saved
      // use case: we don't need to save soft users tokens before they get recommendations
      if (localStorage.getItem('refresh')) {
        localStorage.setItem('refresh', data?.data.refresh)
      }
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (token.accessToken) {
      setIsLoading(false);
    }
  }, [token.accessToken])

  useEffect(() => {
    if (error) {
      localStorage.removeItem('refresh');
      localStorage.removeItem('portfolio_id');
      localStorage.removeItem('basic_info');
      setToken({accessToken: '', refreshToken: ''});
      window.scroll(0, 0);
      window.location.reload();
    }
  }, [error])

  return {
    token: `${token.accessToken}`,
    triggerRefresh: handleRefresh,
    isLoading: isPending || isLoading,
    setTokens: setToken,
    saveToken: saveToken
  };
}