import './result-card-footer.scss';
import ButtonKMQ from "../../../../../../components/UI/Button/ButtonKMQ";
import {ButtonTypes} from "../../../../../../constants/ui/types/ButtonTypes";

interface ResultCardFooterProps {
  onViewResults: () => void,
  onViewDetails: () => void,
  disabled?: boolean,
}

const ResultCardFooter = (props: ResultCardFooterProps) => {
  const {
    onViewResults,
    onViewDetails,
    disabled
  } = props;

  return <div className={'result-card-footer'}>
    <ButtonKMQ onClick={onViewResults} disabled={disabled}>
      View results
    </ButtonKMQ>
    <ButtonKMQ onClick={onViewDetails} type={ButtonTypes.Secondary}>
      View details
    </ButtonKMQ>
  </div>
}

export default ResultCardFooter;