import {WorkerMenuOptions} from "../../../constants/menuOptions/WorkerMenuOptions";
import MenuLayout from "../../Menu/MenuLayout";
import {useContext} from "react";
import {UserContext} from "../../../hooks/Context/UserContext";
import {PermissionType} from "../../../constants/types/PermissionType";
import {AssessmentsAdminMenuOptions} from "../../../constants/menuOptions/AssessmentsAdminMenuOptions";

const WorkerLayout = () => {
    const [user] = useContext(UserContext);

  if (user.permission_groups?.includes(PermissionType.assessments_admin)) {
    return <MenuLayout menuOptions={AssessmentsAdminMenuOptions}/>;
  }

  return <MenuLayout menuOptions={WorkerMenuOptions}/>;
}

export default WorkerLayout;
