/**
 * `parseErrorResponse` and `scrollToError` Utility Functions used in ParseResume component
 * 
 * `parseErrorResponse`: Parses the server error response string into a structured object, 
 * allowing access to specific validation errors for `jobs` and `educations`.
 * 
 * `scrollToError`: Scrolls to the first element with a specified error class inside a given container,
 * helping users focus on the first validation error in the UI for easier correction.
 */

export interface ErrorDetail {
    string: string;
    code: string;
  }
  
export interface ParsedErrorResponse {
    profile: {
        jobs: Array<{ normalization_choice?: ErrorDetail[] }>;
        educations: Array<{ normalization_choice?: ErrorDetail[] }>;
    };
}
  
  export const parseErrorResponse = (error: string): ParsedErrorResponse | null => {
    try {
      //BE is sending response like this which is not json so we convert it
      const formattedError = error
        .replace(/'/g, '"')
        .replace(/ErrorDetail\((.*?)\)/g, '{"string": "This field may not be blank.", "code": "blank"}');
      return JSON.parse(formattedError);
    } catch (err) {
      console.error("Failed to parse error response", err);
      return null;
    }
  };

export const scrollToError = (containerSelector: string, errorClassSelector: string) => {
    const container = document.querySelector(containerSelector);
    if (container) {
      const errorElement = container.querySelector(errorClassSelector) as HTMLElement | null;
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
};