import './login.scss';
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import Logo from '../../../assets/logo/LogoWhite.svg';
import LoginHeader from "../../Headers/LoginHeader/LoginHeader";

const LoginWrap = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return <div>
    <LoginHeader/>
    <Outlet/>
  </div>

  // Old login designs. Leaving them here for some time just in case we need them

  return <div className={'login'}>
    {
      location.pathname === '/'
        ? <LoginHeader/>
        : null
    }

    <div className={'login-left'}>
      {
        location.pathname !== '/'
          ? <img
            src={Logo}
            alt={'kmq-logo'}
            className={'login-left-logo'}
            onClick={() => navigate('/')}
          />
          : null
      }
      <div className={'login-left-text'}>
        Thank you for joining our demo. We're excited to showcase how our platform empowers you. Let's get started!
      </div>
    </div>

    <div className={'login-right'}>
      <Outlet/>
    </div>
  </div>
}

export default LoginWrap;