import './marketplace.scss';
import MarketplaceHeader from "./MarketplaceHeader";
import MarketplaceBody from "./MarketplaceBody";
import React, {useContext} from "react";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import Loading from "../../../components/UI/Loading/Loading";
import UserProgramsEmpty from "../user-programs/UserProgramsEmpty";
import {UserContext} from "../../../hooks/Context/UserContext";

const UserMarketplace = () => {
  const [user] = useContext(UserContext);
  const [portfolio] = useContext(PortfolioContext);

  if (!portfolio.status && !user.isSoft) {
    return <Loading loading/>
  }

  if ((!portfolio.id || !portfolio.portfolio_email) && !user.isSoft) {
    return <UserProgramsEmpty/>
  }

  return <div className={'marketplace'}>
    <MarketplaceHeader/>
    <MarketplaceBody/>
  </div>
}

export default UserMarketplace;