import { useFileDownload, BaseDownloadParams } from './useFileDownload';
import { portfolioResumeEndpointUrl } from '../../constants/api/endpoints';

export interface ResumeDownloadParams extends BaseDownloadParams {
    portfolio_id: string;
    portfolio_name?: string;
}

export const useResumeDownload = () => {
    const { downloadFile } = useFileDownload();

    const downloadResume = async (params: ResumeDownloadParams) => {
        const filename = params.portfolio_name ? `${params.portfolio_name}_Resume.pdf` : undefined;
        return downloadFile(portfolioResumeEndpointUrl, { ...params, filename });
    };

    return { downloadResume };
};