import './sorting.scss';
import OpenIcon from '../../../../assets/icons/UI/select/arrow-down.svg';
import CloseIcon from '../../../../assets/icons/UI/select/arrow-up.svg';
import {useState} from "react";

export interface DropdownOption {
  label: string,
  value: any
}

interface DropdownOptions {
  options: DropdownOption[],
  active: DropdownOption,
  onChange: (newActive: DropdownOption) => void,
  icon?: any
}

const Dropdown = (props: DropdownOptions) => {
  const {
    options,
    active,
    onChange,
    icon
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);

  const handleChangeOption = (newOption: DropdownOption) => {
    setMenuIsOpen(false);
    onChange(newOption);
  }

  return <div className={'sorting dropdown'}>
    <div className={'sorting-active'}>
      {icon
        ? <img
          src={icon}
          alt={'sorting'}
          className={'sorting-active-icon'}
        />
        : null
      }
      <span className={'sorting-active-text'}>
        {active.label ? active.label : 'Assignee'}
      </span>
      <img
        src={menuIsOpen ? CloseIcon : OpenIcon}
        alt={menuIsOpen ? 'close-menu' : 'open-menu'}
        className={'sorting-active-open-btn'}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
      />
    </div>

    {
      menuIsOpen
        ? <div className={'sorting-menu'}>
          {options.map((item: DropdownOption, index: number) =>
            <div
              className={'sorting-menu-option'}
              key={index}
              onClick={() => handleChangeOption(item)}
            >
              <div className={'sorting-menu-option-text'}>
                {item.label}
              </div>
            </div>
          )}
        </div>
        : null
    }

  </div>
}

export default Dropdown;