import './terms-and-policy.scss';
import {useGetTermsOfUse} from "../../api/general/useGetTermsOfUse";
import Modal from "react-modal";
import {useEffect, useState} from "react";
import ButtonKMQ from "../UI/Button/ButtonKMQ";
import Loading from "../UI/Loading/Loading";

interface TermsOfUseProps {
  open: boolean,
  onClose: () => void,
}

const TermsOfUseModal = (props: TermsOfUseProps) => {
  const {
    open,
    onClose
  } = props;

  const [termsModal, setTermsModal] = useState<boolean>(false);
  const [privacyModal, setPrivacyModal] = useState<boolean>(false);

  const {data, isLoading} = useGetTermsOfUse();

  const handleNextModal = () => {
    setTermsModal(false);
    setPrivacyModal(true);
  }

  const handleCloseModal = () => {
    setPrivacyModal(false);
    onClose();
  }

  useEffect(() => {
    if (data && open) {
      setTermsModal(true);
    }
  }, [data, open])

  return <div className={'terms-and-policy'}>
    <Loading loading={isLoading}/>
    <Modal isOpen={termsModal}>
      {
        data?.terms
          ? <div dangerouslySetInnerHTML={{__html: data.terms}}/>
          : null
      }
      <div className={'terms-and-policy-footer'}>
        <ButtonKMQ onClick={handleNextModal}>
          Agree
        </ButtonKMQ>
      </div>
    </Modal>

    <Modal isOpen={privacyModal}>
      {
        data?.privacy_policy
          ? <div dangerouslySetInnerHTML={{__html: data.privacy_policy}}/>
          : null
      }
      <div className={'terms-and-policy-footer'}>
        <ButtonKMQ onClick={handleCloseModal}>
          Agree
        </ButtonKMQ>
      </div>
    </Modal>
  </div>
}

export default TermsOfUseModal;