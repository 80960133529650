export const sanitizeHTML = (htmlString: string): string => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
  
    const scripts = doc.querySelectorAll('script');
    scripts.forEach(script => script.remove());
  
    const elements = doc.body.querySelectorAll('*');
    elements.forEach(el => {
      for (let attr of Array.from(el.attributes)) {
        if (!['class', 'style', 'href'].includes(attr.name)) {
          el.removeAttribute(attr.name);
        }
      }
  
      if (!['u', 'b', 'i', 'strong', 'em', 'a', 'br'].includes(el.tagName.toLowerCase())) {
        const textNode = document.createTextNode(el.outerHTML);
        el.replaceWith(textNode);
      }
    });
  
    return doc.body.innerHTML;
  };
  
  // Example usage in a React component
//   const AssessmentQuestion = ({ questionText }: { questionText: string }) => {
//     const sanitizedHTML = sanitizeHTML(questionText);
  
//     return (
//       <div 
//         className="assessment-question-question"
//         dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
//       />
//     );
//   };
  