import React, { useContext, useEffect, useMemo, useState } from "react";
import "./evidence-submission.scss";
import EvidenceDropzone from "../../../components/EvidenceDropzone/EvidenceDropzone";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import { useDocumentRequests } from "../../../api/cpl-applicant/useQueryDocumentRequests";
import { useSubmitDocument } from "../../../api/cpl-applicant/useMutateSubmitDocument";
import { PortfolioContext } from "../../../hooks/Context/PortfolioContext";
import {useNavigate} from "react-router-dom";
import NavigationTabs, {NavigationTab} from "../../../components/UI/NavigationTabs/NavigationTabs";
import {forbiddenFileTypes} from "../../../constants/strings/forbiddenFileTypes";
import {toast} from "react-toastify";
import Loading from "../../../components/UI/Loading/Loading";

interface DocumentRequest {
  id: string;
  title: string;
  notes: string;
  status: string;
  created_at: string;
}

const EvidenceSubmission: React.FC = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<string>("");
  const [submittedTabs, setSubmittedTabs] = useState<string[]>([]);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState<Record<string, File>>({});
  const [portfolio] = useContext(PortfolioContext);

  const { data: allDocumentRequests = [], isPending } = useDocumentRequests({
    portfolio_id: portfolio?.id ?? ''
  });

  const tabs = useMemo<NavigationTab[]>(() => {
    if (allDocumentRequests) {
      return allDocumentRequests.map((item) => {
        return {
          name: item.title,
          onClick: () => setActiveTab(item.id),
          active: item.id === activeTab
        }
      })
    }
    return [];
  }, [allDocumentRequests, activeTab])

  // filter out withdrawn requests
  const documentRequests = allDocumentRequests.filter(
    doc => doc.status !== "Request withdrawn"
  );

  let nextDoc = documentRequests.find(item => item.id !== activeTab && item.status === 'Request sent');

  useEffect(() => {
    if (documentRequests.length > 0 && !activeTab && nextDoc) {
      setActiveTab(nextDoc.id);
    }
  }, [documentRequests]);

  const {mutateAsync: submitDocument, isPending: submitDocumentPending} = useSubmitDocument();

  if (!portfolio?.id) {
    return (
      <div className="evidence-submission">
        <div className="evidence-submission-message">
          Please finish your application to view evidence requests
        </div>
      </div>
    );
  }

  const handleFileUpload = (documentId: string, file: File) => {
    if (forbiddenFileTypes.find((item: string) => file.name.includes(item))) {
      toast.error('Wrong file format.');
      return;
    }
    setUploadedFiles(prev => ({
      ...prev,
      [documentId]: file
    }));
  };

  const handleSubmit = async () => {
    if (!uploadedFiles[activeTab]) return;

    try {
      await submitDocument({
        document_request_id: activeTab,
        file: uploadedFiles[activeTab],
      });

      setSubmittedTabs(prev => [...prev, activeTab]);
      setShowSuccessModal(true);

      setUploadedFiles(prev => {
        const newFiles = { ...prev };
        delete newFiles[activeTab];
        return newFiles;
      });
    } catch (error) {
      console.error('Error submitting document:', error);
    }
  };

  const handleCloseModal = () => {
    setShowSuccessModal(false);
    if (nextDoc) {
      setActiveTab(nextDoc.id);
    } else {
      navigate('/cpl-applicant/my-programs');
      window.location.reload();
    }
  };

  if (documentRequests.length === 0 && !isPending) {
    return (
      <div className="evidence-submission">
        <div className="evidence-submission-message">
          No document requests found
        </div>
      </div>
    );
  }

  const activeDocument = documentRequests.find(doc => doc.id === activeTab);
  const canSubmit = uploadedFiles[activeTab] && activeDocument?.status !== "Request resolved";

  return (
    <div className="evidence-submission">
      <Loading loading={!activeTab || submitDocumentPending}/>
      <div className="evidence-submission-title">Evidence Submission</div>
      <div className="evidence-submission-content">
        <NavigationTabs
          tabs={tabs}
        />
        <div className="tab-content">
          {documentRequests.map((doc: DocumentRequest) =>
            activeTab === doc.id ? (
              <div key={doc.id}>
                <EvidenceDropzone
                  onFileUploaded={(file) => handleFileUpload(doc.id, file)}
                  title={doc.title}
                  file={uploadedFiles[doc.id]}
                  status={doc.status}
                  additionalNote={doc.notes}
                />
                {doc.status !== "Request resolved" && (
                  <div className="submit-section">
                    <ButtonKMQ
                      onClick={handleSubmit}
                      disabled={!canSubmit}
                    >
                      Submit
                    </ButtonKMQ>
                  </div>
                )}
              </div>
            ) : null
          )}
        </div>
      </div>
      <AlertModal
        open={showSuccessModal}
        onClose={handleCloseModal}
        text="Your document has been successfully uploaded."
        proceedText={nextDoc ? "Continue" : "Return to My programs"}
        onProceed={handleCloseModal}
      />
    </div>
  );
};

export default EvidenceSubmission;