import React from 'react';
import './pagination.scss';
import ArrowBack from '../../../../assets/icons/UI/icons/arrow-back.svg';
import ArrowForward from '../../../../assets/icons/UI/icons/arrow-forward.svg';

interface PaginationProps {
    total: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ total, currentPage, onPageChange }) => {

    const handlePageChange = (page: number) => {
        if (page >= 1 && page <= total) {
            onPageChange(page);
        }
    };

    const renderPageNumbers = () => {
        const pageNumbers = [];
        if (total <= 4) {
            for (let i = 1; i <= total; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        className={`pagination-button ${i === currentPage ? 'active' : ''}`}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </button>
                );
            }
        } else {
            pageNumbers.push(
                <button
                    key={1}
                    className={`pagination-button ${1 === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageChange(1)}
                >
                    1
                </button>
            );

            if (currentPage > 3) {
                pageNumbers.push(<span className={'start-ellipsis'} key="start-ellipsis">...</span>);
            }

            const startPage = Math.max(currentPage - 1, 2);
            const endPage = Math.min(currentPage + 1, total - 1);

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(
                    <button
                        key={i}
                        className={`pagination-button ${i === currentPage ? 'active' : ''}`}
                        onClick={() => handlePageChange(i)}
                    >
                        {i}
                    </button>
                );
            }

            if (currentPage < total - 2) {
                pageNumbers.push(<span className={'start-ellipsis'} key="start-ellipsis">...</span>);
            }

            pageNumbers.push(
                <button
                    key={total}
                    className={`pagination-button ${total === currentPage ? 'active' : ''}`}
                    onClick={() => handlePageChange(total)}
                >
                    {total}
                </button>
            );
        }
        return pageNumbers;
    };

    return (
        <div className="pagination">
          <img
            src={ArrowBack}
            alt={'previous-page'}
            className={`pagination-arrow ${currentPage === 1 ? 'disabled' : ''}`}
            onClick={currentPage === 1
              ? undefined
              : () => handlePageChange(currentPage - 1)
            }
          />
            {renderPageNumbers()}
            <img
              src={ArrowForward}
              alt={'next-page'}
              className={`pagination-arrow ${currentPage === total ? 'disabled' : ''}`}
              onClick={currentPage === total
                ? undefined
                : () => handlePageChange(currentPage + 1)
              }
            />
        </div>
    );
};

export default Pagination;
