import './resume-dates.scss';
import SelectKMQ from "../../../../UI/Select/SelectKMQ";
import moment from "moment/moment";
import { monthsOptions, yearsOptions } from "../../../../../constants/resume/dates";
import { useEffect, useMemo, useState } from "react";
import ErrorKMQ from '../../../../UI/Error/ErrorKMQ';

const defaultTempDate = {
  month: '',
  year: '',
}

interface ResumeDatesProps {
  changeResume: (newResume: any) => void,
  active?: boolean,
  start_date?: string,
  end_date?: string,
  isError?: boolean
  hideFutureDates?: boolean
}

const ResumeDates = (props: ResumeDatesProps) => {
  const {
    changeResume,
    active,
    start_date,
    end_date,
    isError,
    hideFutureDates
  } = props;

  const [tempDateStart, setTempDateStart] = useState<{ month: string, year: string }>(defaultTempDate);
  const [tempDateEnd, setTempDateEnd] = useState<{ month: string, year: string }>(defaultTempDate);

  const getMonth = (date?: string) => {
    if (date) {
      return {
        label: moment(date).format('MMMM'),
        value: moment(date).format('MMMM')
      }
    }
    return null;
  }

  const getYear = (date?: string) => {
    if (date) {
      return {
        label: moment(date).format('YYYY'),
        value: moment(date).format('YYYY')
      }
    }
    return null;
  }

  const startMonth = useMemo(() => {
    if (tempDateStart.month) {
      return {
        label: tempDateStart.month,
        value: tempDateStart.month
      }
    }
    return getMonth(start_date);
  }, [start_date, tempDateStart]);

  const startYear = useMemo(() => {
    if (tempDateStart.year) {
      return {
        label: tempDateStart.year,
        value: tempDateStart.year
      }
    }
    return getYear(start_date);
  }, [start_date, tempDateStart]);

  const endMonth = useMemo(() => {
    if (active) {
      return null;
    }
    if (tempDateEnd.month) {
      return {
        label: tempDateEnd.month,
        value: tempDateEnd.month
      }
    }
    return getMonth(end_date);
  }, [end_date, active, tempDateEnd]);

  const endYear = useMemo(() => {
    if (active) {
      return null;
    }
    if (tempDateEnd.year) {
      return {
        label: tempDateEnd.year,
        value: tempDateEnd.year
      }
    }
    return getYear(end_date);
  }, [end_date, active, tempDateEnd]);

  const changeStartMonth = (newValue: string) => {
    if (tempDateStart.year) {
      changeResume({
        start_date: moment(
          `${newValue}-${tempDateStart.year}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
      setTempDateStart(defaultTempDate);
    } else if (moment(start_date).format() === 'Invalid date') {
      setTempDateStart({
        month: newValue,
        year: ''
      })
    } else {
      changeResume({
        start_date: moment(
          `${newValue}-${moment(start_date).format('YYYY')}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
    }
  }

  const changeStartYear = (newValue: string) => {
    if (tempDateStart.month) {
      changeResume({
        start_date: moment(
          `${tempDateStart.month}-${newValue}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
      setTempDateStart(defaultTempDate);
    } else if (moment(start_date).format() === 'Invalid date') {
      setTempDateStart({
        month: '',
        year: newValue
      })
    } else {
      changeResume({
        start_date: moment(
          `${moment(start_date).format('MMMM')}-${newValue}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
    }
  }

  const changeEndMonth = (newValue: string) => {
    if (tempDateEnd.year) {
      changeResume({
        end_date: moment(
          `${newValue}-${tempDateEnd.year}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
      setTempDateEnd(defaultTempDate)
    } else if (moment(end_date).format() === 'Invalid date') {
      setTempDateEnd({
        month: newValue,
        year: ''
      })
    } else {
      changeResume({
        end_date: moment(
          `${newValue}-${moment(end_date).format('YYYY')}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
    }
  }

  const changeEndYear = (newValue: string) => {
    if (tempDateEnd.month) {
      changeResume({
        end_date: moment(
          `${tempDateEnd.month}-${newValue}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
      setTempDateEnd(defaultTempDate)
    } else if (moment(end_date).format() === 'Invalid date') {
      setTempDateEnd({
        month: '',
        year: newValue
      })
    } else {
      changeResume({
        end_date: moment(
          `${moment(end_date).format('MMMM')}-${newValue}`, 'MMMM-YYYY'
        ).format('YYYY-MM-DD')
      });
    }
  }

  useEffect(() => {
    if (moment(start_date).isValid() && moment(end_date).isValid()) {
      if (moment(start_date).isAfter(moment(end_date))) {
        changeResume({end_date: start_date});
      }
    }
  }, [start_date, end_date, changeResume])

  useEffect(() => {
    setTempDateStart(defaultTempDate);
    setTempDateEnd(defaultTempDate);
  }, [active])

  return (
    <div className={`resume-date ${active ? 'resume-date-present' : ''}`}>
      <SelectKMQ
        options={
          startYear?.value === moment().format('YYYY')
            ? monthsOptions.filter((item, index) => index < Number(moment().format('MM')))
            : monthsOptions
        }
        value={startMonth}
        onChange={(newMonth) => changeStartMonth(newMonth.value)}
        label={'Start date'}
        placeholder={'Month'}
        error={isError && !startMonth}
      />
      <SelectKMQ
        options={yearsOptions}
        value={startYear}
        onChange={(newYear) => changeStartYear(newYear.value)}
        placeholder={'Year'}
        error={isError && !startYear}
      />
      <span className={'resume-date-end'}>
        <SelectKMQ
          options={
            (hideFutureDates && endYear?.value === moment().format('YYYY'))
              ? monthsOptions.filter((item, index) => index < Number(moment().format('MM')))
              : monthsOptions
          }
          value={endMonth}
          onChange={(newMonth) => changeEndMonth(newMonth.value)}
          label={'End date'}
          placeholder={'Month'}
          disabled={active}
        />
        <SelectKMQ
          options={yearsOptions}
          value={endYear}
          onChange={(newYear) => changeEndYear(newYear.value)}
          placeholder={'Year'}
          disabled={active}
        />
        {
          (isError && (!startMonth || !startYear))
            ? <div className='resume-date-error'>
              <ErrorKMQ error={"Some fields are missing or incomplete, please fill in all required information."}/>
            </div>
            : null
        }
      </span>
    </div>
  );
}

export default ResumeDates;
