import {useMutation} from "@tanstack/react-query";
import {stripeSessionUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";
import {toast} from "react-toastify";

export const useMutateStripeSession = () => {
  const axios = useAxios();

  async function postData(data: { portfolio_id: string }) {
    return axios.post(stripeSessionUrl, data).then(response => response?.data)
  }

  return useMutation({
    mutationKey: ['stripe-session'],
    mutationFn: postData,
    retry: 1,
    onError: (error) => {
      // @ts-ignore
      if (error?.response?.data?.error) {
        // @ts-ignore
        toast.error(error?.response?.data?.error);
      }
    }
  });
}
