import './kmq-header.scss';
import LogoKMQ from '../../../assets/logo/KnowMeQ_logo.svg';
import {useNavigate} from "react-router-dom";

const KMQHeader = () => {
  const navigate = useNavigate();

  return (
    <div className="kmq-header">
      <img
        className={'kmq-header-logo'}
        src={LogoKMQ}
        alt="kmq-logo"
        onClick={() => navigate('/')}
      />
    </div>
  );
};

export default KMQHeader;
