import './forgot-password.scss';
import Back from "../../../components/UI/Back/Back";
import {useNavigate} from "react-router-dom";
import InputKMQ from "../../../components/UI/Input/InputKMQ";
import {useEffect, useState} from "react";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {object, string} from "yup";
import {useForgotPassword} from "../../../api/login/useForgotPassword";
import {toast} from "react-toastify";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [buttonIsDisabled, setButtonIsDisabled] = useState<boolean>(true);
  const {mutate, isSuccess} = useForgotPassword();

  let emailSchema = object({
    email: string().email().min(3).required(),
  });

  const handleForgotPassword = () => {
    mutate({email});
  }

  useEffect(() => {
    emailSchema.validate({email})
      .then(() => setButtonIsDisabled(false))
      .catch(() => setButtonIsDisabled(true))
  }, [email, emailSchema])

  useEffect(() => {
    if (isSuccess) {
      toast.success('Email sent! Check your inbox for instructions.');
    }
  }, [isSuccess])

  return <div className={'forgot-password'}>
    <Back
      text={'Back to Log in'}
      onClick={() => navigate('/login')}
    />

    <div className={'forgot-password-container'}>
      <div className={'forgot-password-title'}>
        Forgot password?
      </div>
      <div className={'forgot-password-text'}>
        No worries! Enter your email address below, and we'll send you instructions on how to reset your password.
      </div>
      <div className={'forgot-password-email'}>
        <InputKMQ
          label={'Email'}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type={'text'}
          placeholder={'Enter email'}
        />
      </div>
      <div className={'forgot-password-button'}>
        <ButtonKMQ
          onClick={handleForgotPassword}
          disabled={buttonIsDisabled}>
          Reset password
        </ButtonKMQ>
      </div>
    </div>
  </div>
}

export default ForgotPassword;