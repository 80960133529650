import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";
import { documentUploadEndpointUrl } from "../../constants/api/endpoints";
import { toast } from "react-toastify";

interface SubmitDocumentParams {
  document_request_id: string;
  file: File;
}

export const useSubmitDocument = () => {
  const axios = useAxios();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ document_request_id, file }: SubmitDocumentParams) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('document_request_id', document_request_id);

      const response = await axios.post(documentUploadEndpointUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    },
    retry: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["documentRequests"] });
      toast.success("Document uploaded successfully");
    },
    onError: (error: Error) => {
      toast.error("Failed to upload document. Please try again.");
      console.error("Error submitting document:", error);
    },
  });
};