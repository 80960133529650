import './decay-config.scss';
import {IDecayFunctions} from "../../app/config/Config";
import {ChangeEvent} from "react";
import InputKMQ from "../UI/Input/InputKMQ";
import Checkbox from "../UI/Checkbox/Checkbox";

interface DecayFunctionsConfigProps {
  data: IDecayFunctions,
  changeData: (newData: IDecayFunctions) => void,
  text: string
}

const DecayFunctionsConfig = (props: DecayFunctionsConfigProps) => {
  const {
    data,
    changeData,
    text
  } = props;

  return <div className={'decay-config'}>
    <div className={'config-subtitle'}>
      {text}
    </div>

    <Checkbox
      active={data.use_decay}
      handleClick={() => {
        console.log('clicked')
        changeData({
          ...data,
          use_decay: !data.use_decay
        })
      }}
      text={'Use Decay Functions'}
    />

    <InputKMQ
      value={String(data.max_recency_time)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => changeData({
        ...data,
        max_recency_time: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Maximum Job Recency Time'}
      label={'Maximum Job Recency Time'}
    />

    <InputKMQ
      value={String(data.min_recency_decay)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => changeData({
        ...data,
        min_recency_decay: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Minimum Recency Decay Value'}
      label={'Minimum Recency Decay Value'}
    />

    <InputKMQ
      value={String(data.max_duration_time)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => changeData({
        ...data,
        max_duration_time: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Maximum Duration Time'}
      label={'Maximum Duration Time'}
    />

    <InputKMQ
      value={String(data.max_log_x)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => changeData({
        ...data,
        max_log_x: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Maximum X Axis Value for Logarithmic Graph'}
      label={'Maximum X Axis Value for Logarithmic Graph'}
    />
  </div>
}

export default DecayFunctionsConfig;