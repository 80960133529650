import React from 'react'
import EmployeesTable from "./employees-table/EmployeesTable";

const AdminDashboard = () => {
  return (
    <>
        <div className={"worker-assessments-title"}>Employees</div>
        <EmployeesTable/>
    </>
  )
}

export default AdminDashboard