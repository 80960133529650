import './roi.scss';
import KMQHeader from "../../components/Headers/KMQHeader";
import InputKMQ from "../../components/UI/Input/InputKMQ";
import {useMemo, useState} from "react";
import Footer from "../../components/Footer";

const RoiPage = () => {
  const [subscriptionFee, setSubscriptionFee] = useState<number>(20000);
  const [studentsNumber, setStudentsNumber] = useState<number>(0);
  const [annualTuition, setAnnualTuition] = useState<number>(0);

  const returnAmount = useMemo<[number, number]>(() => {
    if (subscriptionFee && studentsNumber && annualTuition) {
      return [
        Math.round((annualTuition * studentsNumber) - subscriptionFee),
        Math.round((annualTuition * studentsNumber * 100) / subscriptionFee)
      ]
        ;
    }
    return [0, 0];
  }, [subscriptionFee, studentsNumber, annualTuition])

  const numberWithCommas = (value: number) => {
    if (value > 0) {
      return String(value).replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
    }
    return '';
  }

  const numberWithDollarAndCommas = (fee: number) => {
    return '$' + numberWithCommas(fee);
  }

  const handleInputChange = (inputValue: string, saveValue: (newNumber: number) => void) => {
    if (inputValue.length === 0) {
      saveValue(0);
    } else {
      let newValue = parseInt(inputValue.replaceAll('$', '').replaceAll(',', ''));
      if (newValue || newValue === 0) {
        saveValue(newValue);
      }
    }
  }

  return <div className={'roi'}>
    <KMQHeader/>
    <div className={'roi-body'}>
      <div className={'roi-body-title'}>
        Investing in your college’s Credit for Prior Learning:
      </div>
      <div className={'roi-body-subtitle'}>
        Calculating Return on Investment (ROI) for Archie CPL
      </div>
      <div className={'roi-body-text'}>
        Archie CPL is designed to help colleges with accelerated, evidence informed Credit for Prior Learning processes,
        reducing labor and converting more applicants to successful students faster.
      </div>
      <div className={'roi-body-text'}>
        Reduce administration time for college staff to make highly confident, evidence informed credit granting
        decisions processing CPL applications while providing an expedited, transparent process for applicants.
      </div>
      <div className={'roi-body-subtitle'}>
        How to calculate ROI:
      </div>
      <div className={'roi-body-text'}>
        Use the calculator to create different scenarios (current levels, future growth and use) to estimate a Return on
        Investment for your college for using Archie.
      </div>
      <div className={'roi-body-subtitle roi-body-formula'}>
        Total Tuition (Annual Tuition x Number of Students) / Archie CPL Subscription Fee
      </div>

      <div className={'roi-body-form'}>
        <div className={'roi-body-form-inputs'}>
          <div className={'roi-body-form-inputs-container'}>
            <InputKMQ
              value={numberWithDollarAndCommas(subscriptionFee)}
              onChange={(e) => handleInputChange(e.target.value, setSubscriptionFee)}
              type={'text'}
              placeholder={'Enter here'}
              label={'Archie CPL Subscription Fee::'}
            />
          </div>
          <div className={'roi-body-form-inputs-container'}>
            <InputKMQ
              value={numberWithCommas(studentsNumber)}
              onChange={(e) => handleInputChange(e.target.value, setStudentsNumber)}
              type={'text'}
              placeholder={'Enter here'}
              label={'Number of Students completing PLAR/CPL:'}
            />
          </div>
          <div className={'roi-body-form-inputs-container'}>
            <InputKMQ
              value={numberWithDollarAndCommas(annualTuition)}
              onChange={(e) => handleInputChange(e.target.value, setAnnualTuition)}
              type={'text'}
              placeholder={'Enter here'}
              label={'Annual Tuition:'}
            />
          </div>
        </div>

        <div className={'roi-body-form-result'}>
          <div className={'roi-body-form-result-container'}>
            <div className={'roi-body-form-result-container-text'}>
              Return on Investment:
            </div>
            <div className={'roi-body-form-result-container-number'}>
              {
                returnAmount[0] > 0
                  ? numberWithDollarAndCommas(returnAmount[0])
                  : '-'
              }
            </div>
          </div>
          <div className={'roi-body-form-result-container'}>
            <div className={'roi-body-form-result-container-text'}>
              Return on Investment %:
            </div>
            <div className={'roi-body-form-result-container-number'}>
              {
                returnAmount[0] > 0
                  ? numberWithCommas(returnAmount[1])
                  : '-'
              }
            </div>
          </div>
        </div>

      </div>
    </div>
    <Footer/>
  </div>
}

export default RoiPage;