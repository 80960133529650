import AssessmentHeader from "../AssessmentHeader/AssessmentHeader";
import AssessmentBack from "../AssessmentBack/AssessmentBack";
import AssessmentFooter from "../AssessmentFooter/AssessmentFooter";
import AlertModal from "../../../../components/UI/Modals/AlertModal/AlertModal";
import {useMemo, useState, useEffect} from "react";
import AssessmentQuestion from "../AssessmentQuestion/AssessmentQuestion";
import {useNavigate} from "react-router-dom";
import {AnswerFormat} from "../../assessment/[assessment_id]/AssessmentID";

interface AssessmentProps {
  assessmentData: {
    title?: string;
    questions?: any[];
  };
  totalSelfQuestions: number;
  assessmentIntro?: {
    title?: string;
    details?: any[];
    description?: string[];
  };
  currQuestion: number;
  answers: AnswerFormat[];
  onBack: () => void;
  onContinue: () => void;
  currAnswer: string | string[];
  setCurrAnswer: (answer: string | string[]) => void;
}

const AssessmentQuestionPage = (props: AssessmentProps) => {
  const {
    assessmentData,
    totalSelfQuestions,
    assessmentIntro,
    currQuestion,
    answers,
    currAnswer,
    setCurrAnswer,
    onBack,
    onContinue,
  } = props;

  const navigate = useNavigate();
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false);
  const [isLastQuestion, setIsLastQuestion] = useState<boolean>(false);

  useEffect(() => {
    setIsLastQuestion(currQuestion === assessmentData?.questions?.length);
  }, [currQuestion, assessmentData?.questions?.length]);

  const backText = useMemo<string>(() => {
    if (currQuestion === 1) {
      return "Back to instructions";
    }
    if (currQuestion === totalSelfQuestions) {
      return "Back to self-assessment";
    }
    return "Back to previous question";
  }, [currQuestion, totalSelfQuestions]);

  return (
    <div className={"assessment-page"}>
      <AssessmentHeader
        title={assessmentData.title}
        showInstructions
        instructions={assessmentIntro}
        text={'View instructions'}
      />
      <div className={"assessment-page-body"}>
        <AssessmentBack text={backText} onClick={onBack}/>
        {(assessmentData?.questions && assessmentData.questions.length > 0) ? (
          <>
            <AssessmentQuestion
              question={assessmentData.questions[currQuestion - 1]}
              currIndex={currQuestion}
              totalQuestions={assessmentData.questions.length}
              answers={answers}
              chosen={currAnswer}
              setChosen={(answer_id: string | string[]) => setCurrAnswer(answer_id)}
            />
            <AssessmentFooter
              progress={
                (answers.length / assessmentData.questions.length) * 100
              }
              continueText={
                isLastQuestion && answers.length >= assessmentData.questions.length - 1
                  ? "Submit answers"
                  : "Continue"
              }
              onContinue={onContinue}
              onClose={() => setShowCloseModal(true)}
              showCloseButton
              continueDisabled={!currAnswer}
              submitButton={isLastQuestion && answers.length >= assessmentData.questions.length - 1}
            />
          </>
        ) : null}
      </div>

      <AlertModal
        open={showCloseModal}
        onClose={() => setShowCloseModal(false)}
        text={
          "Are you sure you want to exit? Your progress will be saved for when you return."
        }
        proceedText={"Yes, save and exit"}
        closeText={"No, continue assessment"}
        onProceed={() => navigate('/assessments/assessment')}
      />
    </div>
  );
};

export default AssessmentQuestionPage;
