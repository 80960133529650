import './sign-up.scss';
import InputKMQ from "../../../components/UI/Input/InputKMQ";
import React, {useContext, useEffect, useState} from "react";
import {INewUser} from "../../../constants/types/INewUser";
import PasswordRequirements from "../../../components/login/PasswordRequirements/PasswordRequirements";
import Checkbox from "../../../components/UI/Checkbox/Checkbox";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {object, string, ValidationError} from "yup";
import {useCreateUser} from "../../../api/login/useCreateUser";
import {useNavigate} from "react-router-dom";
import Loading from "../../../components/UI/Loading/Loading";
import ErrorKMQ from "../../../components/UI/Error/ErrorKMQ";
import {useSoftToHardApplicant} from "../../../api/soft-user/useSoftToHardApplicant";
import {UserContext} from "../../../hooks/Context/UserContext";
import TermsOfUseModal from '../../../components/TermsOfUse';
import ArrowRight from '../../../assets/icons/UI/icons/ArrowRight.svg';
import Notification from "../../../components/UI/Notification/Notification";

const SignUp = () => {
  const navigate = useNavigate();

  const [user] = useContext(UserContext);
  const [newUser, setNewUser] = useState<INewUser>({});
  const [selectedForPayment, setSelectedForPayment] = useState<boolean>(false);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [passwordIsChecked, setPasswordIsChecked] = useState<boolean>(false);
  const [dataIsAccepted, setDataIsAccepted] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
  const [inputError, setInputError] = useState<boolean>(false);
  const {
    mutate: createNewUser,
    data: dataNewUser,
    isPending: isPendingNewUser,
    error: errorNewUser
  } = useCreateUser();
  const {
    mutate: createHardUser,
    data: dataHardUser,
    isPending: isPendingHardUser,
    error: errorHardUser
  } = useSoftToHardApplicant();

  const isPending = isPendingNewUser || isPendingHardUser;
  const data = dataNewUser ? dataNewUser : dataHardUser;
  const error = errorNewUser ? errorNewUser : errorHardUser;

  const signUpSchema = object({
    email: string().min(3).required(),
    password: string().min(3).required(),
    confirmation_password: string().min(3).required(),
    first_name: string().min(1).required(),
    last_name: string().min(1).required(),
  });

  const handleCreateUser = async () => {
    if (newUser.password && newUser.password !== newUser.confirmation_password) {
      setConfirmPasswordError(true);
    } else if (!user.isSoft && !showTerms) {
      setShowTerms(true);
    } else {
      try {
        await signUpSchema.validate(newUser);
        if (user.isSoft) {
          createHardUser(newUser)
        } else {
          createNewUser(newUser);
        }
        localStorage.removeItem('basic_info');
        // @ts-ignore
      } catch (e: ValidationError) {
        console.log('e', e.errors)
        setInputError(true);
      }
    }
  }

  useEffect(() => {
    if (confirmPasswordError && newUser.password && newUser.password === newUser.confirmation_password) {
      setConfirmPasswordError(false);
    }
  }, [confirmPasswordError, newUser])

  useEffect(() => {
    if (data && newUser.email) {
      localStorage.setItem('user-email', newUser.email);
      navigate(`/login/verification-code${selectedForPayment ? '?payment=true' : ''}`);
    }
  }, [data])

  useEffect(() => {
    const urlParams = new URLSearchParams(decodeURI(window.location.search));
    const paymentInfo = urlParams.get('payment');
    if (paymentInfo) {
      setSelectedForPayment(!!paymentInfo);
    }

    const savedData = localStorage.getItem('basic_info');
    if (savedData) {
      const parsedData = JSON.parse(savedData);
      setNewUser({
        ...newUser,
        email: parsedData?.email,
        first_name: parsedData?.first_name,
        last_name: parsedData?.last_name
      })
    }
  }, [])


  return <div className={'sign-up'}>
    <Loading loading={isPending}/>
    <div className={'sign-up-content'}>

      {
        selectedForPayment
          ? <div className={'sign-up-content-nav'}>
            <div>
            <span className={'sign-up-content-nav-prev'}>
              Submit for review
            </span>
              <img
                className={'sign-up-content-nav-icon'}
                src={ArrowRight}
                alt={'arrow-next'}
              />
              <span className={'sign-up-content-nav-prev'}>
              Sign up / Log in
            </span>
              <img
                className={'sign-up-content-nav-icon'}
                src={ArrowRight}
                alt={'arrow-next'}
              />
              <span className={'sign-up-content-nav-next'}>
              Payment
            </span>
            </div>
            <Notification>
              To continue to payment, please login or create an account.
            </Notification>
          </div>
          : null
      }

      <div className={'sign-up-title'}>
        Sign up
      </div>

      <InputKMQ
        value={newUser.first_name ?? ''}
        onChange={(e) => setNewUser({...newUser, first_name: e.target.value})}
        type={'text'}
        placeholder={'Enter first name'}
        label={'First name'}
        error={
          (inputError && !newUser.first_name)
            ? 'Please enter your first name'
            : ''
        }
      />

      <InputKMQ
        value={newUser.last_name ?? ''}
        onChange={(e) => setNewUser({...newUser, last_name: e.target.value})}
        type={'text'}
        placeholder={'Enter last name'}
        label={'Last name'}
        error={
          (inputError && !newUser.last_name)
            ? 'Please enter your last name'
            : ''
        }
      />

      <InputKMQ
        value={newUser.email ?? ''}
        onChange={(e) => setNewUser({...newUser, email: e.target.value})}
        type={'text'}
        placeholder={'Enter email'}
        label={'Email'}
        error={
          (inputError && !newUser.email)
            ? 'Please enter your email'
            // @ts-ignore
            : error?.response?.status === 409
              ? 'This email has already been registered'
              : ''
        }
      />

      <InputKMQ
        value={newUser.password ?? ''}
        onChange={(e) => setNewUser({...newUser, password: e.target.value})}
        type={'password'}
        placeholder={'Enter password'}
        label={'Create password'}
        passwordShowIcon
      />

      <InputKMQ
        value={newUser.confirmation_password ?? ''}
        onChange={(e) => setNewUser({...newUser, confirmation_password: e.target.value})}
        type={'password'}
        placeholder={'Enter password'}
        label={'Confirm password'}
        passwordShowIcon
        error={confirmPasswordError ? 'Passwords do not match' : ''}
      />

      <PasswordRequirements
        password={newUser.password}
        setPasswordCheck={setPasswordIsChecked}
      />

      <Checkbox
        active={dataIsAccepted}
        handleClick={() => setDataIsAccepted(!dataIsAccepted)}
        text={'I acknowledge that by proceeding with payment to KnowMeQ Incorporated for its Credit for Prior Learning tool, that my information (resume, work and education experience) will be shared with specified postsecondary institution and its staff members for credit processing'}
      />

      <div className={'sign-up-continue'}>
        <ButtonKMQ
          onClick={handleCreateUser}
          disabled={!dataIsAccepted || !passwordIsChecked}>
          Create account and continue
        </ButtonKMQ>
        {
          inputError
            ? <ErrorKMQ
              error={"Some fields are incomplete or data is invalid, please fill in all required information."}/>
            : null
        }
      </div>

      <div className={'sign-up-or'}>
        <div className={'sign-up-or-line'}/>
        <span className={'sign-up-or-text'}>
          Or
        </span>
      </div>

      <div className={'sign-up-login'}>
        {'Already have an account? '}
        <span className={'sign-up-login-link'} onClick={() => navigate('/login')}>
          Log in
        </span>
      </div>
    </div>

    <TermsOfUseModal
      open={showTerms}
      onClose={handleCreateUser}
    />
  </div>
}

export default SignUp;