import "./cpl-applicant-submitted-profile.scss";
import InputKMQ from "../../UI/Input/InputKMQ";
import SelectKMQ from "../../UI/Select/SelectKMQ";
import TextArea from "../../UI/TextArea/TextArea";
import Checkbox from "../../UI/Checkbox/Checkbox";
import CheckIcon from "../../../assets/icons/UI/icons/checkmark.svg";
import { IPortfolio } from "../../../hooks/Context/PortfolioContext";
import ResumeDates from "../CplApplicantProfile/components/ResumeDates/ResumeDates";

interface CplApplicantSubmittedProfileProps {
	portfolio: IPortfolio;
}

const CplApplicantSubmittedProfile = ({
	portfolio,
}: CplApplicantSubmittedProfileProps) => {
	const renderNormalizationOptions = (
		normalizationChoice: string,
		description: string
	) => {
		return (
			<div className="normalization-option">
				<div className="normalization-option-title">
					<img
						src={CheckIcon}
						alt={"check-icon"}
						className={"normalization-option-title-active"}
					/>
					{normalizationChoice}
				</div>
				{description && (
					<div className={"normalization-option-text"}>{description}</div>
				)}
			</div>
		);
	};

	const renderWorkExperience = () => {
		return portfolio.jobs?.map((job: any, index: number) => (
			<div key={index} className="work-experience">
				<h4>Work Experience {index + 1}</h4>
				<InputKMQ
					value={job.company ?? ""}
					label={"Company"}
					onChange={() => null}
					type=""
					placeholder=""
				/>
				<SelectKMQ
					options={[]}
					value={
						job.location ? { label: job.location, value: job.location } : null
					}
					label={"Location"}
					onChange={() => null}
				/>
				<Checkbox
					active={job.current_job ?? false}
					text={"I am currently working in this position"}
					handleClick={() => null}
				/>
				<ResumeDates
					start_date={job.start_date}
					end_date={job.end_date}
					hideFutureDates={!(job.current_job ?? false)}
					changeResume={() => null}
				/>
				<InputKMQ
					value={job.title ?? ""}
					label={"Job Title"}
					onChange={() => null}
					type=""
					placeholder=""
				/>
				<TextArea
					value={job.description ?? ""}
					label={"Job Description"}
					onChange={() => null}
					placeholder=""
				/>
				{job.normalization_choice &&
					renderNormalizationOptions(
						job.normalization_choice,
						job.normalization_choice_descriptions
					)}
			</div>
		));
	};

	const renderEducationHistory = () => {
		return portfolio.educations?.map((education: any, index: number) => (
			<div key={index} className="work-experience">
				<h4>Degree Program {index + 1}</h4>
				<InputKMQ
					value={education.institution_name ?? ""}
					label={"Name of Institution"}
					onChange={() => null}
					type=""
					placeholder=""
				/>
				<SelectKMQ
					options={[]}
					value={
						education.location
							? { label: education.location, value: education.location }
							: null
					}
					label={"Location"}
					onChange={() => null}
				/>
				<SelectKMQ
					options={[]}
					value={
						education.institution_type
							? {
									label: education.institution_type,
									value: education.institution_type,
							  }
							: null
					}
					label={"Type of institution"}
					onChange={() => null}
				/>
				{education.institution_type ? (
					<SelectKMQ
						options={[]}
						value={
							education.degree_type
								? { label: education.degree_type, value: education.degree_type }
								: null
						}
						label={"Type of degree"}
						onChange={() => null}
					/>
				) : null}
				<InputKMQ
					value={education.program ?? ""}
					label={"Degree program"}
					onChange={() => null}
					type=""
					placeholder=""
				/>
				<Checkbox
					active={!education.currently_enrolled}
					text={"I have graduated"}
					handleClick={() => null}
				/>
				<ResumeDates
					start_date={education.start_date}
					end_date={education.end_date}
					hideFutureDates={education.currently_enrolled ?? false}
					changeResume={() => null}
				/>
			</div>
		));
	};

	return (
		<div className="submitted-profile">
			<div className="edit-resume-review">
				<div className="edit-resume-work-title">Work History</div>
				{renderWorkExperience()}
				<div className="edit-resume-education-title">Education History</div>
				{renderEducationHistory()}
			</div>
		</div>
	);
};

export default CplApplicantSubmittedProfile;
