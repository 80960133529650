import {useQuery} from "@tanstack/react-query";
import {assessmentFinishLaterGetURL} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

interface FinishLaterProps {
  isInProgress: boolean | undefined,
  assessment_id?: string
}

export const useQueryFinishLater = (props: FinishLaterProps) => {
  const {
    isInProgress,
    assessment_id
  } = props;

  const axios = useAxios();

  const handleRequest = async () => {
    try {
      const response = await axios.get(assessmentFinishLaterGetURL + `${assessment_id}`);
      return response?.data ?? response;
    } catch (error: any) {
      if (error.response && error.response.status === 404) {
        return { error: "No in-progress response found for the given assessment ID" };
      }
      throw error;
    }

  };

  return useQuery({
    queryKey: ['assessment', 'finishLaterGet'],
    queryFn: handleRequest,
    enabled: isInProgress
  });
}