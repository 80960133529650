import {Text, View, StyleSheet, Image, Font} from '@react-pdf/renderer';

const styles = StyleSheet.create({
  table: {
    marginBottom: 24,
  },
  header: {
    padding: '8px 8px 4px 8px',
    margin: 0,
    width: "100%",
    position: 'relative',
    border: '1px solid #909090',

    title: {
      color: '#212122',
      fontFamily: 'Castoro',
      fontSize: 12,
    },
  },
  header_approved: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 12,
  },
  row: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    top: '-1px'
  },
  col1: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '9.3%',
    padding: '4px 8px',
    borderLeft: '1px solid #909090',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col2: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '30.95%',
    padding: '4px 8px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col3: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '13.37%',
    padding: '4px 8px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col4: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '14.20%',
    padding: '4px 8px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col5: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '16.69%',
    padding: '4px 8px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
  col6: {
    color: '#212122',
    fontFamily: 'Castoro',
    fontSize: 10,
    flexGrow: 0,
    flexShrink: 0,
    width: '15.49%',
    padding: '4px 8px',
    borderRight: '1px solid #909090',
    borderBottom: '1px solid #909090',
    textAlign: 'center'
  },
})

const ProgramTable = ({program}: { program: any }) => {
  return <View style={styles.table} wrap={false}>
    <View style={styles.header}>
      <Text style={styles.header.title}>
        {program.name}
      </Text>
      <Text style={styles.header_approved}>
        {program.courses.length} courses approved
      </Text>
    </View>
    <View style={{...styles.row, fontWeight: 'light'}}>
      <Text style={styles.col1}>
        Code
      </Text>
      <Text style={styles.col2}>
        Course name
      </Text>
      <Text style={styles.col3}>
        Match ratio
      </Text>
      <Text style={styles.col4}>
        Review date
      </Text>
      <Text style={styles.col5}>
        Approved date
      </Text>
      <Text style={styles.col6}>
        Approved by
      </Text>
    </View>

    {
      program.courses.map((item: any, index: number) =>
        <View style={styles.row}>
          <Text style={styles.col1}>
            {item.code}
          </Text>
          <Text style={styles.col2}>
            {item.title}
          </Text>
          <Text style={styles.col3}>
            {`${Math.round(item.matching_score * 100)}%`}
          </Text>
          <Text style={styles.col4}>
            {item.reviewed_at_date}
          </Text>
          <Text style={styles.col5}>
            {item.approved_at_date}
          </Text>
          <Text style={styles.col6}>
            {item.approved_by}
          </Text>
        </View>
      )
    }
  </View>
}

export default ProgramTable;