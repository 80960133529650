import './breadcrumbs.scss';
import {useNavigate} from "react-router-dom";
import BreadcrumbsIcon from '../../../assets/icons/UI/icons/arrow-right.svg';

interface BreadcrumbsOption {
  label: string,
  link: string
}

interface BreadcrumbsProps {
  content: BreadcrumbsOption[]
}

const Breadcrumbs = (props: BreadcrumbsProps) => {
  const {
    content
  } = props;

  const navigate = useNavigate();

  return <div className={'breadcrumbs'}>
    {content.map((item: BreadcrumbsOption, index: number) =>
      <span key={index}
            className={`breadcrumbs-option ${index === content.length - 1 ? 'breadcrumbs-option-current' : ''}`}
            onClick={() => navigate(item.link)}
      >
        <span className={'breadcrumbs-option-text'}>
          {item.label}
        </span>
        <img
          src={BreadcrumbsIcon}
          alt={'divider'}
          className={'breadcrumbs-option-icon'}
        />
    </span>)}
  </div>
}

export default Breadcrumbs;