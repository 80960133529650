import "./assessmentID.scss";
import {useEffect, useMemo, useState, useContext, useLayoutEffect} from "react";
import { useParams } from "react-router-dom";
import AssessmentIntroPage from "../../components/AssessmentIntroPage/AssessmentIntroPage";
import AssessmentQuestionPage from "../../components/AssessmentQuestionPage/AssessmentQuestionPage";
import SelfAssessmentPage from "../../components/SelfAssessmentPage/SelfAssessmentPage";
import { ContentItem} from "../../components/SelfAssessmentPage/SelfAssessmentPage";

import { useMutationSubmitAssessments } from "../../../../api/assessment/useMutationSubmitAssessments";
import { useMutationFinishLater } from "../../../../api/assessment/useMutationFinishLater";
import { useQueryFinishLater } from "../../../../api/assessment/useQueryFinishLater";
import { useQueryQuestionData } from "../../../../api/assessment/useQueryQuestionData";
import { useQueryProgress } from "../../../../api/assessment/useQueryProgress";
import { UserContext } from "../../../../hooks/Context/UserContext";
import Loading from "../../../../components/UI/Loading/Loading";

export interface Option {
  content?: string;
  text?: string;
  id: number;
}

export interface Center {
  content: Option[];
  content_type: string;
}

export interface Right {
  content: Option[] | string;
  sub_question_id: number;
  content_type: "image" | "title" | "list" | "table" | "footer" | "options";
}

export interface Question {
  left?: ContentItem[];
  right?: Right[];
  center?: Center[];
  metadata: Record<string, any>;
  question_text: string;
  question_type: string;
  question_id: string | number;
  question_category: string;
}

export interface DashboardInfo {
  content: string;
  content_type: string;
  sub_content: string;
}

export interface Meta {
  assessment_name: string;
  description: string[];
  duration: string;
  number_of_questions: string;
  version: string;
  dashboard_info?: DashboardInfo[];
  self_assessment_description?: string[];
}

export interface AnswerFormat {
  question_id: string | number;
  answer: string | string[];
}

export interface QuestionData {
  assessment_id: number;
  meta: Meta;
  questions: Question[];
}

export interface Assessment {
  assessment_id: number;
  assessment_name: string;
  created_at: string | null;
  status: "in_progress" | "not_started" | "completed";
  updated_at: string | null;
}

const AssessmentPage = () => {
  const { assessment_id } = useParams();
  const [user_id] = useContext(UserContext);
  const [showIntro, setShowIntro] = useState<boolean>(true);
  const [showSelfAssessment, setShowSelfAssessment] = useState<boolean>(false);
  const [answers, setAnswers] = useState<AnswerFormat[]>([]);
  const [currQuestion, setCurrQuestion] = useState<number>(1);
  const [currAnswer, setCurrAnswer] = useState<string | string[]>("");
  const [loading, setLoading] = useState<boolean>(true);
  const { data: questionData, isLoading: isQuestionDataLoading, refetch: refetchQuestionData } = useQueryQuestionData({assessment_id});
  const { data: progressData, isLoading: isProgressLoading, refetch: refetchProgressData } = useQueryProgress();
  const { data: finishLaterAns, isLoading: isFinishLaterLoading, refetch: refetchFinishLater } = useQueryFinishLater({
    isInProgress: progressData?.assessments.find(
        (item: Assessment) => item.assessment_id === Number(assessment_id)
    )?.status === "in_progress",
    assessment_id
  });
  const { mutate: postSubmitAssessments } = useMutationSubmitAssessments({assessment_id});
  const { mutate: postFinishLater } = useMutationFinishLater();

  const assessmentData = useMemo(() => {
    return questionData;
  }, [questionData]);

  const totalSelfQuestions = useMemo<number>(() => {
    if (
        assessmentData && assessmentData.questions &&
        assessmentData.questions.some(
            (question: Question) => question.question_category === "Self Assessment"
        )
    ) {
      return assessmentData.questions.filter(
          (item: Question) => item.question_category === "Self Assessment"
      )?.length ?? 0;
    }
    return 0;
  }, [assessmentData]);

  useLayoutEffect(() => {
    setLoading(true);
    setAnswers([]);
    setCurrAnswer('');
    setCurrQuestion(1);
    setShowIntro(true);
    setShowSelfAssessment(false);

    Promise.all([refetchQuestionData(), refetchProgressData(), refetchFinishLater()]).finally(() => {
      setLoading(false);
    });
  }, [assessment_id, refetchQuestionData, refetchProgressData, refetchFinishLater]);

  useEffect(() => {
    if (finishLaterAns && finishLaterAns.error) {
      // handle 404 response and reset state for assessments that do not have finish later responses
      setCurrQuestion(1);
      setAnswers([]);
      setCurrAnswer('');
      setShowIntro(true);
      setShowSelfAssessment(false);
    } else if (finishLaterAns && finishLaterAns.responses.length > 0) {
      setCurrQuestion(finishLaterAns.responses.length + 1);
      setAnswers(finishLaterAns.responses);
    }
  }, [finishLaterAns]);

  useEffect(() => {
    if (currQuestion > answers.length) {
      setCurrAnswer("");
    } else {
      setCurrAnswer(answers[currQuestion - 1]?.answer);
    }
  }, [currQuestion, answers]);

  const onStart = () => {
    setShowIntro(false);
    if (
        assessmentData &&
        assessmentData.questions.some(
            (question: Question) => question.question_category === "Self Assessment"
        )
    ) {
      setShowSelfAssessment(true);
    }
  };

  const onContinue = () => {
    window.scroll(0, 0);
    if (currQuestion === totalSelfQuestions + 1 && showSelfAssessment) {
      setShowSelfAssessment(false);
      return;
    }

    let newAnswers;
    if (currQuestion === answers.length + 1) {
      const newAnswer = { question_id: assessmentData?.questions[currQuestion - 1]?.question_id || currQuestion, answer: currAnswer };
      newAnswers = [...answers, newAnswer];
      setAnswers(newAnswers);
    } else {
      newAnswers = answers.map((item, index) =>
          index !== currQuestion - 1
              ? item
              : { question_id: assessmentData?.questions[currQuestion - 1]?.question_id || currQuestion, answer: currAnswer }
      );
      setAnswers(newAnswers);
    }

    if (currQuestion === assessmentData?.questions.length) {
      const newAnswer = { question_id: currQuestion, answer: currAnswer };
      const newEIAnswer = { question_id: assessmentData?.questions[currQuestion - 1]?.question_id, answer: currAnswer };
      const newAnswerFormat = assessmentData?.assessment_id !== 4 ? newAnswer : newEIAnswer;
      postSubmitAssessments({
        user_id: user_id,
        assessment_id: Number(assessment_id),
        responses: newAnswers
      });
    }
    if (currQuestion - 1 === answers.length) {
      const newAnswer =
          assessmentData?.assessment_id !== 4
              ? { question_id: currQuestion, answer: currAnswer }
              : {
                question_id:
                    questionData?.questions[currQuestion - 1]?.question_id ?? currQuestion,
                answer: currAnswer,
              };

      setAnswers([...answers, newAnswer]);
    } else {
      setAnswers(
          answers.map((item, index) =>
              index !== currQuestion - 1
                  ? item
                  : { question_id: currQuestion, answer: currAnswer }
          )
      );
    }
    if (assessmentData && currQuestion < assessmentData.questions.length) {
      setCurrQuestion(currQuestion + 1);
    }

    handleFinishLater(newAnswers);
  };

  const handleFinishLater = (currentAnswers: AnswerFormat[]) => {
    postFinishLater({
      user_id: user_id,
      assessment_id: Number(assessment_id),
      responses: currentAnswers,
    });
  };

  const onBack = () => {
    if (currQuestion === 1) {
      setShowIntro(true);
    } else if (currQuestion === totalSelfQuestions && !showSelfAssessment) {
      setShowSelfAssessment(true);
    } else {
      setCurrQuestion(currQuestion - 1);
    }
  };

  //console.log('assessmentData', assessmentData, assessment_id, )

  if (isFinishLaterLoading || isQuestionDataLoading || isProgressLoading || loading || assessmentData?.assessment_id !== Number(assessment_id)) {
    return <Loading loading/>;
  }

  if (showIntro && assessmentData && assessmentData.questions) {
    return (
        <AssessmentIntroPage
            assessmentIntro={assessmentData}
            progress={(answers.length / assessmentData.questions.length) * 100}
            onStart={onStart}
        />
    );
  }

  if (showSelfAssessment && totalSelfQuestions > 0 && assessmentData && assessmentData.questions[currQuestion - 1].question_category === "Self Assessment") {
    return (
        <SelfAssessmentPage
            assessmentTitle={assessmentData?.meta.assessment_name}
            self_assessmentData={assessmentData.questions.filter(
                (item: Question) => item.question_category === "Self Assessment"
            )}
            totalSelfQuestions={totalSelfQuestions}
            currQuestion={currQuestion}
            self_assessmentInfo={assessmentData?.meta}
            progress={(answers.length / assessmentData.questions.length) * 100}
            onBack={onBack}
            onContinue={onContinue}
            currAnswer={currAnswer}
            setCurrAnswer={setCurrAnswer}
        />
    );
  }

  return (
      <AssessmentQuestionPage
          assessmentData={{
            title: assessmentData?.meta.assessment_name,
            questions: assessmentData?.questions,
          }}
          totalSelfQuestions={totalSelfQuestions}
          assessmentIntro={assessmentData?.meta}
          currQuestion={currQuestion}
          answers={answers}
          onBack={onBack}
          onContinue={onContinue}
          currAnswer={currAnswer}
          setCurrAnswer={setCurrAnswer}
      />
  );
};

export default AssessmentPage;