import {useQuery} from "@tanstack/react-query";
import {applicantsUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

interface ApplicantsParams {
  assigned_to?: string,
  hide_completed?: boolean,
  ordering?: string,
  search_term?: string,
  program_ids?: string[],
  current_page?: number,
}


export const useQueryApplicants = (params: ApplicantsParams) => {
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(
      applicantsUrl,
      {params: params}
    )
      .then(response => response?.data ?? response);
  }

  return useQuery({
      queryKey: ['applicants'],
      queryFn: handleRequest,
    }
  )

}
