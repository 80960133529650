import './result-grading.scss';
import {createRef, useEffect} from "react";

interface ResultGradingProps {
  percentage: number,
  size?: 'large' | 'small'
}

const ResultGrading = (props: ResultGradingProps) => {
  const {
    percentage,
    size = 'large'
  } = props;

  const containerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (percentage) {
      containerRef!.current!.style.background = `conic-gradient(from 0deg, #212121 ${percentage}%, transparent 0%)`
    }
  }, [percentage])

  return <div ref={containerRef} className={`result-grading ${size === 'small' ? 'result-grading-small' : ''}`}>
    <div className={'result-grading-border result-grading-border-outside'}/>
    <div className={'result-grading-border result-grading-border-inside'}/>

    <div className={'result-grading-inside'}>
      {percentage}%
    </div>
  </div>
}

export default ResultGrading;