import {useMutation, useQueryClient} from "@tanstack/react-query";
import {assessmentSubmitURL} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";
import {useNavigate} from "react-router-dom";

export const useMutationSubmitAssessments = ({assessment_id}: {assessment_id?: string}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const axios = useAxios();

  async function postSubmitAssessments(data: any) {
    return axios.post(assessmentSubmitURL, data);
  }

  return useMutation({
    mutationKey: ["assessment", "submit"],
    mutationFn: postSubmitAssessments,
    onSuccess: () => {
      queryClient.invalidateQueries({queryKey: ["assessment"]});
      queryClient.invalidateQueries({queryKey: ["submit"]});
      navigate(`/assessments/assessment/${assessment_id}` + '/results');
    }
  });
};