import {useEffect, useMemo, useState} from "react";
import AssessmentHeader from "../AssessmentHeader/AssessmentHeader";
import AssessmentBack from "../AssessmentBack/AssessmentBack";
import {useNavigate} from "react-router-dom";
import AlertModal from "../../../../components/UI/Modals/AlertModal/AlertModal";
import AssessmentFooter from "../AssessmentFooter/AssessmentFooter";
import AssessmentIntro from "../AssessmentIntro/AssessmentIntro";
import AssessmentQuestion from "../AssessmentQuestion/AssessmentQuestion";
import SelfAssessmentSubmit from "../SelfAssessmentSubmit/SelfAssessmentSubmit";
import { Meta,Right } from "../../assessment/[assessment_id]/AssessmentID";

export interface Content {
  id: number;
  content?: string;
  text?: string;
}
export interface Center {
  content: Content[];
  content_type: string;
}

export interface ContentItem {
  content: string[];
  content_type: 'image' | 'title' | 'list' | 'table' | 'footer';
}
export interface Question {
  right?:Right[];
  left?: ContentItem[];
  center?: Center[];
  metadata?: Record<string, any>;
  question_category?: string;
  question_text?: string;
  question_type?: string;
  question_id?: number,
}

interface SelfAssessmentProps {
  assessmentTitle?: string,
  self_assessmentInfo?: Meta,
  self_assessmentData: Question[],
  totalSelfQuestions?: number,
  currQuestion: number,
  progress: number,
  onBack: () => void,
  onContinue: () => void,
  currAnswer: string | string[],
  setCurrAnswer: (answer: string | string[]) => void,
}

const SelfAssessmentPage = (props: SelfAssessmentProps) => {
  const {
    assessmentTitle,
    self_assessmentData,
    self_assessmentInfo,
    totalSelfQuestions,
    currQuestion,
    progress,
    currAnswer,
    setCurrAnswer,
    onBack,
    onContinue,
  } = props;
  const navigate = useNavigate();
  const [showIntro, setShowIntro] = useState<boolean>(true);
  const [showCloseModal, setShowCloseModal] = useState<boolean>(false);
  const backText = useMemo<string>(() => {
    if (currQuestion === 1) {
      return showIntro
        ? 'Back to assessment instructions'
        : 'Back to self-assessment instructions';
    }
    return 'Back to previous question';

  }, [currQuestion, showIntro])

  const handleBack = () => {
    if (currQuestion === 1 && !showIntro && self_assessmentData.length > 0) {
      setShowIntro(true);
    } else {
      onBack();
    }
  }
  const renderContent = () => {
    if (showIntro) {
      return <AssessmentIntro
        detail={self_assessmentInfo}
      />
    }
    if(totalSelfQuestions && currQuestion !== totalSelfQuestions + 1){
      return <AssessmentQuestion
      question={self_assessmentData[currQuestion - 1]}
      currIndex={currQuestion}
      totalQuestions={self_assessmentData.length}
      chosen={currAnswer}
      setChosen={(answer_id: string | string[]) => setCurrAnswer(String(answer_id))}
    />
    }
    return <SelfAssessmentSubmit/>
  }

  useEffect(() => {
    if (currQuestion > 1) {
      setShowIntro(false);
    }
  }, [])
  return <div className={'assessment-page'}>
    <AssessmentHeader
      title={assessmentTitle}
      showInstructions={!showIntro}
      instructions={self_assessmentInfo}
      text={'View self-assessment instructions'}
    />
    <div className={'assessment-page-body'}>
      <AssessmentBack
        text={backText}
        onClick={handleBack}
      />
      {renderContent()}
    </div>

    <AssessmentFooter
      progress={progress}
      continueText={totalSelfQuestions && currQuestion  === totalSelfQuestions + 1 && currQuestion > 0  ? 'Submit self-assessment' : 'Continue'}
      onContinue={showIntro
        ? () => setShowIntro(false)
        : onContinue
      }
      onClose={() => setShowCloseModal(true)}
      showCloseButton={!showIntro}
      continueDisabled={!showIntro && !currAnswer && currQuestion !== (totalSelfQuestions && totalSelfQuestions + 1)}
    />

    <AlertModal
      open={showCloseModal}
      onClose={() => setShowCloseModal(false)}
      text={'Are you sure you want to exit? Your progress will be saved for when you return.'}
      proceedText={'Yes, save and exit'}
      closeText={'No, continue assessment'}
      onProceed={() => navigate('/assessments/assessment')}
    />
  </div>
}

export default SelfAssessmentPage;