import React, {useState} from 'react';
import './employee-table.scss';
import ComplexSearch from "../../../../../../components/UI/ComplexSearch/ComplexSearch";
import { assessment_user_list_sorting_options } from "../../../../../../constants/ui/sortingOptions";
import {UserPlus} from "@phosphor-icons/react";
import CsvUploader from "../../../employee-upload/CsvUploader/CsvUploader";
import {useQueryClient} from "@tanstack/react-query";

interface Employee {
    id: string;
    first_name: string;
    last_name: string;
    job_title: string;
    location: string;
    team: string;
}

interface Props {
    data: any; // Define more specific types based on your API structure
    onRowClick: (id: string) => void;
    searchValue: string;
    setSearchValue: (value: string) => void;
    sortingValue: any;
    setSortingValue: any;
    hasEmployees: any
}

const EmployeeTable: React.FC<Props> = ({
                                            data,
                                            onRowClick,
                                            searchValue,
                                            setSearchValue,
                                            sortingValue,
                                            setSortingValue,
                                            hasEmployees
                                        }) => {
    const queryClient = useQueryClient();
    const [showUploader, setShowUploader] = useState<boolean>(false);

    const handleAddEmployees = () => {
        setShowUploader(true);
    };

    const handleUsersCreated = () => {
        setShowUploader(false);
        queryClient.invalidateQueries({ queryKey: ['employees'] });
    };

    const handleCancel = () => {
        setShowUploader(false);
    };

    return (
        <div className="employee-table">
            {showUploader ? (
                <CsvUploader maxRows={250} onUsersCreated={handleUsersCreated} onCancel={handleCancel} hasEmployees={hasEmployees}/>
            ) : (
                <>
                <div className="search-sort-button">
                    <ComplexSearch
                        search={{
                            value: searchValue,
                            onChange: e => setSearchValue(e.target.value),
                            placeholder: 'Search Employees'
                        }}
                        sorting={{
                            options: assessment_user_list_sorting_options,
                            value: sortingValue,
                            onChange: (newSorting) => setSortingValue(newSorting)
                        }}
                    />
                    <h3 className="add-employee-btn" onClick={handleAddEmployees}>
                        <UserPlus size={25}/>
                        Add Employee
                    </h3>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th>Employee</th>
                        <th>Team</th>
                        <th>Location</th>
                    </tr>
                    </thead>
                    <tbody>
                    {data.users.users.map((employee: Employee) => (
                        <tr key={employee.id} onClick={() => onRowClick(employee.id)}>
                            <td>
                                <div className="table-name-cell">
                                  <span className="user-profile-avatar">
                                    {employee.first_name[0]}{employee.last_name[0] ?? employee.first_name[0]}
                                  </span>
                                    <div>
                                        <p>{employee.first_name} {employee.last_name}</p>
                                        <p className="table-name-cell-job">{employee.job_title}</p>
                                    </div>
                                </div>
                            </td>
                            <td>{employee.team}</td>
                            <td>{employee.location}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
                </>
            )}
        </div>
    );
};

export default EmployeeTable;
