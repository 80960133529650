export const applicantsSortingOptions = [
  {
    label: 'Alphabetically A-Z',
    value: 'name_ascending'
  },
  {
    label: 'Alphabetically Z-A',
    value: 'name_descending'
  },
  {
    label: 'Newest applications to oldest',
    value: 'created_at_ascending'
  },
  {
    label: 'Oldest applications to newest',
    value: 'created_at_descending'
  },
  {
    label: 'Review not started to completed',
    value: 'review_not_started'
  },
  {
    label: 'Review completed to not started',
    value: 'review_completed'
  },
]
export const programsSortingOptions = [
  {
    label: 'Alphabetically A-Z',
    value: 'name_ascending'
  },
  {
    label: 'Alphabetically Z-A',
    value: 'name_descending'
  },
  {
    label: 'Highest number of recommended courses to least',
    value: 'num_recommended_descending'
  },
  {
    label: 'Least number of recommended courses to highest',
    value: 'num_recommended_ascending'
  },
]

export const programsSortingOptionsAdmin = [
  {
    label: 'Alphabetically A-Z',
    value: 'name_ascending'
  },
  {
    label: 'Alphabetically Z-A',
    value: 'name_descending'
  },
  {
    label: 'Highest number of recommended courses to least',
    value: 'num_recommended_descending'
  },
  {
    label: 'Least number of recommended courses to highest',
    value: 'num_recommended_ascending'
  },
  {
    label: 'Priority Sorting',
    value: 'priority_sorting'
  }
]

export const coursesSortingOptions = [
  {
    label: 'Alphabetically A-Z',
    value: 'title_ascending'
  },
  {
    label: 'Alphabetically Z-A',
    value: 'title_descending'
  },
  {
    label: 'Highest to lowest match',
    value: 'matching_score_descending'
  },
  {
    label: 'Lowest to highest match',
    value: 'matching_score_ascending'
  }
]

export const coursesSortingOptionsAdmin = [
  {
    label: 'Alphabetically A-Z',
    value: 'title_ascending'
  },
  {
    label: 'Alphabetically Z-A',
    value: 'title_descending'
  },
  {
    label: 'Highest to lowest match',
    value: 'matching_score_descending'
  },
  {
    label: 'Lowest to highest match',
    value: 'matching_score_ascending'
  },
  {
    label: 'Priority Sorting',
    value: 'priority_sorting'
  }
]

export const filtersPropgramsOptions = [
  {
    label: 'Industrial Design',
    value: 'Industrial Design'
  },
  {
    label: 'Business Management',
    value: 'Business Management'
  },
  {
    label: 'Nursing',
    value: 'Nursing'
  },
  {
    label: 'Computer Science',
    value: 'Computer Science'
  },
  {
    label: 'Design Management',
    value: 'Design Management'
  },
  {
    label: 'Business Management',
    value: 'Business Management'
  },
  {
    label: 'Information Technology',
    value: 'Information Technology'
  },
  {
    label: 'Graphic Design',
    value: 'Graphic Design'
  },
  {
    label: 'Integrated Media',
    value: 'Integrated Media'
  }
]

export const assessment_user_list_sorting_options = [
  {
    label: 'Name A-Z',
    value: 'name_ascending'
  },
  {
    label: 'Name Z-A',
    value: 'name_descending'
  },
  {
    label: 'Location A-Z',
    value: 'location_ascending'
  },
  {
    label: 'Location Z-A',
    value: 'location_descending'
  },
  {
    label: 'Team A-Z',
    value: 'team_ascending'
  },
  {
    label: 'Team Z-A',
    value: 'team_descending'
  },
]