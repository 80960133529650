import {useQuery} from "@tanstack/react-query";
import {userInfoUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useQueryUserInfo = ({portfolio_id}: { portfolio_id?: string | null }) => {
  const axios = useAxios();

  const getUserInfo = () => {
    return axios.get(userInfoUrl, {
      params: {
        portfolio_id
      }
    }).then(data => data.data)
  }

  return useQuery({
    queryKey: ['user'],
    queryFn: getUserInfo,
    enabled: false,
    retry: false
  })
}