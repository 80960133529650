import { useMutation } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";
import { documentRequestEndpointUrl } from "../../constants/api/endpoints";
import { toast } from "react-toastify";

interface DocumentRequestData {
	title: string;
	notes: string;
	course_recommendation_id: string;
}

interface CreateDocumentRequestPayload {
	portfolio_id: string;
	document_requests: DocumentRequestData[];
}

interface CreateDocumentRequestResponse {
	message: string;
}

export const useMutateDocumentRequest = () => {
	const axios = useAxios();

	const createRequest = async (payload: CreateDocumentRequestPayload) => {
		const response = await axios.post<CreateDocumentRequestResponse>(
			documentRequestEndpointUrl,
			payload
		);
		return response.data;
	};

	return useMutation({
		mutationFn: createRequest,
		onError: (error) => {
			// @ts-ignore
			toast.error(error);
		},
	});
};
