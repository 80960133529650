import './progress-chiclet.scss';
import {ChicletTypes} from "../../../../constants/ui/types/ChicletTypes";

interface ProgressChicletProps {
  type: ChicletTypes,
  text: string,
  progress?: [number, number]
}

const ProgressChiclet = (props: ProgressChicletProps) => {
  const {
    type,
    text,
    progress
  } = props;

  return <span className={`progress-chiclet-container`}>
    <span className={`progress-chiclet progress-chiclet-${type.replaceAll(' ', '_')}`}>
    {progress ? `${progress[0]}/${progress[1]}` : null} {text}
  </span>
  </span>
}

export default ProgressChiclet;