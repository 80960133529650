export const SampleAQData = {
  assessment_id: 3,
  assessment_name: "Adaptability Quotient",
  type: "aqType",
  total_score: 75,
  overall_text: [
    "In your Reading Self-Assessment, you rated yourself as having 60% reading proficiency.",
    "You were able to identify and locate relevant and clearly stated information. You also demonstrated some ability to form conclusions based on stated and unstated details. Additionally, you were able to identify some relationships in the text such as chronology, main idea supporting, the ability to compare and contrast, and the ability to draw cause and effect relationships.",
  ],
  results: [
    {
      title: "Problem Solving",
      text: "This score reflects your ability to be flexible and adaptable when faced with a difficult problem. A high score in this component suggests that you are good at identifying alternate solutions to problems and in accommodating unexpected challenges when solving a problem.",
      percentage: 75,
    },
    {
      title: "Problem Solving",
      text: "This score reflects your ability to be flexible and adaptable when faced with a difficult problem. A high score in this component suggests that you are good at identifying alternate solutions to problems and in accommodating unexpected challenges when solving a problem.",
      percentage: 60,
    },
    {
      title: "Problem Solving",
      text: "This score reflects your ability to be flexible and adaptable when faced with a difficult problem. A high score in this component suggests that you are good at identifying alternate solutions to problems and in accommodating unexpected challenges when solving a problem.",
      percentage: 60,
    },
    {
      title: "Problem Solving",
      text: "This score reflects your ability to be flexible and adaptable when faced with a difficult problem. A high score in this component suggests that you are good at identifying alternate solutions to problems and in accommodating unexpected challenges when solving a problem.",
      percentage: 60,
    },
  ],
};

export const SampleAccordionData = {
  "assessment_id": 2, 
  "assessment_name": "OCEAN",
  "type": "accordion",
  "results": [
    {
      "title":"Acknowledging and Knowing Their Own Emotions",
      "percentage": 70,
      "text_List":[
        "In your Reading Self-Assessment, you rated yourself as having 60% reading proficiency.",
        "You were able to identify and locate relevant and clearly stated information. You also demonstrated some ability to form conclusions based on stated and unstated details. Additionally, you were able to identify some relationships in the text such as chronology, main idea supporting, the ability to compare and contrast, and the ability to draw cause and effect relationships.",
        "As most jobs in Canada require 80% or better document literacy, it would be beneficial for you to seek additional training. Such training would improve your ability to do your job well or to find and keep a better job."
      ]
    },
    {
      "title":"Controlling and Managing Their Own Emotions ",
      "percentage": 90,
      "text_List":[
        "In your Reading Self-Assessment, you rated yourself as having 60% reading proficiency.",
        "You were able to identify and locate relevant and clearly stated information. You also demonstrated some ability to form conclusions based on stated and unstated details. Additionally, you were able to identify some relationships in the text such as chronology, main idea supporting, the ability to compare and contrast, and the ability to draw cause and effect relationships.",
        "As most jobs in Canada require 80% or better document literacy, it would be beneficial for you to seek additional training. Such training would improve your ability to do your job well or to find and keep a better job."
      ]
    }
  ]
}

export const SampleNormalData =  {
  "assessment_id": 3, 
  "assessment_name": "Problem Solving",
  "type": "normal",
  "results":[
    {
      "percentage": 70,
      "percentage_range": "Level 3 (69 – 100 %)",
      "text_List":[
        "In your Reading Self-Assessment, you rated yourself as having 60% reading proficiency.",
        "You were able to identify and locate relevant and clearly stated information. You also demonstrated some ability to form conclusions based on stated and unstated details. Additionally, you were able to identify some relationships in the text such as chronology, main idea supporting, the ability to compare and contrast, and the ability to draw cause and effect relationships.",
        "As most jobs in Canada require 80% or better document literacy, it would be beneficial for you to seek additional training. Such training would improve your ability to do your job well or to find and keep a better job."
      ]
    }
  ]
}