import Select, {
  components,
  DropdownIndicatorProps,
  GetOptionLabel,
  GetOptionValue,
  MultiValueProps
} from 'react-select';
import {ActionMeta, SingleValue} from 'react-select';
import LabelKMQ from "../Label/LabelKMQ";
import './select.scss';
import React from "react";
import SelectIconDownDisabled from '../../../assets/icons/UI/select/arrow-down-disabled.svg';
import SelectIconDown from '../../../assets/icons/UI/select/arrow-down.svg';
import SelectIconUp from '../../../assets/icons/UI/select/arrow-up.svg';

interface SelectProps {
  options: any[],
  value: object | null,
  onChange: (element: SingleValue<any>, action: ActionMeta<any>) => void,
  label?: string,
  placeholder?: string,
  getOptionLabel?: GetOptionLabel<any>,
  getOptionValue?: GetOptionValue<any>,
  disabled?: boolean,
  isMulti?: boolean
  error?: boolean
}

const SelectKMQ = (props: SelectProps) => {
  const {
    options,
    value,
    onChange,
    getOptionLabel,
    getOptionValue,
    placeholder,
    label,
    disabled,
    isMulti,
    error
  } = props

  const DropdownIndicator: React.FC<DropdownIndicatorProps> = props => {
    return (
      <img
        src={
          disabled
            ? SelectIconDownDisabled
            : props.selectProps.menuIsOpen
              ? SelectIconUp
              : SelectIconDown
        }
        alt={'select-down'}
      />
    );
  };

  const MultiValue = (props: MultiValueProps) => {
    const content = props.children
      ? String(props.children).slice(0, 3)
      : props.children;
    return <components.MultiValue {...props}>{content}</components.MultiValue>;
  };

  return <span
    className={`select-container ${disabled ? 'select-container-disabled' : ''} ${error ? 'select-container-error' : ''}`}>
    <LabelKMQ>{label}</LabelKMQ>

    <Select
      classNamePrefix='selectkmq'
      components={{DropdownIndicator, MultiValue}}
      value={value}
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      menuIsOpen={disabled ? false : undefined}
      isSearchable
      isMulti={isMulti}
    />
    </span>
}

export default SelectKMQ;