import Loading from "../../../components/UI/Loading/Loading";
import {useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";

const StartOver = () => {
  const navigate = useNavigate();
  const [_, setPortfolio] = useContext(PortfolioContext);

  useEffect(() => {
    localStorage.removeItem('refresh');
    localStorage.removeItem('portfolio_id');
    localStorage.removeItem('marketplace_user');
    localStorage.removeItem('basic_info');
    setPortfolio({});
    navigate('/cpl-applicant');
  }, []);

  return <Loading loading/>
}

export default StartOver;