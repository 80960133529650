import './admin.scss';
import React, {useContext, useEffect, useState} from "react";
import PlarCard, {PlarCardType} from "../../../components/UI/Cards/Plar/PlarCard";
import {useNavigate, useParams} from "react-router-dom";
import {useQueryPrograms} from "../../../api/admin/useQueryPrograms";
import {useQueryCourses} from "../../../api/admin/useQueryCourses";
import {useQueryClient} from "@tanstack/react-query";
import {coursesSortingOptionsAdmin} from "../../../constants/ui/sortingOptions";
import SearchAndSort, {ISortingOption} from "../../../components/UI/SearchAndSort/SearchAndSort";
import Breadcrumbs from "../../../components/UI/Breadcrumbs/Breadcrumbs";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import CourseCard from '../../../components/UI/Cards/Plar/CourseCard';

export interface ICourse {
  id: string,
  course_recommendation_id: string,
  code: string,
  description: string,
  matching_score: number,
  approved?: string,
  reviewed_at_date?: string,
  reviewer_name?: string,
  title: string,
  type?: string,
  cpl_eligible?: boolean
  payment_status: string
}

const AdminCourses = () => {
  const navigate = useNavigate();
  const {program_id, portfolio_id} = useParams();
  const [portfolio] = useContext(PortfolioContext);
  const queryClient = useQueryClient();
  const [searchValue, setSearchValue] = useState<string>('');
  const [sortingValue, setSortingValue] = useState<ISortingOption>(coursesSortingOptionsAdmin[4]);
  const [init, setInit] = useState<boolean>(false);

  const {data: currProgram} = useQueryPrograms({
    portfolio_id: portfolio_id,
    program_id: program_id
  });

  const {data: courses} = useQueryCourses({
    portfolio_id: portfolio_id,
    program_id: program_id,
    searchValue,
    sortingValue: sortingValue.value
  });

  const getCardInfo = (course: ICourse) => {
    if (course.approved === null) {
      return {
        type: PlarCardType.Not_started,
        chicletText: undefined,
        statusText: undefined
      };
    }
    if (course.approved) {
      return {
        type: PlarCardType.Completed,
        chicletText: 'Approved',
        statusText: [`Approved on ${course.reviewed_at_date} by ${course.reviewer_name}`]
      };
    }
    return {
      type: PlarCardType.Rejected,
      chicletText: 'Rejected',
      statusText: [`Rejected on ${course.reviewed_at_date} by ${course.reviewer_name}`]
    };
  }

  useEffect(() => {
    window.scroll(0, 0);
  }, [])

  useEffect(() => {
    if (searchValue.length > 2 || searchValue.length === 0) {
      queryClient.invalidateQueries({queryKey: ['courses']});
    }
  }, [searchValue])

  useEffect(() => {
    if (init) {
      queryClient.invalidateQueries({queryKey: ['courses']});
    } else {
      setInit(true);
    }
  }, [sortingValue])

  return <div>
    <div className={'admin-courses'}>
      <Breadcrumbs content={[
        {
          label: 'Applicants',
          link: `/cpl-admin`
        },
        {
          label: `${portfolio.portfolio_user_name}’s profile`,
          link: `/cpl-admin/${portfolio_id}`
        },
        {
          label: `${currProgram?.name}`,
          link: `/cpl-admin/${portfolio_id}/${program_id}`
        }
      ]}/>
      <div className={'admin-courses-title'}>
        {currProgram?.name}
      </div>
      <div className={'admin-courses-subtitle'}>
        {`${currProgram?.num_courses_recommended} courses recommended`}
      </div>

      <SearchAndSort
        searchValue={searchValue}
        onSearchChange={(e) => setSearchValue(e.target.value)}
        placeholder={'Search Programs'}
        sortingOptions={coursesSortingOptionsAdmin}
        activeSorting={sortingValue}
        onSortingChange={(newSorting) => setSortingValue(newSorting)}
      />

      {
        courses?.data?.courses && courses.data.courses.map((item: ICourse, index: number) =>
          <div key={index}>
            <CourseCard
              {...getCardInfo(item)}
              title={item.title}
              code={item.code}
              onClick={() => navigate(`/cpl-admin/${portfolio_id}/${program_id}/${item.code}`)}
              buttonText={'View supporting evidence'}
              match={`${Math.floor(item.matching_score * 100)}% match`}
              cpl_eligible={item.cpl_eligible}
              payment_status={
                item?.payment_status === 'paid'
                  ? item.payment_status
                  : ''
              }
              changePaymentStatus={() => {}}
            />
          </div>)
      }
    </div>
  </div>
}

export default AdminCourses;