import {Outlet} from "react-router-dom";
import {useState} from 'react';
import {ResumeContext} from "../../../hooks/Context/ResumeContext";
import {Resume} from "../../../lib/resume-parser/redux/types";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {IUser, UserContext} from "../../../hooks/Context/UserContext";
import AuthLayout from "../AuthLayout/AuthLayout";
import ToastContainerUI from "../../UI/ToastContainerUI/ToastContainerUI";
import {IToken, TokenContext} from "../../../hooks/Context/TokenContext";
import {IPortfolio, PortfolioContext} from "../../../hooks/Context/PortfolioContext";

const GlobalLayout = () => {
  const [parsedResume, setParsedResume] = useState<Resume>({
    basic_info: {},
    work_experience: [{
      company: '',
      job_title: '',
      job_summary: ''
    }],
    education: [{
      program: ''
    }],
  });

  const [user, setUser] = useState<IUser>({});
  const [portfolio, setPortfolio] = useState<IPortfolio>({});
  const [token, setToken] = useState<IToken>({accessToken: '', refreshToken: ''});

  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        retry: 5,
      },
      queries: {
        retry: 2
      }
    },
  })


  return <TokenContext.Provider value={[token, setToken]}>
    <QueryClientProvider client={queryClient}>
      <UserContext.Provider value={[user, setUser]}>
        <ResumeContext.Provider value={[parsedResume, setParsedResume]}>
          <PortfolioContext.Provider value={[portfolio, setPortfolio]}>

            <AuthLayout>
              <Outlet/>
            </AuthLayout>

            <ToastContainerUI/>

          </PortfolioContext.Provider>
        </ResumeContext.Provider>
      </UserContext.Provider>
    </QueryClientProvider>
  </TokenContext.Provider>
}

export default GlobalLayout;