import {useMutation, useQueryClient} from "@tanstack/react-query";
import {assignPortfolioToAdminUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useAssignAdmin = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function postAssign(data: {portfolio_id: string, user_id: string}) {
    return axios.post(assignPortfolioToAdminUrl, data)
  }

  return useMutation({
    mutationKey: ['assign-admin'],
    mutationFn: postAssign,
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ['user'] })
  });
}