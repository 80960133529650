import {useNavigate} from "react-router-dom";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";

const UserProgramsEmpty = () => {
  const navigate = useNavigate();

  return <div className={'user-programs-empty'}>
    <div  className={'user-programs-empty-container'}>
      <div className={'user-programs-empty-text'}>
        To see recommended programs make sure to fill out your resume and submit your application.
      </div>

      <div className={'user-programs-empty-button'}>
        <ButtonKMQ onClick={() => navigate('/cpl-applicant')}>
          Go to My Account
        </ButtonKMQ>
      </div>
    </div>
  </div>
}

export default UserProgramsEmpty;