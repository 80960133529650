import { useMutation, useQueryClient } from "@tanstack/react-query";
import { assessmentFinishLaterURL } from "../../constants/api/endpoints";
import { useAxios } from "../../hooks/axios/useAxios";

export const useMutationFinishLater = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function postFinishLater(data: any) {
    return axios.post(assessmentFinishLaterURL, data);
  }

  return useMutation({
    mutationKey: ["assessment", "finishLater"],
    mutationFn: postFinishLater,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["assessment"] });
      queryClient.invalidateQueries({ queryKey: ["finishLater"] });
    },
  });
};
