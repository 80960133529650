import './assessment-card.scss';
import {UserRoles} from "../../../../constants/user/roles";
import {AssessmentCardTypes} from "../../../../constants/ui/types/AssessmentCardTypes";
import AssessmentCardButtons from "./components/CardButtons/AssessmentCardButtons";
import AssessmentCardStatus, {CompletionStatus} from "./components/CardStatus/AssessmentCardStatus";
import AssessmentCardIcons, {AssessmentIcon} from "./components/CardIcons/AssessmentCardIcons";

export interface AssessmentCardProps {
  type?: AssessmentCardTypes,
  title: string,
  icons: AssessmentIcon[],
  description: string,
  userRole: UserRoles,
  onViewDetails: () => void,
  onClick: () => void,
  onTeamAssign?: () => void,
  completionStatus?: CompletionStatus[],
  additionalStatusText?: string[],
}

const AssessmentCard = (props: AssessmentCardProps) => {
  const {
    type = AssessmentCardTypes.Regular,
    title,
    description,
  } = props;


  return <div className={`assessment-card assessment-card-${type.replaceAll(' ', '_')}`}>
    <div className={'assessment-card-title'}>
      {title}
    </div>

    <AssessmentCardIcons {...props} />

    <div className={'assessment-card-description'}>
      {description}
    </div>

    <AssessmentCardStatus {...props}/>

    <AssessmentCardButtons {...props}/>
  </div>
}

export default AssessmentCard;