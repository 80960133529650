import {useContext, useEffect, useState} from "react";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import {useQueryUserInfo} from "../../../api/admin/useQueryUserInfo";
import Loading from "../../UI/Loading/Loading";
import {Outlet, useNavigate, useParams} from "react-router-dom";
import {toast} from "react-toastify";

const CplAdminPortfolioLayout = () => {
  const navigate = useNavigate();
  const {portfolio_id} = useParams();
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const {refetch} = useQueryUserInfo({portfolio_id});
  const [showLoading, setShowLoading] = useState<boolean>(true);

  useEffect(() => {
    if (showLoading || portfolio.id !== portfolio_id) {
      setShowLoading(true);
      refetch().then(response => {
        setShowLoading(false);
        if (response.data) {
          setPortfolio(response.data)
        }
      }).catch(() => {
        navigate('/cpl-admin');
        toast.error('This portfolio is no longer available');
      })
    }
  }, [navigate, portfolio_id, portfolio])

  if (!showLoading && portfolio.id === portfolio_id) {
    return <Outlet/>
  }

  return <Loading loading/>;
}

export default CplAdminPortfolioLayout;