import './college-card.scss';
import StatusChiclet from "../../Chiclets/StatusChiclet/StatusChiclet";
import IconChiclet from "../../Chiclets/IconChiclet";
import MedalIcon from "../../../../assets/icons/marketplace/medal-transparent.svg";

interface CollegeCardProps {
  logo: string,
  name: string,
  location?: string,
  num_cpl_eligible_recommendations: number,
  num_programs_with_recommended_courses: number,
  onClick: () => void
}

const CollegeCard = (props: CollegeCardProps) => {
  const {
    logo,
    name,
    location,
    num_programs_with_recommended_courses,
    num_cpl_eligible_recommendations,
    onClick
  } = props;

  return <div className={'college-card'}>
    <div className={'college-card-logo'} onClick={onClick}>
      {location
        ? <span className={'college-card-logo-location'}>
          {location}
        </span>
        : null}
      <img
        src={logo}
        alt={'logo'}
        className={'college-card-logo-image'}
      />
    </div>
    <div className={'college-card-description'}>
      <div className={'college-card-description-name'}>
        {name}
      </div>
      <div className={'college-card-description-courses'}>
        <StatusChiclet text={`${num_cpl_eligible_recommendations} Credit for Prior Learning Eligible Courses`}/>
      </div>
      <div className={'college-card-description-programs'}>
        <IconChiclet
          text={`${num_programs_with_recommended_courses} programs`}
          icon={MedalIcon}
        />
      </div>
    </div>

  </div>

}

export default CollegeCard;