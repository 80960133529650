import './assessment-question.scss';
import AssessmentQuestionBody from "./components/AssessmentQuestionBody";
import AssessmentContent from "./components/AssessmentContent";
import { Center, ContentItem, Question } from '../SelfAssessmentPage/SelfAssessmentPage';
import { AnswerFormat,Right } from '../../assessment/[assessment_id]/AssessmentID';

export interface IContent {
  type: string,
  content: any
}

export interface AssessmentQuestionProps {
  left?: ContentItem[];
  center?: Center[],
  right?: Right[],
  question: Question,
  currIndex?: number,
  totalQuestions: number,
  chosen: string | string[],
  answers?: AnswerFormat[],
  setChosen: (answer: string | string[]) => void
}


const AssessmentQuestion = (props: AssessmentQuestionProps) => {
  const {
    question,
    answers, 
    currIndex
  } = props;
  

  if (question && question.left) {
    return <table className={'assessment-container'}>
      <tr>
        <td className={'assessment-container-content'}>
          <AssessmentContent
            leftContent={question.left}
            answers={answers}
            currIndex={currIndex}
          />
        </td>

        <td className={'assessment-container-question'}>
          <AssessmentQuestionBody
            {...props}
          />
        </td>
      </tr>
    </table>
  }

  return <AssessmentQuestionBody
    {...props}
  />

}

export default AssessmentQuestion;