export const createSoftUserURL = 'user-management/soft/login';
export const postRefreshTokenUrl = (userIsSoft?: boolean) =>
  `user-management/${userIsSoft ? 'soft/' : ''}token/refresh`;

export const postRecommendationUrl = (userIsSoft?: boolean) =>
  `${userIsSoft ? 'soft/' : ''}recommendation/`;

export const programsUrl = (userIsSoft?: boolean) =>
  `course-information/${userIsSoft ? 'soft/' : ''}programs`;

export const coursesUrl = (userIsSoft?: boolean) =>
  `course-information/${userIsSoft ? 'soft/' : ''}courses`;

export const marketplaceInstitutionsUrl = (userIsSoft?: boolean) =>
  `course-information/${userIsSoft ? 'soft/' : ''}education-institutions`;

export const favouriteUrl = (userIsSoft?: boolean) =>
  `core-plar-recommendation/${userIsSoft ? 'soft/' : ''}favourite-program`;

export const addToCartUrl = (userIsSoft?: boolean) =>
  `/payments/${userIsSoft ? 'soft/' : ''}course-recommendation-payment-status`;