import './applicationInfo.scss';
import InputKMQ from "../../../../UI/Input/InputKMQ";
import { ChangeEvent } from "react";
import { ResumeBasicInfo } from '../../../../../lib/resume-parser/redux/types';
import ErrorKMQ from "../../../../UI/Error/ErrorKMQ";


interface ResumeApplicantProps {
  applicantInfo: ResumeBasicInfo,
  changeApplicantInfo: (applicantInfo: ResumeBasicInfo) => void,
  softUserError: string
}

const WorkExperience = (props: ResumeApplicantProps) => {
  const {
    applicantInfo,
    changeApplicantInfo,
    softUserError
  } = props;

  const {
    email,
    first_name,
    last_name,
    full_name,
  } = applicantInfo;

  return (
    <div className={"resume-applicant"}>
      <InputKMQ
        value={first_name ?? ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          changeApplicantInfo({ first_name: e.target.value })
        }
        type={"text"}
        placeholder={"Enter first name"}
        label={"First Name"}
        error={
          (!first_name && softUserError === 'validation')
            ? 'Please enter the first name'
            : ''
        }
      />
      <InputKMQ
        value={last_name ?? ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          changeApplicantInfo({ last_name: e.target.value })
        }
        type={"text"}
        placeholder={"Enter last name"}
        label={"Last Name"}
        error={
          (!last_name && softUserError === 'validation')
            ? 'Please enter the last name'
            : ''
        }
      />
      <InputKMQ
        value={email ?? ""}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          changeApplicantInfo({ email: e.target.value })
        }
        type={"text"}
        placeholder={"Enter email"}
        label={"Applicant email"}
        error={
          (!email && softUserError === 'validation')
            ? 'Please enter the email'
            : ''
        }
      />
      {
        (softUserError && softUserError !== 'validation')
          ? <ErrorKMQ error={softUserError}/>
          : null
      }
    </div>
  );
};

export default WorkExperience;
