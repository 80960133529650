import './loading.scss';
import {BeatLoader} from "react-spinners";
import React, {useEffect, useState} from "react";
import ProgressLoadingBar from "./ProgressLoadingBar/ProgressLoadingBar";

interface LoadingProps {
  loading?: boolean,
  text?: string,
  showProgress?: boolean
}

const Loading = (props: LoadingProps) => {
  const {
    loading,
    text,
    showProgress,
  } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      if (showProgress) {
        setTimeout(() => setIsLoading(false), 1000);
      } else {
        setIsLoading(false);
      }
    }
  }, [loading])

  if (isLoading) {
    return <div className={'loading'}>
      <div className={'loading-container'}>
        {
          !showProgress
          ? <BeatLoader loading={true} color={'#212121'}/>
          : null
        }
        <div className={'loading-text'}>
          {text}
        </div>
        {
          showProgress
            ? <ProgressLoadingBar
              loading={loading ?? false}
            />
            : null
        }

      </div>
    </div>
  }

  return null;
}

export default Loading;