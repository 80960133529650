import './notification.scss';
import React, {useState} from "react";
import {XCircle} from "@phosphor-icons/react";

const Notification = ({children}: { children: React.ReactNode }) => {
  const [isClosed, setIsClosed] = useState<boolean>(false);

  if (isClosed) {
    return null
  }

  return <div className={'notification'}>
    <span className={'notification-content'}>
      {children}
    </span>
    <XCircle
      size={28}
      color="#FFFFFF"
      className={'notification-close'}
      onClick={() => setIsClosed(true)}
    />
  </div>
}

export default Notification;