import './user-programs.scss';
import React, {useContext} from "react";
import Loading from "../../../components/UI/Loading/Loading";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import UserProgramsEmpty from "./UserProgramsEmpty";
import UserPrograms from "./UserPrograms";
import {UserContext} from "../../../hooks/Context/UserContext";

const UserProgramsRoute = () => {
  const [user] = useContext(UserContext);
  const [portfolio] = useContext(PortfolioContext);

  if (!portfolio.status && !user.isSoft) {
    return <Loading loading/>
  }

  if ((!portfolio.id || !portfolio.portfolio_email) && !user.isSoft) {
    return <UserProgramsEmpty/>
  }

  return <UserPrograms/>
}

export default UserProgramsRoute;