import './user-profile.scss';

interface UserProfileProps {
  name: {
    first_name: string,
    last_name: string
  },
  job?: string,
  size?: 'small' | 'large',
  showText?: boolean
}

const UserProfile = (props: UserProfileProps) => {
  const {
    name,
    job,
    size,
    showText
  } = props;

  return <div className={`user-profile ${size === 'large' ? 'user-profile-large' : ''}`}>
    <span className={'user-profile-avatar'}>
      {name?.first_name?.length > 0 ? name.first_name[0] : 'U'}
      {name?.last_name?.length > 0 ? name.last_name[0] : ''}
    </span>
    {showText
      ? <span className={'user-profile-text'}>
        <div className={'user-profile-text-name'}>
          {(name?.first_name ?? '') + ' ' + (name?.last_name ?? '')}
        </div>
        {
          job
            ? <div className={'user-profile-text-job'}>
              {job}
            </div>
            : null
        }
      </span>
      : null
    }
  </div>
}

export default UserProfile;