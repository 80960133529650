import {createContext, Dispatch} from "react";

export interface IToken {
  accessToken: string,
  refreshToken: string
}

export const TokenContext = createContext<[IToken, Dispatch<IToken>]>(
  [
    {
      accessToken: '',
      refreshToken: '',
    },
    () => {}
  ]
);