import React, {useEffect, useState} from 'react';
import EmployeeTable from "./components/EmployeeTable/EmployeeTable";
import CsvUploader from "../employee-upload/CsvUploader/CsvUploader";
import { useQueryEmployees } from "../../../../api/assessment-admin/useQueryEmployees";
import './employees-table.scss';
import {assessment_user_list_sorting_options} from "../../../../constants/ui/sortingOptions";
import {ISortingOption} from "../../../../components/UI/SearchAndSort/SearchAndSort";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";

const EmployeesTable: React.FC = () => {
    const queryClient = useQueryClient();
    const [hasEmployees, setHasEmployees] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const navigate = useNavigate()
    const [sortingValue, setSortingValue] = useState<ISortingOption>(assessment_user_list_sorting_options[2]);
    const { data, isLoading, isSuccess } = useQueryEmployees({
        current_page: currentPage,
        search_term: searchValue,
        ordering: sortingValue.value
    });

    const handleUsersCreated = () => {
        setHasEmployees(true);
    };

    useEffect(() => {
        queryClient.invalidateQueries({ queryKey: ['employees'] });
    }, [sortingValue, searchValue, queryClient])

    useEffect(() => {
        if (isSuccess && !hasEmployees && data?.users?.users?.length > 0) {
            setHasEmployees(true)
        }
    }, [isSuccess, hasEmployees, data])

    return (
        <div className="employees-table">
            {isLoading ? (
                <p>Loading...</p>
            ) : (
                hasEmployees ? (
                    <EmployeeTable
                        data={data}
                        onRowClick={id => navigate(`/assessments/employees/${id}`)}
                        searchValue={searchValue}
                        setSearchValue={setSearchValue}
                        sortingValue={sortingValue}
                        setSortingValue={setSortingValue}
                        hasEmployees={hasEmployees}
                    />
                ) : (
                    <CsvUploader onUsersCreated={handleUsersCreated} maxRows={250} />
                )
            )}
        </div>
    );
};

export default EmployeesTable;
