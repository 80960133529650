import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {WarningCircle, CheckCircle} from "@phosphor-icons/react";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import SelectKMQ from "../../../components/UI/Select/SelectKMQ";
import TextArea from "../../../components/UI/TextArea/TextArea";
import PreviousRequests from "./PreviousRequests/PreviousRequests";
import {ButtonTypes} from "../../../constants/ui/types/ButtonTypes";
import {useMutateDocumentRequest} from "../../../api/admin/useMutateDocumentRequest";

import "./admin-document-request.scss";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import {useQueryUserInfo} from "../../../api/admin/useQueryUserInfo";
import Loading from "../../../components/UI/Loading/Loading";
import {toast} from "react-toastify";
import {PaidCourse, useQueryPaidCourses} from "../../../api/admin/useQueryPaidCourses";
import {UserContext} from "../../../hooks/Context/UserContext";
import {PermissionType} from "../../../constants/types/PermissionType";

interface DocumentRequestForm {
  title: string;
  notes: string;
  course_recommendation_id: string;
  customTitle: string;
}

const documentTypeOptions = [
  { label: "Transcript", value: "Transcript" },
  { label: "Reference letter", value: "Reference letter" },
  { label: "Certificate", value: "Certificate" },
  {
    label: "Micro-credentials and Badges",
    value: "Micro-credentials and Badges",
  },
  { label: "Essay or reflection", value: "Essay or reflection" },
  { label: "Custom", value: "Custom" },
];

const AdminDocumentRequest = () => {
  const [user] = useContext(UserContext);
  const navigate = useNavigate();
  const {portfolio_id} = useParams();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  let [searchParams] = useSearchParams();
  const {data: userInfo, refetch} = useQueryUserInfo({
    portfolio_id: portfolio_id,
  });

  const [formData, setFormData] = useState<DocumentRequestForm>({
    title: "",
    notes: "",
    course_recommendation_id: "",
    customTitle: "",
  });

  const {
    data: paidCourses,
    isLoading: isLoadingCourses,
    error: coursesError,
  } = useQueryPaidCourses({
    portfolio_id: portfolio_id || null,
  });

  const createDocumentRequest = useMutateDocumentRequest();

  const handleSubmit = async () => {
    if (!portfolio_id) {
      console.error("No portfolio ID available");
      return;
    }

    setSubmitting(true);
    try {
      await createDocumentRequest.mutateAsync({
        portfolio_id,
        document_requests: [{
          title: formData.title === "Custom" ? formData.customTitle : formData.title,
          notes: formData.notes,
          course_recommendation_id: formData.course_recommendation_id
        }]
      });
      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error creating document request:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleBack = () => navigate(-1);

  const handleSuccessModalClose = () => {
    navigate("/cpl-admin");
  };

  const handleFormChange = (
    field: keyof DocumentRequestForm,
    value: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const paidCourseOptions = React.useMemo(
    () =>
      (paidCourses || []).map((course) => ({
        label: course.name,
        value: course.id,
      })),
    [paidCourses]
  );

  const selectedCourse = React.useMemo(
    () =>
      paidCourses?.find(
        (course) => course.id === formData.course_recommendation_id
      ),
    [paidCourses, formData.course_recommendation_id]
  );

  const isSubmitDisabled =
    !formData.course_recommendation_id ||
    submitting ||
    isLoadingCourses ||
    !formData.title ||
    (formData.title === "Custom" && !formData.customTitle);

  useEffect(() => {
    window.scroll(0, 0);
    if (portfolio_id) {
      refetch();
    }
  }, [refetch, portfolio_id])

  useEffect(() => {
    if (userInfo && userInfo.id === portfolio_id && !userInfo?.assigned_to_me && !user?.permission_groups?.includes(PermissionType.cpl_super_admin)) {
      toast.error('This user is not assigned to you.');
      navigate(`/cpl-admin/${portfolio_id}`);
    }
  }, [user, userInfo, portfolio_id])

  useEffect(() => {
    let courseCode = searchParams.get('course_code')
    if (courseCode && paidCourses && paidCourses.length > 0) {
      let chosenCourseId = paidCourses.find((item: PaidCourse) => item.code === courseCode)?.id ?? '';
      setFormData((prev) => ({
        ...prev,
        course_recommendation_id: chosenCourseId
      }))
    }
  }, [searchParams, paidCourses])

  return (
    <>
      <Loading loading={!userInfo?.assigned_to_me && !user?.permission_groups?.includes(PermissionType.cpl_super_admin)}/>
      <div className="document-request-container">
        <div className="document-request-container-title">Request Documents</div>

        <div className="document-request-tabs">
          <div className="tab active">Request documents</div>
        </div>

        <div className="request-form">
          <div className="request-form-title">Request document</div>

          <SelectKMQ
            options={paidCourseOptions}
            value={
              selectedCourse
                ? {
                  label: selectedCourse.name,
                  value: selectedCourse.id,
                }
                : null
            }
            onChange={(newValue) =>
              handleFormChange("course_recommendation_id", newValue.value)
            }
            label="Paid course"
            placeholder={
              isLoadingCourses ? "Loading courses..." : "Select paid course"
            }
          />

          <div className="education-notification">
            <WarningCircle size={21} color="#212121"/>
            <span>
            Please enter the documentations you want to request from
            applicant and ensure one document type per request.
            </span>
          </div>

          <SelectKMQ
            options={documentTypeOptions}
            value={
              formData.title
                ? {
                  label: formData.title,
                  value: formData.title,
                }
                : null
            }
            onChange={(newValue) => handleFormChange("title", newValue.value)}
            label="Document type"
            placeholder="Select document type"
          />

          {formData.title === "Custom" && (
            <div className="form-field custom-input-textarea">
              <TextArea
                value={formData.customTitle}
                onChange={(e) => handleFormChange("customTitle", e.target.value)}
                placeholder="Enter custom document type"
                label="Custom document type"
              />
            </div>
          )}

          <div className="form-field">
            <TextArea
              value={formData.notes}
              onChange={(e) => handleFormChange("notes", e.target.value)}
              placeholder="Enter notes to applicant"
              label="Notes to applicant"
            />
          </div>

          <div className="form-actions">
            <ButtonKMQ onClick={handleBack} type={ButtonTypes.Secondary}>
              Back
            </ButtonKMQ>
            <ButtonKMQ
              onClick={handleSubmit}
              disabled={isSubmitDisabled}
              type={ButtonTypes.Primary}
            >
              {submitting ? "Submitting..." : "Submit"}
            </ButtonKMQ>
          </div>
        </div>

        <PreviousRequests/>
      </div>

      <AlertModal
        open={showSuccessModal}
        text="Your request form has been submitted"
        proceedText="Return to Applicants List"
        onProceed={handleSuccessModalClose}
        onClose={handleSuccessModalClose}
        icon={<CheckCircle size={56} color="#212121"/>}
      />
    </>
  );
};

export default AdminDocumentRequest;