import './password-requirements.scss';
import {useEffect, useMemo} from "react";
import SinglePasswordCheck from "./components/SinglePasswordCheck";

interface IPasswordRequirements {
  password?: string,
  setPasswordCheck: (currState: boolean) => void
}

const PasswordRequirements = (props: IPasswordRequirements) => {
  const {
    password = '',
    setPasswordCheck
  } = props;

  const lengthCheck = useMemo<boolean>(
    () => password.length > 7,
    [password]
  )
  const uppercaseCheck = useMemo<boolean>(
    () => password !== password.toLowerCase(),
    [password]
  )
  const numberCheck = useMemo<boolean>(
    () => !!password.match(".*[0-9].*"),
    [password]
  )
  const characterCheck = useMemo<boolean>(
    () => !!password.match("[!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]"),
    [password]
  )

  useEffect(() => {
    if (lengthCheck && uppercaseCheck && numberCheck && characterCheck) {
      setPasswordCheck(true);
    } else {
      setPasswordCheck(false);
    }
  }, [setPasswordCheck, lengthCheck, uppercaseCheck, numberCheck, characterCheck])

  return <div className={'password-requirements'}>
    <div className={'password-requirements-title'}>
      Password requirements:
    </div>

    <SinglePasswordCheck
      active={lengthCheck}
      label={'Must be at least 8 characters'}
    />
    <SinglePasswordCheck
      active={uppercaseCheck}
      label={'Include one uppercase letter'}
    />
    <SinglePasswordCheck
      active={numberCheck}
      label={'Include one number'}
    />
    <SinglePasswordCheck
      active={characterCheck}
      label={'Include one special character'}
    />

  </div>
}

export default PasswordRequirements;