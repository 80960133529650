import { useNavigate } from 'react-router-dom';
import './document-request-button.scss';
import { FileDoc } from '@phosphor-icons/react';

interface DocumentRequestProps {
  applicantId: string | number;
  disabled?: boolean;
  documentStats?: {
    total: number;
    fulfilled: number;
  } | null;
}

const DocumentRequestButton = ({ applicantId, disabled, documentStats }: DocumentRequestProps) => {
  const navigate = useNavigate();

  const content = documentStats?.total ? (
    <>
      <FileDoc size={32} weight="regular" />
      <span>{`${documentStats.fulfilled} out of ${documentStats.total} doc. received`}</span>
    </>
  ) : (
    <>
      <FileDoc size={32} weight="regular" />
      <span>Request documents</span>
    </>
  );

  const handleClick = (e: React.MouseEvent) => {
    if (!disabled) {
      e.stopPropagation();
      if (documentStats?.fulfilled && documentStats.fulfilled > 0) {
        // if at least one document is uploaded navigate to applicant profile with state parameter indicating applicant information tab to show
        navigate(`/cpl-admin/${applicantId}`, {
          state: { activeTab: 'info' }
        });
      } else {
        navigate(`/cpl-admin/${applicantId}/request-document-upload`);
      }
    }
  };

  return (
    <div
      className={`document-request document-request-link ${disabled ? 'document-request-link-disabled' : ''}`}
      onClick={handleClick}
    >
      {content}
    </div>
  );
};

export default DocumentRequestButton;