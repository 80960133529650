import './search-and-sort.scss';
import {ChangeEvent} from "react";
import Search from "../Search/Search";
import Sorting from "./Sorting/Sorting";

export interface ISortingOption {
  label: string,
  value: string
}

interface SearchAndSortProps {
  searchValue: string,
  onSearchChange: (event: ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string,
  sortingOptions: ISortingOption[],
  activeSorting: ISortingOption,
  onSortingChange: (newSorting: ISortingOption) => void
}

const SearchAndSort = (props: SearchAndSortProps) => {
  const {
    sortingOptions,
    searchValue,
    activeSorting,
    onSearchChange,
    onSortingChange,
    placeholder
  } = props;

  return <div className={'search-and-sort'}>
    <Search
      value={searchValue}
      onChange={onSearchChange}
      placeholder={placeholder}
    />
    <Sorting
      sortingOptions={sortingOptions}
      activeSorting={activeSorting}
      onSortingChange={onSortingChange}
    />
  </div>
}

export default SearchAndSort;