import WorkerLayout from "../components/Layout/AssessmentLayout/AssessmentLayout";
import Dashboard from "../app/assessments/dashboard/Dashboard";
import AssessmentResultsDashboard from "../app/assessments/dashboard/[assessment_id]/results/DashboardResults";
import Assessment from "../app/assessments/assessment/Assessment";
import AssessmentDetails from "../app/assessments/assessment/[assessment_id]/details/AssessmentDetail";
import AssessmentPage from "../app/assessments/assessment/[assessment_id]/AssessmentID";
import AssessmentResults from "../app/assessments/dashboard/[assessment_id]/results/DashboardResults";
import AssessmentsAdminLayout from "../components/Layout/PermissionsLayouts/AssessmentsAdminLayout";
import {assessmentsAdmin} from "./assessmentsAdmin";

export const assessments = [
  {
    path: '/assessments',
    element: <WorkerLayout/>,
    children: [
      {
        path: '/assessments/employees',
        element: <AssessmentsAdminLayout/>,
        children: assessmentsAdmin
      },
      {
        path: '/assessments/dashboard',
        element: <Dashboard/>
      },
      {
        path: '/assessments/dashboard/:assessment_id/results',
        element: <AssessmentResultsDashboard/>
      },
      {
        path: '/assessments/assessment',
        element: <Assessment/>
      },
      {
        path: '/assessments/assessment/:assessment_id/details',
        element: <AssessmentDetails/>
      },
      {
        path: '/assessments/assessment/:assessment_id/results',
        element: <AssessmentResults/>
      }
    ]
  },
  {
    path: '/assessments/assessment/:assessment_id',
    element: <AssessmentPage/>,
  },
]