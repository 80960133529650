import './reset-password.scss';
import {useNavigate} from "react-router-dom";
import InputKMQ from "../../../components/UI/Input/InputKMQ";
import {useEffect, useState} from "react";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import PasswordRequirements from "../../../components/login/PasswordRequirements/PasswordRequirements";
import {jwtToJson} from "../../../lib/jwt/jwtToJson";
import {useResetPassword} from "../../../api/login/useResetPassword";
import {toast} from "react-toastify";
import {useToken} from "../../../hooks/jwt/useToken";
import {useResetPasswordWithToken} from "../../../api/login/useResetPasswordWithToken";
import Loading from "../../../components/UI/Loading/Loading";

const ResetPassword = () => {
  const navigate = useNavigate();
  const {token, isLoading} = useToken();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [passwordIsChecked, setPasswordIsChecked] = useState<boolean>(false);
  const [confirmPasswordError, setConfirmPasswordError] = useState<boolean>(false);
  const {
    mutate: resetPassword,
    isSuccess: resetPasswordSuccess
  } = useResetPassword();
  const {
    mutate: resetPasswordWithToken,
    isSuccess: resetPasswordWithTokenSuccess
  } = useResetPasswordWithToken();

  const handleResetPassword = () => {
    if (password !== confirmPassword) {
      setConfirmPasswordError(true);
    } else {
      if (token) {
        resetPasswordWithToken({
          password: password,
          confirmation_password: confirmPassword,
          token: token
        })
      } else {
        resetPassword({
          password: password,
          confirmation_password: confirmPassword
        });
      }

    }
  }

  useEffect(() => {
    if (!token && !isLoading) {
      try {
        let token = localStorage.getItem('reset-password-token');
        jwtToJson(token ?? '');
      } catch (e) {
        navigate('/login');
      }
    }
  }, [navigate, token, isLoading])

  useEffect(() => {
    if (resetPasswordSuccess) {
      navigate('/login');
      toast.success('Your password is reset. You can log in using your new password.');
      localStorage.removeItem('reset-password-token');
    }
  }, [resetPasswordSuccess, navigate])

  useEffect(() => {
    if (resetPasswordWithTokenSuccess) {
      navigate('/');
      toast.success('Your password is reset.');
    }
  }, [resetPasswordWithTokenSuccess, navigate])

  useEffect(() => {
    if (confirmPasswordError && password && password === confirmPassword) {
      setConfirmPasswordError(false);
    }
  }, [confirmPasswordError, password, confirmPassword])

  return <div>
    <Loading loading={isLoading}/>

    <div className={'reset-password'}>
      <div className={'reset-password-title'}>
        Reset password
      </div>
      <InputKMQ
        label={'Password'}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        type={'password'}
        placeholder={'Enter password'}
        passwordShowIcon
      />
      <InputKMQ
        label={'Confirm password'}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        type={'password'}
        placeholder={'Confirm password'}
        error={confirmPasswordError ? 'Passwords do not match' : ''}
        passwordShowIcon
      />
      <PasswordRequirements
        password={password}
        setPasswordCheck={setPasswordIsChecked}
      />
      <div className={'reset-password-button'}>
        <ButtonKMQ
          onClick={handleResetPassword}
          disabled={!passwordIsChecked || confirmPasswordError || confirmPassword.length < 8}
        >
          Reset password
        </ButtonKMQ>
      </div>
    </div>
  </div>
}

export default ResetPassword;