import "./assessment.scss";
import AssessmentCard from "../components/AssessmentCards/AssessmentCard";
import { generateAssessmentIcons } from "../../../constants/assessments/icons";
import { UserRoles } from "../../../constants/user/roles";
import { AssessmentCardTypes } from "../../../constants/ui/types/AssessmentCardTypes";
import { useNavigate } from "react-router-dom";
import { useQueryAssessments } from "../../../api/assessment/useQueryAssessments";

export interface Assessment {
  assessment_id: number;
  assessment_name: string;
  created_at: string | null;
  duration: string;
  number_of_questions: string;
  short_description: string;
  status: "in_progress" | "not_started" | "completed";
  updated_at: string | null;
}

export interface AssessmentsData {
  assessments: Assessment[];
}

const Assessment = () => {
  const navigate = useNavigate();
  const {data} = useQueryAssessments();
  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    };
    return date.toLocaleDateString('en-GB', options);
  }

  return (
    <div className={"worker-assessments"}>
      <div className={"worker-assessments-title"}>Assessments</div>
      {data &&
        data?.assessments.map((item : Assessment, index:number) => (
          <div key={index}>
            <AssessmentCard
              title={item?.assessment_name}
              icons={generateAssessmentIcons(item)} // needs to be changed when backend is ready
              description={item?.short_description} // backend doesnt have this data yet you will see empty descp for now
              userRole={UserRoles.WORKER}
              onViewDetails={() => navigate(`/assessments/assessment/${item?.assessment_id}/details`)}
              onClick={
                item.status !== "completed"
                  ? () => navigate(`/assessments/assessment/${item?.assessment_id}`)
                  : () => navigate(`/assessments/assessment/${item?.assessment_id}/results`)
              }
              type={
                item.status === "not_started"
                  ? AssessmentCardTypes.Not_started
                  : item.status === "in_progress"
                  ? AssessmentCardTypes.In_progress
                  : AssessmentCardTypes.Completed
              }
              // additionalStatusText={[(item.created_at ?  "Sent on " + formatDate(item.created_at) : " ") , (item.updated_at ? "Due " + formatDate(item.updated_at) : " ")]}
            />
          </div>
        ))}
    </div>
  );
};

export default Assessment;
