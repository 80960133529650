import {useMutation} from "@tanstack/react-query";
import {postResumeParserUrl} from "../../constants/api/endpoints";
import {jsonToJwt} from "../../lib/jwt/jsonToJwt";
import axios from "axios";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";

export const useParseResume = () => {
  const postData = async (file: any) => {
    return axios.post(backendBaseUrl + postResumeParserUrl, file, {
      headers: {
        "Content-Type": "multipart/form-data",
        "Authorization": "Bearer " + jsonToJwt({})
      },
    }).then(response => response.data);
  }

  return useMutation({
    mutationKey: ['post-parse-resume'],
    mutationFn: postData,
  });
};