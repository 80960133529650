import AssessmentHeader from "../AssessmentHeader/AssessmentHeader";
import AssessmentBack from "../AssessmentBack/AssessmentBack";
import AssessmentFooter from "../AssessmentFooter/AssessmentFooter";
import {useNavigate} from "react-router-dom";
import AssessmentIntro from "../AssessmentIntro/AssessmentIntro";
import { QuestionData } from "../../assessment/[assessment_id]/AssessmentID";

interface AssessmentIntroProps {
  assessmentIntro: QuestionData
  progress: number,
  onStart: () => void
}

const AssessmentIntroPage = (props: AssessmentIntroProps) => {
  const {
    assessmentIntro,
    progress,
    onStart
  } = props;

  const navigate = useNavigate();

  return <div className={'assessment-page'}>
    <AssessmentHeader
      title={assessmentIntro.meta.assessment_name}
      instructions={assessmentIntro.meta}
      text={'View instructions'}
    />
    <div className={'assessment-page-body'}>
      <AssessmentBack
        text={'Back to Assessments'}
        onClick={() => navigate('/assessments/assessment')}
      />

      <AssessmentIntro
        detail={assessmentIntro.meta}
        isAssessmentIntro
      />
    </div>

    <AssessmentFooter
      progress={progress}
      continueText={progress > 0 ? 'Continue assessment' : 'Start assessment'}
      onContinue={onStart}
    />
  </div>
}

export default AssessmentIntroPage;