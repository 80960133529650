import {PropsWithChildren} from "react";
import './label.scss';

const LabelKMQ = (props: PropsWithChildren) => {
  const {children} = props;

  if (!children) {
    return null;
  }

  return <div className={'label'}>
    {children}
  </div>
}

export default LabelKMQ;