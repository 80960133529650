import { useAxios } from "../../hooks/axios/useAxios";

export interface BaseDownloadParams {
    [key: string]: string | undefined;
    filename?: string;
}

export const useFileDownload = () => {
    const axios = useAxios();

    const downloadFile = async (endpoint: string, params?: BaseDownloadParams) => {
        try {
            const response = await axios.get(endpoint, {
                params,
                responseType: 'blob',
            });

            const contentType = response.headers['content-type'];
            let filename = params?.filename || 'download';

            if (!params?.filename) {
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    if (filenameMatch && filenameMatch[1]) {
                        filename = filenameMatch[1].replace(/['"]/g, '');
                    }
                } else if (contentType) {
                    const extension = contentType.split('/')[1].split(';')[0];
                    filename = `download.${extension}`;
                }
            }

            const blob = new Blob([response.data], { type: contentType });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);

            return response.data;
        } catch (error) {
            console.error('Error downloading file:', error);
            throw error;
        }
    };

    return { downloadFile };
};