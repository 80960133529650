import './navigation-tabs.scss';
import NavigationTabElement from "./components/NavigationTabElement/NavigationTabElement";

export type NavigationTab = {
  name: string,
  onClick: () => void,
  active: boolean
}

interface NavigationTabsProps {
  tabs: NavigationTab[]
}

const NavigationTabs = (props: NavigationTabsProps) => {
  const {
    tabs
  } = props;
  return <div className={'navigation-tabs'}>
    {
      tabs.length > 0
        ? tabs.map((item: NavigationTab, index: number) =>
          <NavigationTabElement element={item} showDivider={index !== tabs.length - 1}/>)
        : null
    }
    <div className={'navigation-tabs-bottom'}/>
  </div>
}

export default NavigationTabs;