import {useEffect} from "react";
//@ts-ignore
import AppleConfigFile from "./apple-developer-merchantid-domain-association";

const PaymentAppleConfig = () => {
  //const [text, setText] = useState<string>('');

  useEffect(() => {
    window.open(AppleConfigFile)
  }, [])

  return <div/>

  /*useEffect(() => {
    fetch(AppleConfigFile)
      .then(response => response.text())
      .then(parsedText => setText(parsedText));
  }, []);

  return <div>{}</div>*/
}

export default PaymentAppleConfig;