import "./progressLoadingBar.scss";
import {useState, useEffect} from "react";

interface ProgressProps {
  loading: boolean;
}

const ProgressLoadingBar = (props: ProgressProps) => {
  const loadingdefaultTime = 20;
  const pauseProgressBottom = 91, pauseProgressTop = 95;
  const pauseProgress = Math.floor(Math.random() * (pauseProgressTop - pauseProgressBottom) + pauseProgressBottom);
  const {
    loading
  } = props;

  const [progress, setProgress] = useState<number>(0);

  const incrementProgress = () => {
    setProgress(currentProgress => {
      return Math.min(currentProgress + (Math.random() + 0.4), pauseProgress);
    });
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (loading) {
      timer = setInterval(incrementProgress, loadingdefaultTime * 10);
      return () => clearInterval(timer);
    }
    if (progress > 0) {
      setProgress(100);
    }

  }, [loading]);

    return (
      <div className="progress-bar-container">
        <div className="progress-bar-container-content">
          <div className="progress" style={{width: `${progress}%`}}></div>
        </div>
        <div className="progress-bar-container-precent">
          {Math.round(progress) + "%"}
        </div>
      </div>
    );
};

export default ProgressLoadingBar;
