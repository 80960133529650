import './password-check.scss';
import {CheckCircle} from "@phosphor-icons/react";

interface ISinglePasswordCheck {
  active: boolean,
  label: string
}

const SinglePasswordCheck = (props: ISinglePasswordCheck) => {
  const {
    active,
    label
  } = props;

  return <div className={'password-requirements-check'}>
    <CheckCircle
      size={26}
      color={active ? 'green' : '#212121'}
      className={'password-requirements-check-icon'}
    />
    <span className={'password-requirements-check-label'}>
      {label}
    </span>
  </div>
}

export default SinglePasswordCheck;