import './assessment-back.scss';
import {ArrowCircleLeft} from "@phosphor-icons/react";

interface AssessmentBackProps {
  text: string,
  onClick: () => void
}

const AssessmentBack = (props: AssessmentBackProps) => {
  const {
    text,
    onClick
  } = props;

  return <div className={'assessment-back'} onClick={onClick}>
    <span className={'assessment-back-icon'}>
      <ArrowCircleLeft size={28} color="#212121" />
    </span>
    <span className={'assessment-back-text'}>
      {text}
    </span>
  </div>
}

export default AssessmentBack;