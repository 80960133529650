import {Outlet} from "react-router-dom";
import {useContext} from "react";
import {UserContext} from "../../../hooks/Context/UserContext";
import {PermissionType} from "../../../constants/types/PermissionType";

const AssessmentsAdminLayout = () => {
  const [user] = useContext(UserContext);

  if (user.permission_groups?.includes(PermissionType.assessments_admin)) {
    return <Outlet/>;
  }

  return null;
}

export default AssessmentsAdminLayout;