import './card-status.scss';
import {AssessmentCardTypes} from "../../../../../../constants/ui/types/AssessmentCardTypes";
import {AssessmentCardProps} from "../../AssessmentCard";
import ProgressChiclet from "../../../../../../components/UI/Chiclets/ProgressChiclet/ProgressChiclet";
import {ChicletTypes} from "../../../../../../constants/ui/types/ChicletTypes";

export type CompletionStatus = {
  name: string,
  currentProgress: number,
  total: number
}

const AssessmentCardStatus = (props: AssessmentCardProps) => {
  const {
    type = AssessmentCardTypes.Regular,
    completionStatus,
    additionalStatusText
  } = props;

  const getChicletType = {
    [AssessmentCardTypes.Completed]: ChicletTypes.Completed,
    [AssessmentCardTypes.HR_info]: ChicletTypes.Completed,
    [AssessmentCardTypes.In_progress]: ChicletTypes.In_progress,
    [AssessmentCardTypes.Overdue]: ChicletTypes.Overdue,
    [AssessmentCardTypes.Not_started]: ChicletTypes.Not_started,
    [AssessmentCardTypes.Recommended]: ChicletTypes.Recommended,
  }

  const renderLabels = () => {
    if (type === AssessmentCardTypes.Regular) {
      return null;
    }
    if (type === AssessmentCardTypes.HR_info) {
      return <span>
        {completionStatus
          ? completionStatus.map((item: CompletionStatus, _: number) =>
            <ProgressChiclet
              type={getChicletType[type]}
              text={item.name}
              progress={[item.currentProgress, item.total]}
            />)
          : null
        }
      </span>
    }

    return <ProgressChiclet type={getChicletType[type]} text={type}/>
  }

  return <div className={`assessment-card-status assessment-card-status-${type.replaceAll(' ', '_')}`}>
    <div>
      {renderLabels()}
    </div>
    {(additionalStatusText && additionalStatusText.length > 0)
      ? additionalStatusText.map((item: string, index: number) =>
        <div className={'assessment-card-status-text'} key={index}>
          {item}
        </div>)
      : null
    }
  </div>
}

export default AssessmentCardStatus;