import { useMutation, useQuery } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";
import { documentRequestEndpointUrl } from "../../constants/api/endpoints";
import { DocumentRequest } from "../../constants/types/DocumentRequest";

interface GetDocumentRequestsParams {
  portfolio_id: string;
  document_request_id?: string;
}

interface DocumentRequestsResponse {
  document_requests: DocumentRequest[];
}

export const useDocumentRequests = (params: GetDocumentRequestsParams) => {
  const axios = useAxios();
  
  return useQuery<DocumentRequest[]>({
    queryKey: ["documentRequests", params.portfolio_id],
    queryFn: async () => {
      const response = await axios.get<DocumentRequestsResponse>(
        documentRequestEndpointUrl,
        {
          params: {
            portfolio_id: params.portfolio_id,
            ...(params.document_request_id && {
              document_request_id: params.document_request_id,
            }),
          },
        }
      );
      return response.data.document_requests;
    },
    enabled: !!params.portfolio_id,
  });
};