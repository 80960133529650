import './toggle.scss';

interface ToggleProps {
  active: boolean;
  onToggle: () => void;
  label?: string;
  disabled?: boolean;
}

const Toggle = (props: ToggleProps) => {
  const { active, onToggle, label, disabled } = props;

  return (
    <span className={`toggle ${disabled ? 'toggle-disabled' : ''}`}>
      {label && <span className={`toggle-label ${disabled ? 'toggle-label-disabled' : ''}`}>{label}</span>}
      <span
        className={`toggle-container ${active ? 'toggle-container-active' : ''} ${disabled ? 'toggle-container-disabled' : ''}`}
        onClick={!disabled ? onToggle : undefined}
      >
        <span className="toggle-container-circle" />
      </span>
    </span>
  );
}

export default Toggle;
