import { useQuery } from '@tanstack/react-query';
import { assessmentResultGETURL } from '../../constants/api/endpoints';
import { useAxios } from '../../hooks/axios/useAxios';

export const useQueryResultsDashboard = (assessment_id: number, userId?: string) => {
  const axios = useAxios();

  const handleRequest = async () => {
    const response = await axios.get(`${assessmentResultGETURL}${assessment_id}/`, {
      params: {
        user_id: userId,
      },
    });
    return response?.data ?? response;
  };

  return useQuery({queryKey: ['assessment', 'result', 'dashboard', assessment_id, userId], queryFn: handleRequest})
};
