import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";

export const useMutateConfig = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  async function putData(data: any) {
    return axios.put('config/', {
      ...data,
      config_id: localStorage.getItem('config_id')
    })
  }

  return useMutation({
    mutationKey: ['put-config'],
    mutationFn: putData,
    onSuccess: (data) => {
      queryClient.invalidateQueries({queryKey: ['config']})
      alert('Config successfully updated');
    },
    onError: (error) => {
      // @ts-ignore
      alert('Error: ' + error?.response.data.error)
    }
  });
}
