import {useMutation} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import {postRecommendationUrl} from "../../constants/api/soft-user/endpoints";

export const useMutateRecommendation = ({userIsSoft}: {userIsSoft?: boolean}) => {
  const axios = useAxios();

  async function postData({data}: {data: any}) {
    return axios.post(postRecommendationUrl(userIsSoft), data
    ).then(response => response.data)
  }

  return useMutation({
    mutationKey: ['post-recommendation'],
    mutationFn: postData,
    retry: 1,
    onError: (error: any) => {
      if (error.response?.data) {
        return error.response.data;
      }
    }
  });
}
