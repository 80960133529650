import './search.scss';
import InputKMQ from "../Input/InputKMQ";
import {ChangeEvent} from "react";
import {MagnifyingGlass} from "@phosphor-icons/react";

interface SearchProps {
  value: string,
  onChange: (event: ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string
}

const Search = (props: SearchProps) => {
  const {
    value,
    onChange,
    placeholder
  } = props;

  return <span className={'search'}>
    <InputKMQ
      value={value}
      onChange={onChange}
      type={'text'}
      placeholder={placeholder ?? 'Search'}
      icon={<MagnifyingGlass color={'#212121'} size={28}/>}
    />
  </span>
}
export default Search;