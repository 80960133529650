import './program-admin-card.scss';
import ButtonKMQ from "../../../Button/ButtonKMQ";
import HeartSelected from "../../../../../assets/icons/UI/icons/heart-selected.svg";
import {PlarCardType} from "../PlarCard";
import ProgramAdminCardStatus from "./ProgramAdminCardStatus";

export interface ProgramAdminCardProps {
  type?: PlarCardType,
  title: string,
  subtitle?: string,
  onClick: () => void,
  buttonText: string,
  chicletText?: string,
  statusText?: string[],
  showFavouriteAdmin?: boolean,
  num_cpl_eligible_recommendations: number
  num_paid_recommendations: number
}

const ProgramAdminCard = (props: ProgramAdminCardProps) => {
  const {
    type = PlarCardType.Not_started,
    title,
    subtitle,
    onClick,
    buttonText,
    showFavouriteAdmin
  } = props;

  return <div className={`program-admin-card program-admin-card-${type}`}>
    {
      showFavouriteAdmin
        ? <div className={`program-admin-card-favourite`}>
          <img
            src={HeartSelected}
            alt={'heart'}
            className={'program-admin-card-favourite-icon'}
          />
          <span className={'program-admin-card-favourite-text'}>
            Favourite
          </span>
        </div>
        : null
    }

    <div className={'program-admin-card-title'}>
      {title}
    </div>

    {
      subtitle ?
        <div className={'program-admin-card-subtitle'}>
          {subtitle}
        </div>
        : null
    }

    {
      <ProgramAdminCardStatus {...props}/>
    }

    <div className={'program-admin-card-footer'}>
      <ButtonKMQ onClick={onClick}>
        {buttonText}
      </ButtonKMQ>
    </div>
  </div>
}

export default ProgramAdminCard;