import {ListBullets, User, Basket, File} from '@phosphor-icons/react';

export const CplApplicantMenuOptions = [
  {
    name: 'My Account',
    icon: <User size={18} color="#212121"/>,
    link: '/cpl-applicant'
  },
  {
    name: 'My Programs',
    icon: <ListBullets size={18} color="#212121"/>,
    link: '/cpl-applicant/my-programs'
  },
]

export const CplApplicantMarketplaceMenuOptions = [
  {
    name: 'My Account',
    icon: <User size={18} color="#212121"/>,
    link: '/cpl-applicant'
  },
  {
    name: 'My Programs',
    icon: <ListBullets size={18} color="#212121"/>,
    link: '/cpl-applicant/my-programs'
  },
  {
    name: 'Marketplace',
    icon: <Basket size={18} color="#212121"/>,
    link: '/cpl-applicant/marketplace'
  },
]

export const CplApplicantOnlyMarketplaceMenuOptions = [
  {
    name: 'My Account',
    icon: <User size={18} color="#212121"/>,
    link: '/cpl-applicant'
  },
  {
    name: 'Marketplace',
    icon: <Basket size={18} color="#212121"/>,
    link: '/cpl-applicant/marketplace'
  },
]

export const UploadDocumentsMenuOption = {
    name: 'File Submission',
    icon: <File size={18} color="#212121"/>,
    link: '/cpl-applicant/evidence-submission'
  }