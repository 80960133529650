export const forbiddenFileTypes = [
  // executable extensions
  ".exe",
  ".bat",
  ".cmd",
  ".sh",
  ".bin",
  ".com",
  ".cpl",
  ".dll",
  ".dmg",
  ".iso",
  ".jar",
  ".msi",
  ".vb",
  ".vbs",

  // archive extensions
  ".zip",
  ".tar",
  ".tar.gz",
  ".tgz",
  ".tar.bz2",
  ".tbz2",
  ".tar.xz",
  ".txz",
  ".rar",
  ".7z",
  ".gz",
  ".bz2",
  ".xz",
  ".iso",
]