import {CirclesFour, ClipboardText} from "@phosphor-icons/react";

export const WorkerMenuOptions = [
  {
    name: 'Dashboard',
    icon: <CirclesFour size={24} color="#212121"/>,
    link: '/assessments/dashboard'
  },
  {
    name: 'Assessment',
    icon: <ClipboardText size={24} color="#212121"/>,
    link: '/assessments/assessment'
  }
]