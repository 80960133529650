import {useNavigate, useParams} from "react-router-dom";
import Loading from "../../../components/UI/Loading/Loading";
import {useEffect} from "react";
import {jwtToJson} from "../../../lib/jwt/jwtToJson";

const LoginActivate = () => {
  const navigate = useNavigate();
  const {token} = useParams();

  useEffect(() => {
    if (token) {
      try {
        jwtToJson(token);
        localStorage.setItem('reset-password-token', token);
        navigate('/login/create-password');
      } catch (e) {
        navigate('/login');
      }
    }
  }, [navigate, token])

  return <Loading loading/>
}

export default LoginActivate;