import './evidence-component-title.scss';
import SelectIconDown from '../../../../assets/icons/UI/select/arrow-down.svg';
import SelectIconUp from '../../../../assets/icons/UI/select/arrow-up.svg';

interface EvidenceTitleProps {
  index: number,
  title: string,
  isOpened: boolean,
  toggleOpened: () => void
}

const EvidenceTitle = (props: EvidenceTitleProps) => {
  const {
    index,
    title,
    isOpened,
    toggleOpened
  } = props;

  const toggleTable = () => {
    toggleOpened();
    if (isOpened) {
      let parent = document.getElementById(`evidence-component-${index}`);
      if (parent && parent.getBoundingClientRect().y < 0) {
        parent.scrollIntoView();
      }
    }
  }

  return <div className={`evidence-component-title 
            ${isOpened ? 'evidence-component-title-opened' : ''}
          `}>
    {index}. {title}
    <img
      src={isOpened ? SelectIconUp : SelectIconDown}
      alt={isOpened ? 'close-table' : 'open-table'}
      className={'evidence-component-title-icon'}
      onClick={toggleTable}
    />
  </div>
}

export default EvidenceTitle;