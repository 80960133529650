import {ReactNode} from "react";
import Modal from 'react-modal';
import {XCircle} from "@phosphor-icons/react";

import './alert-modal.scss';
import ButtonKMQ from "../../Button/ButtonKMQ";
import {ButtonTypes} from "../../../../constants/ui/types/ButtonTypes";

interface AlertModalProps {
  open: boolean,
  onClose: () => void,
  closeText?: string,
  onProceed?: () => void,
  proceedText?: string,
  title?: string,
  icon?: ReactNode,
  text?: string
}

const AlertModal = (props: AlertModalProps) => {
  const {
    open,
    onClose,
    closeText,
    onProceed,
    proceedText,
    title,
    icon,
    text
  } = props;

  return <Modal
    isOpen={open}
    onRequestClose={onClose}
    portalClassName={'alert-modal'}
    contentLabel="Alert Modal"
  >
    <div className={'alert-modal-body'}>
      <span className={'alert-modal-body-close'} onClick={onClose}>
        <XCircle size={28} color="#212121"/>
      </span>
      {icon
        ? <div className={'alert-modal-body-icon'}>
          {icon}
        </div>
        : null
      }
      {title
        ? <div className={'alert-modal-body-title'}>
          {title}
        </div>
        : null
      }
      {text
        ? <div className={'alert-modal-body-text'}>
          {text}
        </div>
        : null
      }
      {(closeText || proceedText)
        ? <div className={'alert-modal-body-footer'}>
          {closeText
            ? <ButtonKMQ onClick={onClose} type={ButtonTypes.Secondary}>
              {closeText}
            </ButtonKMQ>
            : null
          }
          {proceedText
            ? <ButtonKMQ onClick={onProceed ? onProceed : onClose}>
              {proceedText}
            </ButtonKMQ>
            : null
          }
        </div>
        : null
      }
    </div>
  </Modal>
}

export default AlertModal;