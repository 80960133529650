import './course-card.scss';
import StatusChiclet from "../../../Chiclets/StatusChiclet/StatusChiclet";
import ButtonKMQ from "../../../Button/ButtonKMQ";
import {PlarCardType} from "../PlarCard";
import ProgressChiclet from "../../../Chiclets/ProgressChiclet/ProgressChiclet";
import {ChicletTypes} from "../../../../../constants/ui/types/ChicletTypes";
import CourseCardStatus from "./CourseCardStatus";

export interface CourseCardProps {
  type?: PlarCardType,
  title: string,
  code?: string,
  match?: string
  onClick: () => void,
  buttonText: string,
  chicletText?: string,
  statusText?: string[],
  cpl_eligible?: boolean,
  payment_status: string,
  changePaymentStatus: () => void
}

const CourseCard = (props: CourseCardProps) => {
  const {
    type = PlarCardType.Not_started,
    title,
    code,
    onClick,
    buttonText,
    cpl_eligible,
  } = props;

  return <div className={`course-card course-card-${type}`}>
    {
      cpl_eligible
        ? <StatusChiclet
          text={window.screen.width > 420 ? 'Credit for Prior Learning Eligible' : 'CPL Eligible'}
        />
        : <ProgressChiclet
          type={ChicletTypes.Not_started}
          text={'Credit not Eligible for Prior Learning'}
        />
    }

    <CourseCardStatus {...props}/>

    {
      code ?
        <div className={'course-card-code'}>
          {code}
        </div>
        : null
    }


    <div className={'course-card-title'}>
      {title}
    </div>

    <div className={'course-card-footer'}>
      <ButtonKMQ onClick={onClick}>
        {buttonText}
      </ButtonKMQ>
    </div>
  </div>
}

export default CourseCard;