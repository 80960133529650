import {useQuery} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";

export const useQueryConfig = () => {
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get('config/', {
      params: {
        config_id: localStorage.getItem('config_id')
      }
    })
      .then(response => response?.data?.data ?? response);
  }

  return useQuery({queryKey: ['config'], queryFn: handleRequest})

}
