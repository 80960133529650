import { useQuery } from '@tanstack/react-query';
import { assessmentProgressGETURL } from '../../constants/api/endpoints';
import { useAxios } from '../../hooks/axios/useAxios';

export const useQueryEmployeeDashboard = (userId: string) => {
    const axios = useAxios();

    const handleRequest = () => {
        return axios.get(`${assessmentProgressGETURL}?user_id=${userId}`)
            .then(response => response?.data ?? response);
    };

    return useQuery({queryKey: ['assessment', 'employee-dashboard', userId], queryFn: handleRequest})
};
