import './login-header.scss';
import LogoKMQ from "../../../assets/logo/KnowMeQ_logo.svg";
import {useLocation, useNavigate} from "react-router-dom";
import {useGetSubdomainInfo} from "../../../api/general/useGetSubdomainInfo";

const LoginHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {data: subdomainInfo} = useGetSubdomainInfo();

  return <div className={'login-header'}>
    <img
      className={'header-logo'}
      src={subdomainInfo?.logo_url ? subdomainInfo.logo_url : LogoKMQ}
      alt={'kmq-logo'}
      onClick={
        location.pathname.includes('login')
          ? () => navigate('/login')
          : () => navigate('/')
      }
    />
  </div>
}

export default LoginHeader;