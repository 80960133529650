import {ReactNode} from "react";
import {AssessmentCardProps} from "../../AssessmentCard";

export type AssessmentIcon = {
  description: string,
  icon: ReactNode
}

const AssessmentCardIcons = (props: AssessmentCardProps) => {
  const {
    icons,
  } = props;
  return <div className={'assessment-card-icons'}>
    {icons.map((item: AssessmentIcon, index: number) =>
      <span className={'assessment-card-icons-container'} key={index}>
          <span className={'assessment-card-icons-container-icon'}>
            {item.icon}
          </span>
          <span className={'assessment-card-icons-container-text'}>
            {item.description}
          </span>
        </span>
    )}
  </div>
}

export default AssessmentCardIcons;