interface QuestionContentProps {
  type: string,
  content: any
}

const AssessmentQuestionContent = (props: QuestionContentProps) => {
  const {
    type,
    content
  } = props;

  if (type === 'image') {
    return <div className={'assessment-question-image'}>
      <img src={content} alt={'question-content'}/>
    </div>
  }

  return <div className={'assessment-question-text'}>
    {content}
  </div>
}

export default AssessmentQuestionContent;