import './normalization-option.scss';
import CheckIcon from "../../../../../assets/icons/UI/icons/checkmark.svg";
import CheckEmptyIcon from "../../../../../assets/icons/UI/icons/checkmark-empty.svg";
import MenuOpenIcon from "../../../../../assets/icons/UI/select/arrow-down.svg";
import MenuCloseIcon from "../../../../../assets/icons/UI/select/arrow-up.svg";
import {useEffect, useState} from "react";

interface NormalizationOptionProps {
    title: string,
    description?: string,
    onChoose: () => void,
    active: boolean
}

const NormalizationOption = (props: NormalizationOptionProps) => {
    const {
        title,
        description,
        onChoose,
        active
    } = props;
    const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        if (title) {
            setMenuIsOpen(false);
        }
    }, [title]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return <div className={`normalization-option ${menuIsOpen ? 'normalization-option-open' : ''}`}>
        <div className={'normalization-option-title'} onClick={onChoose}>
            <img
                src={active ? CheckIcon : CheckEmptyIcon}
                alt={"check-icon"}
                className={'normalization-option-title-active'}
            />
            <div className={'normalization-option-title-text'}>
                {title}
            </div>
        </div>
        {
            description
                ? <img
                    src={menuIsOpen ? MenuCloseIcon : MenuOpenIcon}
                    alt={menuIsOpen ? "close-menu" : 'open-menu'}
                    onClick={() => setMenuIsOpen(!menuIsOpen)}
                    className={'normalization-option-title-menu-toggle'}
                />
                : null
        }
        {
            menuIsOpen
                ? <div className={'normalization-option-text'}>
                    {isMobile && <div className="normalization-option-text-title">{title}</div>}
                    {description}
                </div>
                : null
        }
    </div>
}

export default NormalizationOption;