import './marketplace.body.scss';

import InputKMQ from "../../../components/UI/Input/InputKMQ";
import {useContext, useEffect, useMemo, useState} from "react";
import {useQueryInstitutions} from "../../../api/cpl-applicant/useQueryInstitutions";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import Loading from "../../../components/UI/Loading/Loading";
import {useUserLocation} from "../../../hooks/useUserLocation/useUserLocation";
import CollegeCard from "../../../components/UI/Cards/College";
import {ICollege} from "../../../constants/types/ICollege";
import {useQueryClient} from "@tanstack/react-query";
import {UserContext} from "../../../hooks/Context/UserContext";
import {isMarketplaceUrl} from "../../../lib/envFunctions/isMarketplaceUrl";

const MarketplaceBody = () => {
  const [user] = useContext(UserContext);
  const queryClient = useQueryClient();
  const [portfolio] = useContext(PortfolioContext);
  const [searchValue, setSearchValue] = useState<string>('');
  const location = useUserLocation();

  const searchParam = useMemo<string>(() => {
    if (searchValue.length < 2) {
      return '';
    }
    return searchValue;
  }, [searchValue])

  const {
    data: colleges,
    isSuccess,
  } = useQueryInstitutions({
    portfolio_id: portfolio.id,
    location: location,
    searchParam,
    userIsSoft: user.isSoft
  });

  const handleClick = (subdomain: string) => {
    window.open(encodeURI(subdomain + `/login/auth?` +
    `refresh=${localStorage.getItem('refresh')}&`+
    `portfolio_id=${localStorage.getItem('portfolio_id')}&` +
    `basic_info=${localStorage.getItem('basic_info')}`)
        .replaceAll('+', '%2B'),
      '_blank')
  }

  useEffect(() => {
    if (location) {
      setTimeout(() =>
          queryClient.invalidateQueries({queryKey: ['institutions']}),
        500);
    }
  }, [location, queryClient])

  useEffect(() => {
    queryClient.invalidateQueries({queryKey: ['institutions']});
  }, [searchParam, queryClient])

  return <div className={'marketplace-body'}>
    <Loading loading={!isSuccess}/>
    <div className={'marketplace-body-search'}>
      <InputKMQ
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        placeholder={'Search'}
        type={'text'}
      />
    </div>
    <div className={'marketplace-body-cards'}>
      {
        colleges ? colleges.map((item: ICollege, index: number) =>
            <span key={index}>
              <CollegeCard
                logo={item.logo_url}
                name={item.name}
                location={item.location}
                num_cpl_eligible_recommendations={item.num_cpl_eligible_recommendations}
                num_programs_with_recommended_courses={item.num_programs_with_recommended_courses}
                onClick={() => handleClick(item.subdomain_url)}
              />
            </span>)
          : null
      }
    </div>
  </div>
}

export default MarketplaceBody;