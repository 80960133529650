import './textarea.scss';
import LabelKMQ from "../Label/LabelKMQ";
import ErrorKMQ from "../Error/ErrorKMQ";
import {ChangeEvent, createRef, useEffect} from "react";


interface TextAreaProps {
  value: string,
  onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void,
  placeholder: string,
  error?: string,
  label?: string,
  readOnly?: boolean
}

const TextArea = (props: TextAreaProps) => {
  const {
    value,
    onChange,
    placeholder,
    error,
    label,
    readOnly
  } = props;

  const textareaRef = createRef<HTMLTextAreaElement>();

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight - 24}px`;
    }
  }, [value])

  return <span className={`textarea ${error ? 'textarea-error' : ''}`}>
    <LabelKMQ>{label}</LabelKMQ>
    <textarea
      ref={textareaRef}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      rows={1}
      readOnly={readOnly}
    />
    <ErrorKMQ error={error}/>
  </span>
}

export default TextArea;