import './config.scss';

import {useQueryConfig} from "../../api/normalization/useQueryConfig";
import {ChangeEvent, useContext, useEffect, useState} from "react";
import InputKMQ from "../../components/UI/Input/InputKMQ";
import Checkbox from "../../components/UI/Checkbox/Checkbox";
import SelectKMQ from "../../components/UI/Select/SelectKMQ";
import {deepClone} from "../../lib/resume-parser/deep-clone";
import ButtonKMQ from "../../components/UI/Button/ButtonKMQ";
import {useMutateConfig} from "../../api/normalization/useMutateConfig";
import {UserContext} from "../../hooks/Context/UserContext";
import DecayFunctionsConfig from "../../components/Config/DecayFunctionsConfig";
import Loading from "../../components/UI/Loading/Loading";
import {useLocation} from "react-router-dom";

const sourcesOptions = [
  {label: 'WARN', value: 'WARN'},
  {label: 'NOWARN', value: 'NOWARN'},
  {label: 'EXCLUDE', value: 'EXCLUDE'},
]

export interface IDecayFunctions {
  max_duration_time: number
  max_log_x: number
  max_recency_time: number
  min_recency_decay: number
  use_decay: boolean
}

interface IConfig {
  "min_cosine_sim": number,
  "number_job_norm_choices": number,
  "number_edu_norm_choices": number,
  "rank_threshold": number,
  "rank_thresholding_min": number,
  "cosine_score_threshold": number,
  "use_rank_score": boolean,
  "evidence_per_source": number
  "course_score_threshold": number,
  "course_recommendation_number_cap": number,
  "sources": {
    "title": string,
    "use_type": string
  }[],
  decay_functions: {
    "educations": IDecayFunctions
    "jobs": IDecayFunctions
  }
  "debug": boolean
}

const Config = () => {
  const [user] = useContext(UserContext);
  const {data, isSuccess} = useQueryConfig();
  const {mutate} = useMutateConfig();
  // @ts-ignore
  const [currData, setCurrData] = useState<IConfig>({});
  const {search} = useLocation();

  useEffect(() => {
    setCurrData(data);
  }, [data])

  useEffect(() => {
    const config_id = new URLSearchParams(search).get('config_id');
    if (config_id) {
      localStorage.setItem('config_id', config_id)
    }
  }, [search])

  if (!isSuccess || !currData) {
    return <Loading loading/>
  }

  return <div className={'config'}>
    <div className={'config-title'}>
      Config page
    </div>

    <InputKMQ
      value={String(currData?.min_cosine_sim)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrData({
        ...currData,
        min_cosine_sim: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Minimum Cosine Similarity'}
      label={'Minimum Cosine Similarity'}
    />
    <InputKMQ
      value={String(currData?.number_job_norm_choices)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrData({
        ...currData,
        number_job_norm_choices: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Number of Job Normalization Choices'}
      label={'Number of Job Normalization Choices'}
    />
    <InputKMQ
      value={String(currData?.number_edu_norm_choices)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrData({
        ...currData,
        number_edu_norm_choices: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Number of Education Normalization Choices'}
      label={'Number of Education Normalization Choices'}
    />
    <InputKMQ
      value={String(currData?.rank_threshold)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrData({
        ...currData,
        rank_threshold: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Rank Threshold'}
      label={'Rank Threshold'}
    />
    <InputKMQ
      value={String(currData?.rank_thresholding_min)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrData({
        ...currData,
        rank_thresholding_min: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Rank Thresholding Minimum'}
      label={'Rank Thresholding Minimum'}
    />
    <InputKMQ
      value={String(currData?.cosine_score_threshold)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrData({
        ...currData,
        cosine_score_threshold: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Cosine Score Threshold'}
      label={'Cosine Score Threshold'}
    />
    <Checkbox
      active={currData?.use_rank_score}
      handleClick={() => setCurrData({...currData, use_rank_score: !currData.use_rank_score})}
      text={'Use Rank Score'}
    />
    <InputKMQ
      value={String(currData?.evidence_per_source)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrData({
        ...currData,
        evidence_per_source: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Evidence per Source'}
      label={'Evidence per Source'}
    />
    <InputKMQ
      value={String(currData?.course_score_threshold)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrData({
        ...currData,
        course_score_threshold: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Course Score Threshold'}
      label={'Course Score Threshold'}
    />
    <InputKMQ
      value={String(currData?.course_recommendation_number_cap)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => setCurrData({
        ...currData,
        course_recommendation_number_cap: Number(e.target.value)
      })}
      type={'number'}
      placeholder={'Size Cap on Course Recommendation'}
      label={'Size Cap on Course Recommendation'}
    />

    <div className={'config-subtitle'}>
      Sources
    </div>

    <div>
      {
        currData?.sources && currData.sources.map((item, index) =>
          <SelectKMQ
            options={sourcesOptions}
            value={{label: item.use_type, value: item.use_type}}
            onChange={(newValue) => {
              let newData = deepClone(currData);
              newData.sources[index].use_type = newValue.value;
              setCurrData(newData)
            }}
            label={item.title}
          />
        )
      }
    </div>

    <DecayFunctionsConfig
      data={currData.decay_functions.educations}
      changeData={(newData: IDecayFunctions) => setCurrData({
        ...currData,
        decay_functions: {
          jobs: currData.decay_functions.jobs,
          educations: newData
        }
      })}
      text={'Decay functions for education'}
    />

    <DecayFunctionsConfig
      data={currData.decay_functions.jobs}
      changeData={(newData: IDecayFunctions) => setCurrData({
        ...currData,
        decay_functions: {
          jobs: newData,
          educations: currData.decay_functions.educations,
        }
      })}
      text={'Decay functions for jobs'}
    />

    <Checkbox
      active={currData?.debug}
      handleClick={() => setCurrData({...currData, debug: !currData.debug})}
      text={'Debug'}
    />

    <ButtonKMQ onClick={() => mutate({config: currData})}>
      Submit
    </ButtonKMQ>

  </div>
}

export default Config;
