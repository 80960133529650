import axios from "axios";
import {useMutation} from "@tanstack/react-query";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";
import {resetPasswordWithTokenUrl} from "../../constants/api/endpoints";

export const useResetPasswordWithToken = () => {

  async function postData(data: { password: string, confirmation_password: string, token: string }) {
    return axios.post(backendBaseUrl + resetPasswordWithTokenUrl,
      {
        password: data.password,
        confirmation_password: data.confirmation_password
      },
      {
        headers: {
          'Authorization': 'Bearer ' + data.token
        }
      }
    )
  }

  return useMutation({
    mutationKey: ['reset-password'],
    mutationFn: postData,
    retry: false
  });
}