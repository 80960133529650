import AdminDashboard from "../app/assessments/admin-dashboard/AdminDashboard";
import Dashboard from "../app/assessments/dashboard/Dashboard";
import AssessmentResults from "../app/assessments/dashboard/[assessment_id]/results/DashboardResults";

export const assessmentsAdmin = [
  {
    path: '/assessments/employees',
    element: <AdminDashboard/>,
  },
  {
    path: '/assessments/employees/:id',
    element: <Dashboard/>,
  },
  {
    path: '/assessments/employees/:id/:assessment_id/results',
    element: <AssessmentResults/>
  }
];