import {useQuery} from "@tanstack/react-query";
import {assessmentListURL} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

export const useQueryAssessments = () => {
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(assessmentListURL, )
      .then(response => response?.data ?? response);
  }

  return useQuery({queryKey: ['assessment'], queryFn: handleRequest})

}
