import './text-field.scss';
import LabelKMQ from "../Label/LabelKMQ";

interface TextFieldProps {
  label?: string,
  value: string
}

const TextField = (props: TextFieldProps) => {
  const {
    label,
    value
  } = props;
  return <div className={'text-field'}>
    {
      label
        ? <LabelKMQ>
          {label}
        </LabelKMQ>
        : null
    }

    <div className={'text-field-text'}>
      {value}
    </div>

  </div>
}

export default TextField;