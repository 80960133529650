import { Trash } from "@phosphor-icons/react";
import './deleteButton.scss'
interface DeleteButtonProps {
  isDisabled: boolean;
  onClick: () => void;
}

const DeleteButton = (props: DeleteButtonProps) => {
  const { isDisabled, onClick } = props;
  return (
    <div
      className={`close-delete-icon ${isDisabled ? "close-delete-icon-disabled" : ""}`}
      onClick={onClick}
    >
      <Trash
        size={25}
        color={isDisabled ? "#909090" : "#212121"}
      />
      <span>Delete</span>
    </div>
  );
};

export default DeleteButton;
