import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";
import { documentRequestEndpointUrl } from "../../constants/api/endpoints";
import { toast } from "react-toastify";

interface WithdrawRequestParams {
  document_request_id: string;
}

export const useMutateWithdrawRequest = () => {
  const queryClient = useQueryClient();
  const axios = useAxios();

  return useMutation({
    mutationFn: async ({ document_request_id }: WithdrawRequestParams) => {
      const response = await axios.delete(
        `${documentRequestEndpointUrl}/${document_request_id}`
      );
      return response.data;
    },
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({ queryKey: ["documentRequests"] });
    },
    retry: false,
    onError: (error: Error) => {
      toast.error(error.message);
    },
  });
};