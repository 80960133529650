import {useMutation} from "@tanstack/react-query";
import axios from "axios";
import {createSoftUserURL} from "../../constants/api/soft-user/endpoints";
import {jsonToJwt} from "../../lib/jwt/jsonToJwt";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";

interface SoftUser {
  email?: string,
  first_name?: string,
  last_name?: string
}

export const useCreateSoftApplicant = () => {

  const createUser = (body: SoftUser) => {
    return axios.post(
      backendBaseUrl + createSoftUserURL,
      body,
      {
        headers: {
          "Authorization": "Bearer " + jsonToJwt({})
        }
      }
    ).then(resp => resp.data)
  }

  return useMutation({
    mutationKey: ['soft-user'],
    mutationFn: createUser,
    retry: false
  })
}