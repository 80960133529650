import {MenuOption} from "../../MenuKMQ";
import './menu-element.scss';

interface MenuElementProps {
  element: MenuOption,
  index: number
}

const MenuElementKMQ = (props: MenuElementProps) => {
  const {
    element,
    index
  } = props;

  return <div className={`menu-element ${element.active ? 'menu-element-active' : ''}`}
              key={index} onClick={element.onClick}>
      {element.icon}
    <span className={'menu-element-text'}>
      {element.name}
    </span>

  </div>
}

export default MenuElementKMQ;