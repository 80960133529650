import {useQuery} from "@tanstack/react-query";
import {evidencesUrl} from "../../constants/api/endpoints";
import {useAxios} from "../../hooks/axios/useAxios";

interface GetCoursesParams {
  institution_id?: string,
  portfolio_id?: string | null,
  course_code?: string
}

export const useQueryEvidences = (params: GetCoursesParams) => {
  const {
    institution_id,
    portfolio_id,
    course_code
  } = params;
  const axios = useAxios();

  const handleRequest = () => {
    return axios.get(evidencesUrl, {
      params: {
        institution_id,
        portfolio_id,
        course_code
      }
    })
      .then(response => response?.data ?? response);
  }

  return useQuery({queryKey: ['evidences'], queryFn: handleRequest})

}
