import './self-assessment-submit.scss';
import {CheckCircle} from "@phosphor-icons/react";

const SelfAssessmentSubmit = () => {
  return <div className={'self-assessment-submit'}>
    <CheckCircle size={40} color="#212121" />
    <div className={'self-assessment-submit-text'}>
      You've successfully finished the self-assessment segment. Proceed to the main assessment to continue your evaluation.
    </div>
  </div>
}

export default SelfAssessmentSubmit;