import { useQuery } from "@tanstack/react-query";
import { useAxios } from "../../hooks/axios/useAxios";

const employeeProfileURL = "assessment-engine/user-profile";

export const useEmployeeProfileInfo = (userId: string) => {
    const axios = useAxios();

    const handleRequest = () => {
        return axios.get(`${employeeProfileURL}?user_id=${userId}`)
            .then(response => response?.data ?? response);
    };

    return useQuery({queryKey: ['employee', 'profile', userId], queryFn: handleRequest})
};
