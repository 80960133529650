import './card-status.scss';
import {PlarCardProps, PlarCardType} from "../../PlarCard";
import ProgressChiclet from "../../../../Chiclets/ProgressChiclet/ProgressChiclet";
import {ChicletTypes} from "../../../../../../constants/ui/types/ChicletTypes";
import SkillChiclet from "../../../../Chiclets/SkillChiclet/SkillChiclet";


const PlarCardStatus = (props: PlarCardProps) => {
  const {
    type = PlarCardType.Not_started,
    chicletText,
    statusText,
    match
  } = props;

  const cardTypeToChicletType = {
    [PlarCardType.Not_started]: ChicletTypes.Not_started,
    [PlarCardType.Accepted]: ChicletTypes.Completed,
    [PlarCardType.Completed]: ChicletTypes.Completed,
    [PlarCardType.Rejected]: ChicletTypes.Overdue,
    [PlarCardType.In_progress]: ChicletTypes.In_progress,
  }

  return <div className={`plar-card-status plar-card-status-${type.replaceAll(' ', '_')}`}>
    <div>
      {
        match
          ? <SkillChiclet text={match}/>
          : null
      }
      {
        chicletText
          ? <ProgressChiclet type={cardTypeToChicletType[type]} text={chicletText}/>
          : null
      }
    </div>
    {(statusText && statusText.length > 0)
      ? statusText.map((item: string, index: number) =>
        <div className={'plar-card-status-text'} key={index}>
          {item}
        </div>)
      : null
    }
  </div>
}

export default PlarCardStatus;