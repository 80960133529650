import {useMutation} from "@tanstack/react-query";
import {useAxios} from "../../hooks/axios/useAxios";
import axios from "axios";
import {jsonToJwt} from "../../lib/jwt/jsonToJwt";
import {backendBaseUrl} from "../../constants/api/backendBaseUrl";

export const useMutateNormalization = (setResults: (newData: any) => void) => {
  //const axios = useAxios();

  async function handleRequest(data: any) {
    if (data) {
      return axios.post(backendBaseUrl + 'normalization/', {
        "profile": {
          ...data,
        },
      }, {
        headers: {
          'Content-Type': 'application/json',
          "Authorization": "Bearer " + jsonToJwt({})
        },
      }).then(response => response?.data ?? response);
    }
  }

  return useMutation({
    mutationKey: ['post-normalization'],
    mutationFn: handleRequest,
    onSuccess: (data) => {
      setResults(data?.data?.profile);
    }
  });
}
