import "./assessmentDetail.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryQuestionData } from "../../../../../api/assessment/useQueryQuestionData";
import { DashboardInfo } from "../AssessmentID";
import { useState, useEffect } from "react";
import SelectIconUp from "../../../../../assets/icons/UI/select/arrow-up.svg";
import SelectIconDown from "../../../../../assets/icons/UI/select/arrow-down.svg";
import { Clock, Note } from "@phosphor-icons/react";
import AssessmentBack from "../../../components/AssessmentBack/AssessmentBack";
import { sanitizeHTML } from "../../../../../lib/string/sanitizeHtml";

const AssessmentDetails = () => {
  const navigate = useNavigate();
  const { assessment_id } = useParams();
  const { data, isLoading } = useQueryQuestionData({ assessment_id });

  const [showDropdown, setShowDropdown] = useState<boolean[]>([]);

  useEffect(() => {
    if (data && data.meta && data.meta.dashboard_info) {
      const dropdownCount = data.meta.dashboard_info.filter(
        (item: DashboardInfo) => item.content_type === "h3-dropdown"
      ).length;
      setShowDropdown(Array(dropdownCount).fill(false)); // Initialize all dropdowns as closed
    }
  }, [data]);

  const toggleVisibility = (index: number) => {
    setShowDropdown((prev) => {
      const newVisibleItems = [...prev];
      newVisibleItems[index] = !newVisibleItems[index];
      return newVisibleItems;
    });
  };

  const renderContent = (item: DashboardInfo, index: number) => {
    if (item.content_type === "h1") {
      return <div className={"assessment-details-title"}>{item.content}</div>;
    } else if (item.content_type === "h2") {
      return <div className={"assessment-details-h2"}>{item.content}</div>;
    } else if (item.content_type === "h3") {
      return <div className={"assessment-details-h3"}>{item.content}</div>;
    } else if (item.content_type === "text") {
      return <div className={"assessment-details-text"}>{item.content}</div>;
    } else if (item.content_type === "h3-dropdown") {
      return (
        <div className={`${index > 0 ? "assessment-results-desp" : null}`}>
          <div
            className={"assessment-results-accordion"}
            onClick={() => toggleVisibility(index)}
            key={index}
          >
            <div>{item.content}</div>
            <img
              src={showDropdown[index] ? SelectIconDown : SelectIconUp}
              alt={"dropdown"}
            />
          </div>
          <div
            className={`${
              !showDropdown[index] ? "assessment-results-accordion-hidden" : null
            }`}
          >
            <div
              className={`assessment-results-text`}
              key={index}
              dangerouslySetInnerHTML={{ __html: sanitizeHTML(item.sub_content || "") }}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {!isLoading ? (
        <div className={"assessment-details"}>
          <div className="assessment-details-back">
            <AssessmentBack
              text={"Back to Assessments"}
              onClick={() => navigate("/assessments/assessment")}
            />
          </div>
          <table>
            <tr>
              <td className={"assessment-details-left"}>
                {data &&
                  data.meta.dashboard_info.map(
                    (item: DashboardInfo, index: number) =>
                      renderContent(item, index)
                  )}
                <div className={"assessment-details-left-divider"} />
              </td>

              <td className={"assessment-details-right"}>
                <div className={"assessment-card-icons-container"}>
                  <div className={"assessment-card-icons-container-small"}>
                    <span className={"assessment-card-icons-container-icon"}>
                      <Clock size={28} color="#212121" />
                    </span>
                    <span className={"assessment-card-icons-container-text"}>
                      {data.meta.duration}
                    </span>
                  </div>
                  <div className={"assessment-card-icons-container-small"}>
                    <span className={"assessment-card-icons-container-icon"}>
                      <Note size={28} color="#212121" />
                    </span>
                    <span className={"assessment-card-icons-container-text"}>
                      {data.meta.number_of_questions}
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </div>
      ) : null}
    </div>
  );
};

export default AssessmentDetails;
