import './info.scss';
import InfoIcon from '../../../assets/icons/UI/icons/info.svg';

interface InfoProps {
  text: string
}

const Info = (props: InfoProps) => {
  const {
    text
  } = props;

  return <span className={'info'}>
    <img
      src={InfoIcon}
      alt={'info'}
      className={'info-icon'}
    />
    <div className={'info-text'}>
      {text}
    </div>
  </span>
}

export default Info;