import Loading from "../../../components/UI/Loading/Loading";
import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {jwtToJson} from "../../../lib/jwt/jwtToJson";
import {useToken} from "../../../hooks/jwt/useToken";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";

const Auth = () => {
  const navigate = useNavigate();
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const {setTokens} = useToken();
  const [stableRefresh, setStableRefresh] = useState<string>('');
  const [portfolioId, setPortfolioId] = useState<string | null>('');

  useEffect(() => {
    const urlParams = new URLSearchParams(decodeURI(window.location.search));
    const refresh = urlParams.get('refresh');
    const basicInfo = urlParams.get('basic_info');
    if (basicInfo) {
      try {
        JSON.parse(basicInfo);
        localStorage.setItem('basic_info', basicInfo);
      } catch (e) {
        console.log('json too long');
      }
    }
    setPortfolioId(urlParams.get('portfolio_id'));
    if (refresh) {
      setTokens({refreshToken: '', accessToken: ''});
      localStorage.removeItem('refresh');
      setStableRefresh(refresh);
    } else {
      if (!stableRefresh) {
        navigate('/login');
      }
    }
  }, [])

  useEffect(() => {
    if (stableRefresh) {
      try {
        jwtToJson(stableRefresh);
        localStorage.setItem('refresh', stableRefresh);
        localStorage.setItem('marketplace_user', '1');
        if (portfolioId) {
          localStorage.setItem('portfolio_id', portfolioId);
          setPortfolio({id: portfolioId});
        }

      } catch (e) {
        navigate('/login');
      }
    }
  }, [stableRefresh])

  useEffect(() => {
    if (portfolio.id) {
      navigate('/cpl-applicant/my-programs')
    }
  }, [portfolio])

  return <Loading loading/>
}

export default Auth;