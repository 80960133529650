import './filter.scss';
import {useEffect, useState} from "react";
import FilterIcon from '../../../assets/icons/UI/icons/filter.svg';
import CloseIcon from "../../../assets/icons/UI/select/arrow-up.svg";
import OpenIcon from "../../../assets/icons/UI/select/arrow-down.svg";
import ButtonKMQ from "../Button/ButtonKMQ";
import Checkbox from "../Checkbox/Checkbox";
import {ButtonTypes} from "../../../constants/ui/types/ButtonTypes";
import SelectKMQ from "../Select/SelectKMQ";

export interface FilterOption {
  label: string,
  value: any
}

interface FilterProps {
  name: string
  value: FilterOption[],
  options: FilterOption[],
  onChange: (newFilters: FilterOption[]) => void
}

const Filter = (props: FilterProps) => {
  const {
    name,
    options,
    value,
    onChange
  } = props;

  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const [newFilters, setNewFilters] = useState<FilterOption[]>([]);

  const handleNewFilter = (clickedFilter: FilterOption) => {
    if (newFilters.find(item => item.label === clickedFilter.label)) {
      setNewFilters(newFilters.filter(item => item.label !== clickedFilter.label))
    } else {
      setNewFilters([...newFilters, clickedFilter])
    }
  }

  const cancelNewFilters = () => {
    setNewFilters(value);
    setMenuIsOpen(false);
  }

  const submitFilters = () => {
    onChange(newFilters);
    setMenuIsOpen(false);
  }

  const resetFilters = () => {
    onChange([]);
    setNewFilters([]);
    setMenuIsOpen(false);
  }

  useEffect(() => {
    if (value.length > 0) {
      setNewFilters(value);
    }
  }, [value])

  return <SelectKMQ
    options={options}
    value={value}
    onChange={onChange}
    isMulti
    placeholder={`Filter by ${name}`}
  />

  return <span className={'filter'}>
    <div className={'filter-value'}>
      <img
        src={FilterIcon}
        alt={'filter'}
        className={'filter-value-icon'}
      />
      <span className={'filter-value-text'}>
        Filter by: {name}
      </span>
      <img
        src={menuIsOpen ? CloseIcon : OpenIcon}
        alt={menuIsOpen ? 'close-menu' : 'open-menu'}
        className={'filter-value-open-btn'}
        onClick={() => setMenuIsOpen(!menuIsOpen)}
      />
    </div>

    {
      menuIsOpen
        ? <div className={'filter-menu'}>
          <div className={'filter-menu-content'}>
            <div className={'filter-menu-content-left'}>
              {options.slice(0, Math.ceil(options.length / 2)).map((item: FilterOption, index: number) =>
                <div
                  className={'filter-menu-option'}
                  key={index}
                >
                  <Checkbox
                    handleClick={() => handleNewFilter(item)}
                    text={item.label}
                    active={!!newFilters.find(currFilter => currFilter.label === item.label)}
                  />
                </div>
              )}
            </div>

            <div className={'filter-menu-content-right'}>
              {options.slice(Math.ceil(options.length / 2), options.length).map((item: FilterOption, index: number) =>
                <div
                  className={'filter-menu-option'}
                  key={index}
                >
                  <Checkbox
                    handleClick={() => handleNewFilter(item)}
                    text={item.label}
                    active={!!newFilters.find(currFilter => currFilter.label === item.label)}
                  />
                </div>
              )}
            </div>
          </div>

          <div className={'filter-menu-footer'}>
            <span className={'filter-menu-footer-reset'} onClick={resetFilters}>
              Reset filters
            </span>
            <div className={'filter-menu-footer-buttons'}>
              <ButtonKMQ onClick={cancelNewFilters} type={ButtonTypes.Secondary}>
                Cancel
              </ButtonKMQ>
              <ButtonKMQ onClick={submitFilters}>
                Apply
              </ButtonKMQ>
            </div>
          </div>
        </div>
        : null
    }
  </span>
}

export default Filter;