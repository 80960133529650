import {View, Text, StyleSheet} from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page_counter: {
    position: "absolute",
    bottom: '8px',
    right: '24px',
    fontFamily: "Castoro",
    fontSize: 10,
    fontWeight: 'light',
  }
})

const PageNumber = ({title}: {title: string}) => {
  return <Text
      render={({pageNumber, totalPages}) => (
        `${title} ${' '} ${pageNumber} / ${totalPages}`
      )}
      style={styles.page_counter}
      fixed
    />
}

export default PageNumber;