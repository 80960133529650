import "./parse-resume.scss";
import {useContext, useEffect, useRef, useState} from "react";
import {deepClone} from "../../../lib/resume-parser/deep-clone";
import ButtonKMQ from "../../../components/UI/Button/ButtonKMQ";
import {ResumeContext} from "../../../hooks/Context/ResumeContext";
import {useNavigate} from "react-router-dom";
import {useMutateRecommendation} from "../../../api/normalization/useMutateRecommendation";
import {CheckCircle, WarningCircle} from "@phosphor-icons/react";
import CplApplicantProfile from "../../../components/Resume/CplApplicantProfile/CplApplicantProfile";
import Loading from "../../../components/UI/Loading/Loading";
import {PortfolioContext} from "../../../hooks/Context/PortfolioContext";
import AlertModal from "../../../components/UI/Modals/AlertModal/AlertModal";
import SubmittedProfileOpacityView
  from "../../../components/Resume/CplApplicantProfile/components/SubmittedProfileOpacityView";
import {resumeToProfile} from "../../../lib/data-formatting/resumeToProfile";
import {useQueryUserInfo} from "../../../api/admin/useQueryUserInfo";
import {portfolioToResume} from "../../../lib/data-formatting/portfolioToResume";
import {ButtonTypes} from "../../../constants/ui/types/ButtonTypes";
import ProgressBar from "./progress-bar/ProgressBar";
import CplApplicantSubmittedProfile
  from "../../../components/Resume/CplApplicantSubmittedProfile/CPLApplicantSubmittedProfile";
import {
  EDUCATION_STATE,
  PROFILE_STATE,
  RESUME_STATE,
  REVIEW_STATE,
  WORK_EXPERIENCE_STATE
} from "../../../constants/resume/default";
import {UserContext} from "../../../hooks/Context/UserContext";
import DarkDropZone from "../../../components/Resume/DarkDropzone/DarkDropZone";
import ApplicationInfo from "../../../components/Resume/CplApplicantProfile/components/AppplicantInfo/ApplicationInfo";
import {ResumeBasicInfo} from "../../../lib/resume-parser/redux/types";
import {useCreateSoftApplicant} from "../../../api/soft-user/useCreateSoftApplicant";
import {useToken} from "../../../hooks/jwt/useToken";
import {jwtToJson} from "../../../lib/jwt/jwtToJson";
import {PermissionType} from "../../../constants/types/PermissionType";
import {object, string} from "yup";
import {isMarketplaceUrl} from "../../../lib/envFunctions/isMarketplaceUrl";
import FAQ from "../../faq/FAQ";
import { parseErrorResponse, scrollToError } from "../../../lib/resume-parser/parse-error-response";

const ParseResume = () => {
  const {setTokens, saveToken} = useToken();
  const [user, setUser] = useContext(UserContext);
  const [portfolio, setPortfolio] = useContext(PortfolioContext);
  const [parsedResume, setParsedResume] = useContext(ResumeContext);
  const [currentPage, setCurrentPage] = useState(() => {
    if (portfolio.start_over) {
      return RESUME_STATE;
    }
    return portfolio.edit_portfolio ? REVIEW_STATE : RESUME_STATE;
  });
  const [manualEntryMode, setManualEntryMode] = useState<boolean>(false);
  const [showParseErrorAlert, setShowParseErrorAlert] = useState<boolean>(false);
  const [currentWorkIndex, setCurrentWorkIndex] = useState<number>(0);
  const [currentEduIndex, setCurrentEduIndex] = useState<number>(0);
  const [isSubPageFormValid, setIsSubPageFormValid] = useState<boolean>(false);
  const [isReviewFormValid, setIsReviewFormValid] = useState<boolean>(false);
  const [pendingAdd, setPendingAdd] = useState<null | 'work' | 'education'>(null);
  const [softUserError, setSoftUserError] = useState<string>('');
  const {
    mutate: postRecommendation,
    isSuccess: isRecommendationSuccess,
    isPending: isRecommendationLoading,
    isError: isRecommendationError,
    data: dataRecommendation,
    error: recommendationError
  } = useMutateRecommendation({
    userIsSoft: user.isSoft
  });
  const { refetch } = useQueryUserInfo({});
  const {
    mutate: createSoftUser,
    isPending: isSoftUserLoading,
    data: softUserData,
    error: softUserCallError
  } = useCreateSoftApplicant();
  const [welcomeModalOpen, setWelcomeModalOpen] = useState<boolean | null>(null);
  const [programsModalOpen, setProgramsModalOpen] = useState(false);
  const [reviewModalOpen, setReviewModalOpen] = useState(false);
  const [showClearResumeAlert, setShowClearResumeAlert] = useState(false);
  const [showFileSuccessModal, setShowFileSuccessModal] = useState(false);
  const [alertIncompleteModalOpen, setAlertIncompleteModalOpen] = useState(false);
  const workExperienceRef = useRef(null);
  const educationRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    /* 
      CPL-464: Implement scroll to specific error on recommendation error.
    */
    if (isRecommendationError && recommendationError) {
      const errorData = (recommendationError as any)?.response?.data as { error: string };
      const parsedError = parseErrorResponse(errorData.error);

      if (parsedError) {
        setTimeout(() => {
          if (currentPage === REVIEW_STATE) {
            if (parsedError?.profile?.jobs?.some((job) => job.normalization_choice)) {
              scrollToError(".work-experience-review-page", ".work-experience-notification-error");
            } else if (parsedError?.profile?.educations?.some((edu) => edu.normalization_choice)) {
              scrollToError(".education-review-page", ".education-error");
            }
          }
        }, 0);
      }
    }
  }, [isRecommendationError, recommendationError, currentPage]);


  useEffect(() => {
    if (isRecommendationSuccess) {
      if (!user.isSoft) {
        refetch().then((response) => {
          if (response.data) {
            const updatedPortfolio = portfolioToResume(response.data);
          setPortfolio({
            ...updatedPortfolio,
            start_over: portfolio.start_over,
          });

          setParsedResume(updatedPortfolio);
          }
        });
      } else {
        setPortfolio({
          ...resumeToProfile(parsedResume),
          id: dataRecommendation?.portfolio_id,
          start_over: portfolio.start_over,
        });
        localStorage.setItem('basic_info', JSON.stringify({
          first_name: parsedResume.basic_info.first_name,
          last_name: parsedResume.basic_info.last_name,
          email: parsedResume.basic_info.email
        }));
        saveToken();
        localStorage.setItem('portfolio_id', dataRecommendation?.portfolio_id);
      }
      setProgramsModalOpen(true);
    }
  }, [isRecommendationSuccess]);

  useEffect(() => {
    if (portfolio.start_over) {
      setCurrentPage(RESUME_STATE);
    } else if (portfolio.edit_portfolio) {
      setCurrentPage(REVIEW_STATE);
    }
  }, [portfolio.start_over, portfolio.edit_portfolio]);

  useEffect(() => {
    if (localStorage.getItem('refresh') && welcomeModalOpen === null && portfolio.status && (!portfolio.id || !portfolio.portfolio_email)) {
      setWelcomeModalOpen(true);
    }
    setIsSubPageFormValid(true);
  }, [portfolio, welcomeModalOpen]);

  useEffect(() => {
    const isValid = validateCurrentSection();
    setIsSubPageFormValid(isValid);
    const isValidEntireForm = validateForm();
    setIsReviewFormValid(isValidEntireForm);
  }, [parsedResume, currentPage, currentEduIndex, currentWorkIndex]);

  const handleEditApplicantInfo = (applicantInfo: ResumeBasicInfo) => {
    let newResume = deepClone(parsedResume);
    newResume.basic_info = {
      ...newResume.basic_info,
      ...applicantInfo
    }
    setParsedResume(newResume);
  };

  const validateCurrentSection = (): boolean => {
    if (currentPage === WORK_EXPERIENCE_STATE) {
      const work = parsedResume.work_experience[currentWorkIndex];
      return Boolean(
        work?.job_title &&
        work.company &&
        work.start_date &&
        work.job_summary &&
        (work.current_job || work.end_date) &&
        work.location
      );
    } else if (currentPage === EDUCATION_STATE) {
      const edu = parsedResume.education[currentEduIndex];
      return Boolean(
        edu?.program &&
        edu.institution_name &&
        edu.start_date &&
        edu.institution_type &&
        (edu.currently_enrolled || edu.end_date) &&
        edu.location
      );
    }
    return true;
  };

  const validateForm = (): boolean => {
    const isValidWork = parsedResume.work_experience.every(work => Boolean(
      work?.job_title &&
      work.company &&
      work.start_date &&
      work.job_summary &&
      (work.current_job || work.end_date) &&
      work.location
    ));

    const isValidEducation = parsedResume.education.every(edu => Boolean(
      edu?.program &&
      edu.institution_name &&
      edu.start_date &&
      edu.institution_type &&
      (edu.currently_enrolled || edu.end_date) &&
      edu.location
    ));

    return isValidWork && isValidEducation;
  };


  const handleAddWork = () => {
    if (validateCurrentSection()) {
      const newResume = deepClone(parsedResume);
      newResume.work_experience.push({ company: "", job_title: "", job_summary: "" });
      setParsedResume(newResume);
      setCurrentWorkIndex(newResume.work_experience.length - 1);
    } else {
      setPendingAdd('work');
      setAlertIncompleteModalOpen(true);
    }
    window.scroll(0, 0);
  };

  const handleAddEducation = () => {
    if (validateCurrentSection()) {
      const newResume = deepClone(parsedResume);
      newResume.education.push({ program: "" });
      setParsedResume(newResume);
      setCurrentEduIndex(newResume.education.length - 1);
    } else {
      setPendingAdd('education');
      setAlertIncompleteModalOpen(true);
    }
    window.scroll(0, 0);
  };

  const handleProceedAddSubItem = () => {
    if (pendingAdd === 'work') {
      const newResume = deepClone(parsedResume);
      newResume.work_experience.push({ company: "", job_title: "", job_summary: "" });
      setParsedResume(newResume);
      setCurrentWorkIndex(newResume.work_experience.length - 1);
    } else if (pendingAdd === 'education') {
      const newResume = deepClone(parsedResume);
      newResume.education.push({ program: "" });
      setParsedResume(newResume);
      setCurrentEduIndex(newResume.education.length - 1);
    }
    setPendingAdd(null);
    setAlertIncompleteModalOpen(false);
  };

  let softUserSchema = object({
    email: string().email().min(2).required(),
    first_name: string().min(2).required(),
    last_name: string().min(2).required()
  });

  const handleSubmitUserInfo = async () => {
    try {
      await softUserSchema.validate(parsedResume.basic_info);
      createSoftUser(parsedResume.basic_info);
      setSoftUserError('');
    } catch (e) {
      setSoftUserError('validation');
    }
  }

  useEffect(() => {
    // @ts-ignore
    const requestError = softUserCallError?.response?.data?.error;
    if (requestError) {
      setSoftUserError(requestError);
    }
  }, [softUserCallError])

  const goToNextPage = () => {
    window.scroll(0, 0);
    if (currentPage === WORK_EXPERIENCE_STATE && currentWorkIndex < parsedResume.work_experience.length - 1) {
      setCurrentWorkIndex(currentWorkIndex + 1);
    } else if (currentPage === EDUCATION_STATE && currentEduIndex < parsedResume.education.length - 1) {
      setCurrentEduIndex(currentEduIndex + 1);
    } else if (currentPage === PROFILE_STATE && !softUserData) {
      handleSubmitUserInfo();
    } else {
      const pageOrder = [RESUME_STATE, PROFILE_STATE, WORK_EXPERIENCE_STATE, EDUCATION_STATE, REVIEW_STATE];
      const currentIndex = pageOrder.indexOf(currentPage);
      if (currentIndex < pageOrder.length - 1) {
        const nextPage = pageOrder[currentIndex + 1];
        setCurrentPage(nextPage);
        if (nextPage === REVIEW_STATE) {
          setReviewModalOpen(true);
        }
      }
    }
  };

  const goToPreviousPage = () => {
    window.scroll(0, 0);
    if (currentPage === WORK_EXPERIENCE_STATE && currentWorkIndex > 0) {
      setCurrentWorkIndex(currentWorkIndex - 1);
    } else if (currentPage === WORK_EXPERIENCE_STATE && currentWorkIndex === 0 && !user.isSoft) {
      setCurrentPage(RESUME_STATE)
    } else if (currentPage === EDUCATION_STATE && currentEduIndex > 0) {
      setCurrentEduIndex(currentEduIndex - 1);
    } else if (currentPage === PROFILE_STATE) {
      setShowClearResumeAlert(true);
    } else {
      const pageOrder = [RESUME_STATE, PROFILE_STATE, WORK_EXPERIENCE_STATE, EDUCATION_STATE, REVIEW_STATE];
      const currentIndex = pageOrder.indexOf(currentPage);
      if (currentIndex > 0) {
        setCurrentPage(pageOrder[currentIndex - 1]);
      }
    }
  };

  const onProceedClearResume = () => {
    setParsedResume({ basic_info: {}, work_experience: [{ company: '', job_title: '', job_summary: '' }], education: [{ program: '' }] });
    setShowClearResumeAlert(false);
    setCurrentPage(RESUME_STATE);
  };

  const handleSubmit = () => {
    postRecommendation({
      data: {
        profile: resumeToProfile(parsedResume),
        portfolio_id: portfolio.id,
        resume_storage_name: parsedResume.resume_storage_name
      },
    });
    setPortfolio({ ...portfolio, start_over: false, edit_portfolio: false });
  };

  const handleManualFill = () => {
    setManualEntryMode(true);
    if (user?.permission_groups?.includes(PermissionType.cpl_applicant)) {
      setCurrentPage(WORK_EXPERIENCE_STATE)
    } else {
      setCurrentPage(PROFILE_STATE);
    }
  };

  const handleFileUploadSuccess = () => {
    setShowFileSuccessModal(true);
    if (user?.permission_groups?.includes(PermissionType.cpl_applicant)) {
      setCurrentPage(WORK_EXPERIENCE_STATE)
    } else {
      setCurrentPage(PROFILE_STATE);
    }
  };

  const handleParseErrorCancel = () => {
    setShowParseErrorAlert(false);
    setCurrentPage(RESUME_STATE);
  };

  useEffect(() => {
    if (softUserData) {
      setTokens({
        refreshToken: softUserData.refresh,
        accessToken:softUserData.access
      });
      let parsedToken = jwtToJson(softUserData.refresh);
      setUser({
        ...user,
        user_id: parsedToken.user_id,
        isSoft: parsedToken.soft_user
      });
      goToNextPage();
    }
  }, [softUserData])

  const renderCurrentPage = () => {
    // if the user has already submitted their profile and is not editing and is not starting over
    if (portfolio.id && portfolio.portfolio_email && !portfolio.edit_portfolio && !portfolio.start_over) {
      return <CplApplicantSubmittedProfile portfolio={portfolio} />;
    }
    if (currentPage === WORK_EXPERIENCE_STATE && currentWorkIndex >= parsedResume.work_experience.length) {
      setCurrentWorkIndex(parsedResume.work_experience.length - 1);
    }

    if (currentPage === EDUCATION_STATE && currentEduIndex >= parsedResume.education.length) {
      setCurrentEduIndex(parsedResume.education.length - 1);
    }

    switch (currentPage) {
      case RESUME_STATE:
        return (
          <div className={`${user?.id && !user?.isSoft ? 'edit-resume-upload-dropzone' : ''}`}>
            <DarkDropZone 
              onFileUploaded={handleFileUploadSuccess} 
              onManualFill={handleManualFill}
              onParseError={() => setShowParseErrorAlert(true)}
            />
            {!user?.id && !user?.isSoft && <div className={'edit-resume-faq'}>
              <FAQ />
            </div>}
          </div>
        );
      case PROFILE_STATE:
          return (
            <div className={`edit-resume${!user?.id && (user.isSoft=== undefined || user.isSoft) ? '-add-padding' : ''}`}>
              <div className="edit-resume-applicant-subtitle">My Information</div>
              <div className="edit-resume-applicant-small-title">Personal Information</div>
                <div className="edit-resume-applicant-mandatory">*All fields are mandatory to be filled</div>
                <div className="edit-resume-applicant-content">
                  <ApplicationInfo
                    applicantInfo={parsedResume?.basic_info}
                    changeApplicantInfo={handleEditApplicantInfo}
                    softUserError={softUserError}
                  />
                </div>
            </div>
          );
      case WORK_EXPERIENCE_STATE:
        return (
          <div className={`edit-resume${(!user?.id && !user?.isSoft) || user.isSoft ? '-add-padding' : ''}`}>
            <CplApplicantProfile
              type={WORK_EXPERIENCE_STATE}
              isRecommendationError={isRecommendationError}
              handleAddWork={handleAddWork}
              handleAddEducation={handleAddEducation}
              index={currentWorkIndex}
              setCurrentWorkIndex={setCurrentWorkIndex}
              setCurrentEduIndex={setCurrentEduIndex}
            />
          </div>
        );
      case EDUCATION_STATE:
        return (
          <div className={`edit-resume${(!user?.id && !user?.isSoft) || user.isSoft ? '-add-padding' : ''}`}>
            <CplApplicantProfile
              type={EDUCATION_STATE}
              isRecommendationError={isRecommendationError}
              handleAddWork={handleAddWork}
              handleAddEducation={handleAddEducation}
              index={currentEduIndex}
              setCurrentWorkIndex={setCurrentWorkIndex}
              setCurrentEduIndex={setCurrentEduIndex}
            />
          </div>
        );
      case REVIEW_STATE:
        return (
          <div className={`edit-resume${(!user?.id && !user?.isSoft) || user.isSoft ? '-add-padding' : ''}`}>
            <CplApplicantProfile
              eduRef={educationRef}
              workRef={workExperienceRef}
              type={REVIEW_STATE}
              isRecommendationError={isRecommendationError}
              handleAddWork={handleAddWork}
              handleAddEducation={handleAddEducation}
            />
          </div>
        );
      default:
        return <div>Invalid page</div>;
    }
  };

  return (
    <div>
      <Loading
        loading={isRecommendationLoading || isSoftUserLoading}
        showProgress={!!isMarketplaceUrl() && isRecommendationLoading}
      />
      <div className="edit-resume">
        {!(portfolio.id && portfolio.portfolio_email) && (currentPage !== RESUME_STATE && currentPage !== PROFILE_STATE) && <ProgressBar currentPage={currentPage} />}
        {(portfolio.id && portfolio.portfolio_email && !programsModalOpen && !portfolio.edit_portfolio && !portfolio.start_over) ? <SubmittedProfileOpacityView /> : null}
        {/* <div className="edit-resume-title">My Profile</div> */}
        <div>{renderCurrentPage()}</div>
        <div className={`edit-resume-bottom-navigation ${(!user?.id && !user?.isSoft) || user.isSoft ? 'edit-resume-add-padding-bottom-navigation' : ''}`}>
          {currentPage !== RESUME_STATE && (
            <ButtonKMQ onClick={goToPreviousPage} type={ButtonTypes.Secondary}>
              Back
            </ButtonKMQ>
          )}
          {currentPage !== REVIEW_STATE && currentPage !== RESUME_STATE && (
            <ButtonKMQ onClick={goToNextPage} disabled={!isSubPageFormValid}>
              Continue
            </ButtonKMQ>
          )}
          {currentPage === REVIEW_STATE && (
            <ButtonKMQ onClick={handleSubmit} disabled={!isReviewFormValid}>
              Submit
            </ButtonKMQ>
          )}
        </div>
      </div>
      <AlertModal
        open={showParseErrorAlert}
        text="Your resume could not be read. Please either fill in your information manually or return to the previous page to upload another file."
        proceedText="Manual Entry"
        onProceed={() => setShowParseErrorAlert(false)}
        closeText="Back"
        onClose={handleParseErrorCancel}
        icon={<WarningCircle size={56} color="#212121" />}
      />
      <AlertModal
        open={!!welcomeModalOpen}
        onClose={() => setWelcomeModalOpen(false)}
        title="Welcome to our Course Credits platform!"
        text="We're excited to help you take the next step in your career journey. To get started, you'll need to set up your profile. You can either manually input your previous education and work history or upload your resume file to pre-populate the fields."
        proceedText="Let’s get started"
        onProceed={() => setWelcomeModalOpen(false)}
      />
      <AlertModal
        icon={<CheckCircle size={56} color="#212121" />}
        open={programsModalOpen}
        onClose={() => setProgramsModalOpen(false)}
        title="Application submitted!"
        text={`Your application has been successfully submitted! Click ${isMarketplaceUrl() ? '“See Marketplace“' : '“See My Programs”'} below to view your results.`}
        proceedText={isMarketplaceUrl() ? "See Marketplace" : "See My Programs"}
        onProceed={() => {
          if (isMarketplaceUrl()) {
            navigate('/cpl-applicant/marketplace');
          } else {
            navigate('/cpl-applicant/my-programs');
          }
        }}
        closeText="Close"
      />
      <AlertModal
        open={showClearResumeAlert}
        onClose={() => setShowClearResumeAlert(false)}
        text="If you go back to the previous page all the filled out information will be lost and you will have to restart the process. Are you sure you want to proceed?"
        proceedText="Yes, proceed"
        onProceed={onProceedClearResume}
        title="Warning"
        closeText="No, cancel"
        icon={<WarningCircle size={56} color="#212121" />}
      />
      <AlertModal
        open={reviewModalOpen}
        onClose={() => setReviewModalOpen(false)}
        title="Review your information"
        text="Please review your information carefully and ensure all the fields are accurate before submitting."
        proceedText="Continue"
        onProceed={() => setReviewModalOpen(false)}
      />
      <AlertModal
        open={showFileSuccessModal}
        onClose={() => setShowFileSuccessModal(false)}
        icon={<CheckCircle size={56} color={'#212121'} />}
        text="Your resume has been successfully uploaded! Please continue to fill in any missing information."
        proceedText="Close"
        onProceed={() => setShowFileSuccessModal(false)}
      />
      <AlertModal
        open={alertIncompleteModalOpen}
        onClose={() => setAlertIncompleteModalOpen(false)}
        text="Please fill out all areas in the form before adding a new work experience."
        proceedText="Yes, proceed"
        onProceed={handleProceedAddSubItem}
        title="Incomplete Form"
        closeText="No, cancel"
        icon={<WarningCircle size={56} color="#212121" />}
      />
    </div>
  );
};

export default ParseResume;
